import { useFormStore } from '@empire/components/organisms';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import * as XLSX from "xlsx"; 
import "./Connector.css";
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { toast, ToastContainer } from 'react-toastify'; 

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

interface Mapping {
  source: string;
  target: string;
  arrowId: string;
}
interface Field {
  [key: string]: any;
}
interface Section {
  [key: string]: any;
}
interface Form {
  [key: string]: any;
}
interface DynamicEntity {
  [key: string]: any;
}

export default function ImportData() {
  const [mappings, setMappings] = useState<Mapping[]>([])
  const [selectedSource, setSelectedSource] = useState<HTMLDivElement | null>(null)
  const { userConfig,setUserConfig } = useFormStore()
  const [headers, setHeaders] = useState<string[]>([])
  const [excelData, setExcelData] = useState<any[]>([])
  const [mappedData, setMappedData] = useState<any[]>([])
  const [updatedFormData, setUpdatedFormData] = useState<any[]>([])
  const [menuItems, setMenuItems] = useState<any[]>([])
  const [formDataa,setFormData]=useState<any>({})
  const [fromDataId,setFormDataId]=useState<string>('')
  const [rowData, setRowData] = useState<any[]>([])
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([])
  const [dynamicFields,setDynamicFields]=useState<any[]>([])
  const [userEntity,setUserEntity]=useState<any[]>([])
  const [formEntity,setFormEntity]=useState<any[]>([])
  const [dynamicEntity,setDynamicEntity]=useState<any[]>([])
  const [dataValidation,setDataValidation]=useState<any[]>([])
  const [isRowData, setIsRowData] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const OrgId = userConfig?.orgStatus[0].orgId || undefined;
  const authtoken = localStorage.getItem('token');
  const colorIndexRef = useRef<number>(0);
  const svgContainerRef = useRef<SVGSVGElement>(null);
  const [columnMatchedData,setColumnMatchedData]=useState<any[]>([])
 
 


  
  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
        const data = await response.data
        setUserConfig(data);


      } catch (error) {
        console.error('Error fetching form configuration:', error);
      }
    };

    fetchFormConfig();
  }, []);


  const updateRowData = () => {
    const updatedRowData = rowData.map((row, index) => {
      const excelItem = excelData[index]; 
      let updatedRow: { [key: string]: any } = {};
  
    
      Object.keys(excelItem).forEach((excelKey) => {
    
        const rowKey = Object.keys(row).find((key) => key.toLowerCase() === excelKey.toLowerCase()); 
  
        if (rowKey) {
          updatedRow[rowKey] = excelItem[excelKey]; 
        }
      });
  
   
      return updatedRow;
    });
  
    setColumnMatchedData(updatedRowData); 
  };

  
  useEffect(() => {
    updateRowData();
  }, [rowData, excelData]);

  
 
  function updateFormDataWithDynamicEntity(updatedFormData: any, dynamicEntity: any, userEntity: any, formEntity: any): any {
    updatedFormData.forEach((form: any) => {
        form.sections.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                if (field.selectType === "Dynamic") {
                    let isMatched = false; 
                    dynamicEntity.forEach((dynamic: any) => {
                        dynamic.sections.forEach((dynamicSection: any) => {
                            dynamicSection.fields.forEach((dynamicField: any) => {
                              if (field.value === dynamicField.value) {
                                    field.value = dynamic._id; 
                                    isMatched = true;  }});});});if (!isMatched) {field.value = 'Not Matched';} }
                 if (field.selectType === "UserEntity") {
                    let isUserMatched = false; 
                    const matchedUser = userEntity.find((user: any) => user.name === field.value);
                    if (matchedUser) {
                        field.value = matchedUser._id;  
                        isUserMatched = true; 
                    }
                    if (!isUserMatched) {
                        field.value = 'Not Matched';
                    }
                }
                if (field.selectType === "FormEntity") {
                    let isFormMatched = false; 
                    const matchedForm = formEntity.find((formItem: any) => formItem.formTitle === field.value);
                    if (matchedForm) {
                        field.value = matchedForm._id;  
                        isFormMatched = true; 
                    }
                    if (!isFormMatched) {
                        field.value = 'Not Matched';
                    }
                }
            });
        });
    });

    return updatedFormData;
}

useEffect(() => {
    const result = updateFormDataWithDynamicEntity(updatedFormData, dynamicEntity, userEntity, formEntity);
    const updatedResult = result.map((item: any) => ({
      ...item,
      formId: fromDataId,
      userId: userConfig?._id
  }));
 setDataValidation(updatedResult) 
}, [updatedFormData]);

  useEffect(()=>{
 const fetchSelectFlatData = async () => {
 try { const response = await axios.post(`${BACKEND.BACKEND_API}/other/excelflatdata`,{
         data:dynamicFields
        },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
        );
        setDynamicEntity(response.data)
       
      } catch (error) {

        console.error('Failed to fetch form data:', error);
      }
    };

    fetchSelectFlatData();

  },[dynamicFields])

function getDynamicFieldIds(formData:any) {

  const dynamicFieldIds:any = [];

  if (formData?.sections && Array.isArray(formData.sections)) {
    formData.sections.forEach((section:any) => {
      if (section?.fields && Array.isArray(section.fields)) {
        const filteredFieldIds = section.fields
          .filter((field:any) => field.selectType === "Dynamic" && field.id && field.fieldid && field.sectionid )
          .map((field:any) => ({ formid: field.formid, fieldid: field.fieldid , sectionid:field.sectionid}));
        dynamicFieldIds.push(...filteredFieldIds);
      }
    });
  }
  
  return dynamicFieldIds;
}

useEffect(()=>{

  const dynamicFieldIds = getDynamicFieldIds(formDataa);

  setDynamicFields(dynamicFieldIds);

},[formDataa])

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(
          `${BACKEND.BACKEND_API}/other/api/users`,
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        setUserEntity(response.data);
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };
    fetchMenuItems();
  }, []);


  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.post(
          `${BACKEND.BACKEND_API}/other/getForm`,
          {
            orgId: OrgId,
          },
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        setFormEntity(response.data);
        const [formTitle, _id] = response.data;
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };
    fetchMenuItems();
  }, []);

  useEffect(() => {
    if (updatedFormData.length > 0) {
      // Extract columns dynamically from all field labels
      const allFields = updatedFormData.flatMap((form) => form.sections.flatMap((section:any) => section.fields));
      const uniqueLabels = Array.from(new Set(allFields.map((field) => field.label)));

      const dynamicColumnDefs = [
        
        ...uniqueLabels.map((label) => ({
          headerName: label, 
          field: label, 
          cellStyle: (params:any) => {
           
            if (params.value === "Not Matched") {
              return { backgroundColor: 'red', color: 'white' }; 
            }
            return null; 
          },
        })),
      ];
  
      setColumnDefs(dynamicColumnDefs);

      const extractedData = updatedFormData.map((form) => {
        const row: any = { formTitle: form.formTitle };
        form.sections.forEach((section:any) => {
          section.fields.forEach((field:any) => {
            row[field.label] = field.value;
          });
        });
        return row;
      });

      setRowData(extractedData);
    }
  }, [updatedFormData]);

  const sourceItems = headers; 
  let targetItems: { id: string; label: string }[] = [];
  formDataa?.sections?.forEach((section: any) => {
    section?.fields?.forEach((field: any) => {
      targetItems.push({ id: field.id, label: field.label });
    });
  });




  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.post(
          `${BACKEND.BACKEND_API}/other/getForm`,
          {
            orgId: OrgId,
          },
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        setMenuItems(response.data);
        const [formTitle, _id] = response.data;
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };
    fetchMenuItems();
  }, [OrgId]);

  const CurrentForm=async(e:any)=>{

    if(e.target.value != 'null')
    {
      const response = await axios.get(
        `${BACKEND.BACKEND_API}/forms/${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      const {_id,...form}=response.data
      setFormData(form)
      setFormDataId(_id)
   
    }
    else if(e.target.value == 'null')
    {
      setFormData({})
    }

  }

  useEffect(() => {

    if (mappedData.length > 0) {

        const updatedData = mappedData.map((row) => {
     
        const newFormData = structuredClone(formDataa); 
  
        newFormData.sections = newFormData.sections.map((section: any) => {
          section.fields = section.fields.map((field: any) => {
            if (row.hasOwnProperty(field.id)) {
              return { ...field, value: row[field.id] };
            }
            return field;
          });
          return section;
        });
  
        return newFormData;
      });
  
      setUpdatedFormData(updatedData);

    }
  }, [mappedData]);

  
  const getUniqueColor = () => {
    let hue = (colorIndexRef.current * 137) % 360;
    colorIndexRef.current++;
    return `hsl(${hue}, 80%, 50%)`;
  };

  useEffect(() => {
    const handleItemClick = (e: MouseEvent) => {
      const clickedItem = e.target as HTMLDivElement;

      if (clickedItem.classList.contains("item")) {
        if (clickedItem.closest(".sourceBox")) {
          document.querySelectorAll(".item").forEach((i) => i.classList.remove("kelected"));
          clickedItem.classList.add("kelected");
          setSelectedSource(clickedItem);
        } else if (clickedItem.closest(".targetBox") && selectedSource) {
          const existingMapping = mappings.find(
            (m) =>
              m.source === selectedSource.dataset.id &&
              m.target === clickedItem.dataset.id
          );

          if (!existingMapping) {
            const arrowId = `arrow-${Date.now()}`;
            setMappings((prevMappings) => [
              ...prevMappings,
              { source: selectedSource.dataset.id!, target: clickedItem.dataset.id!, arrowId },
            ]);
            drawArrow(selectedSource, clickedItem, arrowId);
          }

          selectedSource.classList.remove("kelected");
          setSelectedSource(null);
        }
      }

    };

    document.addEventListener("click", handleItemClick);
    return () => document.removeEventListener("click", handleItemClick);
  }, [selectedSource, mappings]);
  
  const drawArrow = (source: HTMLDivElement, target: HTMLDivElement, arrowId: string) => {
    if (!svgContainerRef.current) return;

    const sourceRect = source.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    const containerRect = svgContainerRef.current.getBoundingClientRect();

    const startX = sourceRect.right - containerRect.left;
    const startY = sourceRect.top + sourceRect.height / 2 - containerRect.top;
    const endX = targetRect.left - containerRect.left;
    const endY = targetRect.top + targetRect.height / 2 - containerRect.top;

    const uniqueColor = getUniqueColor();
    const markerId = `arrowhead-${colorIndexRef.current}`;

    if (!document.getElementById(markerId)) {
      const marker = document.createElementNS("http://www.w3.org/2000/svg", "marker");
      marker.setAttribute("id", markerId);
      marker.setAttribute("markerWidth", "10");
      marker.setAttribute("markerHeight", "7");
      marker.setAttribute("refX", "9");
      marker.setAttribute("refY", "3.5");
      marker.setAttribute("orient", "auto");
      marker.innerHTML = `<polygon points="0 0, 10 3.5, 0 7" fill="${uniqueColor}"/>`;
      document.getElementById("arrowDefs")?.appendChild(marker);
    }

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("d", `M ${startX},${startY} C ${(startX + endX) / 2},${startY} ${(startX + endX) / 2},${endY} ${endX},${endY}`);
    path.setAttribute("class", "arrow");
    path.setAttribute("stroke", uniqueColor);
    path.setAttribute("id", arrowId);
    path.setAttribute("marker-end", `url(#${markerId})`);
    path.style.cursor = "pointer";

    svgContainerRef.current.appendChild(path);
  };

  const removeMapping = (arrowId: string) => {
    document.getElementById(arrowId)?.remove();
    setMappings((prevMappings) => prevMappings.filter((m) => m.arrowId !== arrowId));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target?.result as ArrayBuffer;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rows: any[] = XLSX.utils.sheet_to_json(sheet); 
        setExcelData(rows); 
        
        const headers: string[] = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0] as string[];
        setHeaders(headers);
        
      };
      reader.readAsArrayBuffer(file);
    }
  };


  const validateRequiredFieldss = () => {
    let allFieldsMapped = true;
    const missingMappings: string[] = [];
  
   
    formDataa?.sections?.forEach((section: any) => {
      section?.fields?.forEach((field: any) => {
        if (field.required || field.value === "" || field.value === "Not Matched" ) {
          
          const mapping = mappings.find((mapping) => mapping.target === field.id);
  
          if (!mapping) {
            
            missingMappings.push(field.label);
            allFieldsMapped = false;
          }
        }
      });
    });
  
 
    if (!allFieldsMapped) {
      toast.error(`Required fields are missing mappings: ${missingMappings.join(", ")}`,{
        position: 'bottom-right',
        autoClose: 2000,
      });
      return false; 
    }
  
    return true; 
  };

const generateMappedData = () => {

  const isValid = validateRequiredFieldss();  
  
  if (!isValid) {
    return;  
  }

const mappedData = excelData.map((row) => {
      let mappedRow: any = {};
  
      mappings.forEach((mapping) => {
        const sourceColumnIndex = parseInt(mapping.source, 10) - 1;
        mappedRow[mapping.target] = row[sourceItems[sourceColumnIndex]];
      });
  
      return mappedRow; 
    });
  
    setMappedData(mappedData);

  
    

  };

   const validateRequiredFields = async (data: any) => {


    const isValid = validateRequiredFieldss();  
  
    if (!isValid) {
      return;  
    }

    
    let allFieldsFilled = true;
    data.forEach((form: any, index: number) => {
      form.sections.forEach((section: any) => {
        section.fields.forEach((field: any) => {
          if (field.required || field.value === "Not Matched") {
            toast.error(`Field ${field.label} Cell ${index+1} Row Not Matched`,{
              position: 'bottom-right',
              autoClose: 2000,
            });
            allFieldsFilled = false; 
          }
        });
      });
    });
    if (allFieldsFilled) {
      try {
        const response = await axios.post(
          `${BACKEND.BACKEND_API}/valueoperation/excelvalueinsert`,
          { data },
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success('Data processed successfully!',{
            position: 'bottom-right',
            autoClose: 2000,
          }); 
        } else {
          toast.error('Something went wrong, please try again!',{
            position: 'bottom-right',
            autoClose: 2000,
          }); 
        }
      } catch (error) {
      
        toast.error('An error occurred while processing the request!',{
          position: 'bottom-right',
          autoClose: 2000,
        });
        console.error('Error:', error);
      }
    }
  };


  const getCurrentDateAndTimeInHindi = () => {
    const now = new Date();
  
 
    const day = String(now.getDate()).padStart(2, '0'); 
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear(); 
  
    
    const date = `${day}-${month}-${year}`;
  
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; 
    const time = `${String(hours).padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;

    return {date,time}
  };

  
  const uploadExcel = async (file:File) => {


    
    const isValid = validateRequiredFieldss();  
  
    if (!isValid) {
      return;  
    }

    

    let allFieldsFilled = true;
    dataValidation.forEach((form: any, index: number) => {
      form.sections.forEach((section: any) => {
        section.fields.forEach((field: any) => {
          if (field.required || field.value === "Not Matched") {
            toast.error(`Field ${field.label} Cell ${index+1} Row Not Matched`,{
              position: 'bottom-right',
              autoClose: 2000,
            });
            allFieldsFilled = false; 
          }
        });
      });
    });

    console.log(allFieldsFilled,'allFieldsFilled....')
    if (allFieldsFilled)
    {

    const formData = new FormData();
    formData.append('excel', file);
  
    try {
      const response = await fetch(`${BACKEND.BACKEND_API}/upload-excel`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.status==200) {
        const data = await response.json();
        if(`${BACKEND.BACKEND_API}${data.fileUrl}` && mappings && formData && fromDataId && userConfig?.userId)
        {
          
          const { date, time } = getCurrentDateAndTimeInHindi();
          try {

            
            const response = await axios.post(
              `${BACKEND.BACKEND_API}/valueoperation/excelqueue`,
              { data:{fileurl:`${BACKEND.BACKEND_API}${data.fileUrl}`,mappings:mappings,form:formDataa,formid:fromDataId,userid:userConfig?.userId,OrgId : userConfig?.orgStatus[0].orgId,date: date,time:time,username:userConfig.name,formname:formDataa.formTitle,status: dataValidation.length <= 10 ? 'Processed' : 'Queue',Processedrecord: dataValidation.length <= 10 ? dataValidation.length : 0 ,datalength:dataValidation.length} },
              {
                headers: {
                  Authorization: `Bearer ${authtoken}`,
                },
              }
            );
            if (response.status === 200 ) {

               if(updatedFormData.length<=10)
               {

                const updatedData = dataValidation.map(item => {
                  return {
                    ...item,
                    userId: userConfig?.userId
                  };
                });
              
                
                const allUserIdAdded = updatedData.every(item => item.hasOwnProperty('userId'));
              
           
                if (allUserIdAdded) {
                  validateRequiredFields(updatedData); 
                } else {
                  console.log("Not all objects have the userId field.");
                }
               }
               else
               {
                toast.success('Data processed successfully!',{
                  position: 'bottom-right',
                  autoClose: 2000,
                }); 
               }
              
            } else {
              toast.error('Something went wrong, please try again!',{
                position: 'bottom-right',
                autoClose: 2000,
              }); 
            }
            
          } catch (error) {
          
            toast.error('An error occurred while processing the request!',{
              position: 'bottom-right',
              autoClose: 2000,
            });
            console.error('Error:', error);
          }

        }
        else
        {
          toast.error('Something went wrong, please try again!',{
            position: 'bottom-right',
            autoClose: 2000,
          }); 
        }

      } else {
        console.error('Failed to upload Excel file');
      }
    } catch (error) {
      console.error('Error uploading Excel file:', error);
    }

  }

  };
  

  



  return (
    <>
      
     <ToastContainer />

      <div className="space-y-10">
      
        <div className="block justify-between page-header md:flex">
          <h3 className="text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
            Import Data
          </h3>
        </div>

      
        <div className="mb-8">
          <label className="block text-gray-600 dark:text-white mb-2">Select Form</label>
          <select
            onChange={(e) => CurrentForm(e)}
            className="w-1/4 p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
          >
            <option value="null">--Select Form--</option>
            {menuItems.map((menuItem) => (
              <option key={menuItem._id} value={menuItem._id}>
                {menuItem.formTitle}
              </option>
            ))}
          </select>
        </div>

        {Object.keys(formDataa).length !== 0 && mappedData.length == 0 ? (
          <div className="mb-8">
            <label htmlFor="fileInput" className="block text-gray-600 dark:text-white mb-2">Upload File (.xlsx, .xls)</label>
            <input
              id="fileInput"
              type="file"
              accept=".xlsx, .xls"
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              onChange={handleFileChange}
            />
          </div>
        ) : null}

{selectedFile && mappedData.length === 0 && (
  <div className="kanel">
    <div className="kanel-header">Create Mapping</div>
    <div className="kanel-body">
      <div className="kontainer">
        <div  className='koox'>
        <svg ref={svgContainerRef} id="svgContainer">
          <defs id="arrowDefs"></defs>
        </svg>
        <div className="kox sourceBox" style={{ marginRight: "100px" }}>
          <h3>Source</h3>
          {sourceItems.map((item, index) => (
            <div key={index} className="item" data-id={(index + 1).toString()}>
              {item}
            </div>
          ))}
        </div>

        <div className="kox targetBox" style={{ marginRight: "30px" }}>
          <h3>Target</h3>
          {targetItems.map((item, index) => (
            <div key={index} className="item dropzone" data-id={item.id}>
              {item.label}
            </div>
          ))}
        </div>

        <div className="kox resultBox" style={{ marginLeft: "0px" }}>
          <h3>Mappings</h3>
          {mappings.map((mapping) => (
            <div key={mapping.arrowId} className="mapping-item">
              <span>{`Source ${mapping.source} → Target ${mapping.target}`}</span>
              <button className="delete-btn small" onClick={() => removeMapping(mapping.arrowId)}>✖</button>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>

    <div className="kanel-footer">
      <button className="btn btn-primary" onClick={() => generateMappedData()}>
        Generate Mapped Data
      </button>
    </div>
  </div>
)}
  </div>
      {mappedData.length > 0 ? (
  < div >
  <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      
        <button 
          className="ti-btn ti-btn-primary"
          onClick={() => {
            setMappedData([]);
            setMappings([]);
          }}
          style={{ marginRight: '20px' }}
        >
          <i className="ti ti-arrow-left" style={{ fontSize: '20px' }}></i>
          Back
        </button>

   
        <div style={{ display: 'flex', gap: '10px' }}>
          <button 
            className="ti-btn ti-btn-primary"
            onClick={() => {
              // validateRequiredFields(dataValidation);
              if(selectedFile)
              {
                uploadExcel(selectedFile)

              }
              
            }}
          >
            Submit
          </button>

          <button 
            className="ti-btn ti-btn-primary"
            onClick={() => {
              setIsRowData(!isRowData);
            }}
          >
            Error
          </button>
        </div>
      </div>
    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
      <AgGridReact rowData={isRowData ? rowData : columnMatchedData} columnDefs={columnDefs} pagination={true} />
    </div>
  </div>
) : null}
    </>
  );
}
