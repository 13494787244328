import React, { useState } from 'react';
import { Field, ParaInputProps } from '../types';

export const ParaGraph = ({ field, value, error, onChange }: ParaInputProps) => {
    const [ischecked, setIschecked] = useState();
    
  return (
    <div className="p-2  flex space-x-6" >
      {field.type==='paragraph' && <p>{field.paragraph}</p>} 
      </div>
   
  );
};