import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Field ,Form, PieChartProps, Section} from '../types'; 

const PieChart = ({ field, value, error, onChange }: PieChartProps) => {

  const series = field.pieChart===undefined ? [0] : field.pieChart?.map((data) => Number(data.value));
    
  const label = field.pieChart===undefined ? [""] : field.pieChart?.map((data) => data.label) 
  
  const [options] = useState<any>({
    chart: {
      width: '100%', 
      type: 'pie',
    },
    labels: label,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-center mb-6">{field.label}</h2>
        <div className="flex justify-center">
          
          <ReactApexChart
            options={options}
            series={series}
            type="pie"
            width="380"
          />
        </div>
      </div>
    </div>
  );
};

export default PieChart;
// import React, { useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// import { PieChartProps } from '../types';

// const PieChart = ({ field }: PieChartProps) => {

//   const pieChartData = Array.isArray(field?.pieChart) ? field.pieChart : [];
  
//   const series = pieChartData.map((data, index) => {
//     const value = Number(data?.value);
//     if (isNaN(value)) {
//       console.warn(`Invalid value at index ${index}:`, data);
//       return 0;
//     }
//     return value;
//   });

//   const labels = pieChartData.map((data, index) => {
//     if (typeof data?.label !== 'string') {
//       return `Unknown ${index}`; 
//     }
//     return data.label;
//   });

//   const [options] = useState<any>({
//     chart: {
//       width: '100%',
//       type: 'pie',
//     },
//     labels: labels,
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: '100%',
//           },
//           legend: {
//             position: 'bottom',
//           },
//         },
//       },
//     ],
//   });

//   return (
//     <div className="max-w-3xl mx-auto p-4">
//       <div className="bg-white p-8 rounded-lg shadow-lg">
//         <h2 className="text-2xl font-semibold text-center mb-6">{field?.label || 'Pie Chart'}</h2>
//         <div className="flex justify-center">
//           <ReactApexChart options={options} series={series} type="pie" width="380" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PieChart;
