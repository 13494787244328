import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
// import { SignaturePadProps } from "../types";
import { Field } from '../types';

interface SignaturePadProps {
  field: Field;
  value: string;
}

export const SignaturePad: React.FC<SignaturePadProps> = ({ field, value }) => {
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(value || null); // Initialize with value prop if available

  // Whenever a new signature is drawn, update the imageURL state
  const saveSignature = () => {
    if (sigCanvas.current) {
      const signatureData = sigCanvas.current.toDataURL('image/png');
      setImageURL(signatureData); // Update the state with the new signature
    }
  };

  // Clear the signature
  const clearSignature = () => {
    sigCanvas.current?.clear();
    setImageURL(null); // Clear the current signature
  };

  // Sync field value with the imageURL (if needed, depends on your form setup)
  if (field) {
    field.value = imageURL ? imageURL.toString() : ''; // Ensure the field value is always updated
  }

  return (
    <div className="flex items-left">
      <div className="border bg-white flex flex-col items-center gap-4 p-6 w-fit mx-auto rounded-lg shadow-md">
        {/* Heading with Bottom Line */}
        <h6 className="text-lg font-semibold">Signature Pad</h6>
        <hr className="w-full border-gray-300" />

        <div className="flex items-center gap-6">
          {/* Signature Canvas */}
          <div className="flex flex-col items-center border p-2 w-[300px] h-[150px] rounded-md">
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              onEnd={saveSignature}
              canvasProps={{
                width: 300,
                height: 150,
                className: 'w-full h-full',
              }}
            />
            <span className="text-sm text-gray-500 mt-3">Signature</span>
          </div>

          {/* Render Image if available */}
          {imageURL && (
            <div className="flex flex-col items-center border p-2 w-[300px] h-[150px] rounded-md">
              <img
                src={imageURL}
                alt="User Signature"
                className="w-full h-full object-cover"
              />
              <span className="text-sm text-gray-500 mt-3">Preview</span>
            </div>
          )}

          {/* Clear Button - Now Small & Aligned */}
          <button
            type="button"
            onClick={clearSignature}
            className="bg-red-500 text-white px-4 py-2 text-sm rounded-md hover:bg-red-600 transition self-center"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};
