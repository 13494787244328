import React, { useState, useRef } from 'react';

interface FormItem {
  _id: string;
  formTitle: string;
}

interface Master {
  group: string;
  formTitle: string;
  _id: string;
}

interface PermissionField {
  value: string;
  label: string;
}

interface Permission {
  fields: PermissionField[];
}

interface FormListProps {
  forms: FormItem[];
  expanded: boolean;
  groupForms: Record<string, Master[]>;
  setExpanded: (value: boolean) => void;
  title: string;
  handleNavigation: (formId: string, path: 'form' | 'list') => void;
  permissiontype: Permission[];
}

const FormList: React.FC<FormListProps> = ({
  forms,
  groupForms,
  title,
  handleNavigation,
  permissiontype,
}) => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const [expandedGroup, setExpandedGroup] = useState<Record<string, boolean>>({});
  const [selectedGroup, setSelectedGroup] = useState("");
  const handleItemClick = (item: FormItem) => {
    setExpandedItem(expandedItem === item.formTitle ? null : item.formTitle);
  };

   const toggleGroupExpansion = (groupName: any) => {
      setExpandedGroup((prevExpandedGroups) => ({
        ...prevExpandedGroups,
        [groupName]: !prevExpandedGroups[groupName],
      }));
    };

  const getPermissionsForForm = (formId: string) => {
    return permissiontype.find((permission) =>
      permission.fields.some((field) => field.value === formId)
    );
  };

  {
    /* kp code */
  }

  const ANIMATION_DURATION = 300;

  // SlideUp function: smoothly collapse the element
  const slideUp = (target: HTMLElement, duration = ANIMATION_DURATION) => {
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = `${duration}ms`;
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight; // force reflow
    target.style.overflow = 'hidden';
    target.style.height = '0';
    target.style.paddingTop = '0';
    target.style.paddingBottom = '0';
    target.style.marginTop = '0';
    target.style.marginBottom = '0';
    setTimeout(() => {
      target.style.display = 'none';
      target.style.removeProperty('height');
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
  };

  // SlideDown function: smoothly expand the element
  const slideDown = (target: HTMLElement, duration = ANIMATION_DURATION) => {
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    const height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = '0';
    target.style.paddingTop = '0';
    target.style.paddingBottom = '0';
    target.style.marginTop = '0';
    target.style.marginBottom = '0';
    target.offsetHeight; // force reflow
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = `${duration}ms`;
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    setTimeout(() => {
      target.style.removeProperty('height');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
  };

  // State for each dropdown level
  const [kopen1, setKopen1] = useState(false); // Level 1: NestedMenu
  // const [kopen2, setKopen2] = useState(false); // Level 2: Nested-2
  // const [kopen3, setKopen3] = useState(false); // Level 3: Nested-2-2

  // Refs for each submenu <ul>
  const submenu1Ref = useRef<HTMLUListElement>(null);
  // const submenu2Ref = useRef<HTMLUListElement>(null);
  // const submenu3Ref = useRef<HTMLUListElement>(null);

  // Toggle functions for each level
  const toggleLevel1 = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setKopen1(!kopen1);
    e.preventDefault();
    if (!submenu1Ref.current) return;
    if (kopen1) {
      slideUp(submenu1Ref.current, ANIMATION_DURATION);
    } else {
      slideDown(submenu1Ref.current, ANIMATION_DURATION);
    }
  };

  // const toggleLevel2 = (e: React.MouseEvent<HTMLAnchorElement>) => {
  //   e.preventDefault();
  //   if (!submenu2Ref.current) return;
  //   if (kopen2) {
  //     slideUp(submenu2Ref.current, ANIMATION_DURATION);
  //   } else {
  //     slideDown(submenu2Ref.current, ANIMATION_DURATION);
  //   }
  //   setKopen2(!kopen2);
  // };

  // const toggleLevel3 = (e: React.MouseEvent<HTMLAnchorElement>) => {
  //   e.preventDefault();
  //   if (!submenu3Ref.current) return;
  //   if (kopen3) {
  //     slideUp(submenu3Ref.current, ANIMATION_DURATION);
  //   } else {
  //     slideDown(submenu3Ref.current, ANIMATION_DURATION);
  //   }
  //   setKopen3(!kopen3);
  // };

  return (
    <>
      {/* nestedcode open*/}

      <li className="slide has-sub">
        {/* Level 1: NestedMenu */}
        <a
          href="javascript:void(0);"
          className="side-menu__item"
          onClick={toggleLevel1}
        >
          <i className="ri-node-tree side-menu__icon" />
          <span className="side-menu__label">
            {' '}
            {title === 'Master'}
            {title === 'Transaction'}
            {title === 'Settings'}
            {title}
          </span>
          {kopen1 ? (
            <i className="ri-arrow-down-s-line side-menu__angle" />
          ) : (
            <i className="ri-arrow-right-s-line side-menu__angle" />
          )}
        </a>
        {kopen1 && (
        <ul className="pl-4" style={{ paddingLeft : "30px", listStyle : "disc" }}>
          {Object.keys(groupForms).map((groupName) => (
            <li key={groupName} onClick={() => setSelectedGroup(groupName)}>
              <a
                href="javascript:void(0);"
                className="side-menu__item"
                onClick={() => toggleGroupExpansion(groupName)}
              >
              {groupName}
              {(selectedGroup === groupName && expandedGroup[groupName])? <i className="ri-arrow-down-s-line side-menu__angle" /> : <i className="ri-arrow-right-s-line side-menu__angle" /> }
              </a>
              {(groupForms[groupName] && selectedGroup === groupName && expandedGroup[groupName]) && (
                  <ul>
                  {groupForms[groupName].map((form,index) => {
                    const permission = getPermissionsForForm(form._id);
                    const canAdd = permission?.fields.some((field) => field.label === 'CanAdd' && field.value === 'Yes');
                    const canList = permission?.fields.some((field) => field.label === 'CanList' && field.value === 'Yes');
                    return (
                      <li key={form._id} className="">
                        <a
                          key={form._id}
                          href="javascript:void(0);"
                          className="side-menu__item"
                          onClick={() => handleItemClick(form)}
                        >
                          {form.formTitle}
                          {expandedItem === form.formTitle ? (
                            <i className="ri-arrow-down-s-line side-menu__angle" />
                          ) : (
                            <i className="ri-arrow-right-s-line side-menu__angle" />
                          )}
                        </a>
                        {expandedItem === form.formTitle && (
                          <>
                            {' '}
                            {canAdd && (
                              <>
                                <li className="slide">
                                  <a
                                    href="javascript:void(0);"
                                    className="side-menu__item ml-6"
                                    onClick={() => handleNavigation(form._id, 'form')}
                                  >
                                    Create
                                  </a>
                                </li>
                              </>
                            )}
                            {canList && (
                              <li className="slide">
                                <a
                                  href="javascript:void(0);"
                                  className="side-menu__item ml-6"
                                  onClick={() => {
                                    handleNavigation(form._id, 'list');
                                    localStorage.setItem(
                                      'formpermission',
                                      JSON.stringify(permission)
                                    );
                                  }}
                                >
                                  List
                                </a>
                              </li>
                            )}
                          </>
                        )}
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          ))}
        </ul>
        )}
      </li>

      {/* nested code close*/}
      {/* <button
        onClick={() => setExpanded(!expanded)}
        className="flex items-center justify-between w-full text-base font-roboto  mb-2"
      >
        <span className=" flex  items-center ">
          {title === 'Master' && <MdDashboard className="mr-2" />}
          {title === 'Transaction' && <RiPagesFill className="mr-2" />}
          {title === 'Settings' && <IoSettings className="mr-2" />}
          {title}
        </span>

        {expanded ? (
          <FaAngleDown className="text-base  " />
        ) : (
          <FaAngleLeft className="text-base " />
        )}
      </button> */}
      {/* {expanded && (
        <ul className="">
          {forms.map((item) => {
            const permission = getPermissionsForForm(item._id);

            const canAdd = permission?.fields.some(
              (field) => field.label === 'CanAdd' && field.value === 'Yes'
            );
            const canList = permission?.fields.some(
              (field) => field.label === 'CanList' && field.value === 'Yes'
            );
            // console.log(permission,'permission');
            return (
              <li key={item._id} className="">
                <button
                  onClick={() => handleItemClick(item)}
                  className={`w-full text-left p-2 font-roboto rounded flex items-center hover:bg-gray-500 ${
                    expandedItem === item.formTitle ? 'bg-gray-600' : ''
                  }`}
                >
                  <span className="flex-grow overflow-hidden text-ellipsis font-roboto  text-sm ">
                    {item.formTitle}
                  </span>
                  {expandedItem === item.formTitle ? (
                    <FaAngleDown className="text-base text-custom-gray" />
                  ) : (
                    <FaAngleLeft className="text-base text-custom-gray" />
                  )}
                </button>

                {expandedItem === item.formTitle && (
                  <div className="ml-4">
                    {canAdd && (
                      <button
                        onClick={() => handleNavigation(item._id, 'form')}
                        className="block font-roboto w-full text-left"
                      >
                        Create
                      </button>
                    )}
                    {canList && (
                      <button
                        onClick={() => {
                          handleNavigation(item._id, 'list');
                          localStorage.setItem(
                            'formpermission',
                            JSON.stringify(permission)
                          );
                        }}
                        className="block w-full font-roboto text-left"
                      >
                        List
                      </button>
                    )}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )} */}
    </>
  );
};

export default FormList;
