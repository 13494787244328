import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1); // Go back if there is history
    } else {
      navigate('/'); // Go to home page if no history
    }
  };

  return (
    <button
      className="ti-btn bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-indigo-500 dark:focus:ring-offset-white/10"
      onClick={goBack}
    >
      <i className="ti ti-arrow-left"></i>
      Back
    </button>
  );
};

export default BackButton;
