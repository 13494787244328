import React from "react";
import { Field, RadioInputProps } from "../types";

export const Radio = ({ field, value, error, onChange }: RadioInputProps) => {
  return (
    <div className="p-2 bg-gray-200 border rounded flex space-x-6">
      {field.option1 && (
        <label>
          <input
            type="radio"
            name={field.id}

            value={field.option1}
            checked={value === field.option1}
            onChange={onChange}
            className="mr-2"
          />
          {field.option1}
        </label>
      )}

      {field.option2 && (
        <label>
          <input
            type="radio"
            name={field.id} 
            
            value={field.option2}
            checked={value === field.option2}
            onChange={onChange}
            className="mr-2"
          />
          {field.option2}
        </label>
      )}

      {field.option3 && (
        <label>
          <input
            type="radio"
            name={field.id}  
            value={field.option3}
            checked={value === field.option3}
            onChange={onChange}
            className="mr-2"
          />
          {field.option3}
        </label>
      )}

      {field.option4 && (
        <label>
          <input
            type="radio"
            name={field.id}  
            value={field.option4}
            checked={value === field.option4}
            onChange={onChange}
            className="mr-2"
          />
          {field.option4}
        </label>
      )}
    </div>
  );
};
