import React, { useState } from "react";
import { RangeFieldProps } from "../types";

const RangeField: React.FC<RangeFieldProps> = ({ field, sectionId, value, error, handleInputChange }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);


  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div className="relative p-2 border rounded">
      {/* Optional: Display the label */}
      {/* <h2 className="mb-2 font-bold">{field.label || 'Range Slider'}</h2> */}

      {/* Render the range input */}
      <input
        type="range"
        id={field.id}
        min={field.min || 0}
        max={field.max || 100}
        step="1"
        value={value}
        onChange={(e) => handleInputChange(sectionId, field.id, Number(e.target.value), field.type)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="w-full"
      />

      {isTooltipVisible && (
        <div
          className="absolute px-2 py-1 text-xs text-white bg-gray-800 rounded"
          style={{
            left: `calc(${(((field.min || 0)) / ((field.max || 0) - (field.min || 0))) * 100}% - 0px)`,
            bottom: "30px",
            transform: "translateX(-50%)"
          }}
        >
          {value}
        </div>
      )}

      {error && (
        <p className="text-sm text-red-500">{error}</p>
      )}
    </div>
  );
};

export default RangeField;