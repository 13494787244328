import React from 'react';
import { TextInputProps } from '../types';

export const Email = ({ field, value, error, onChange,isReadOnly }: TextInputProps) => {  
  
  return (
    <div className="p-2  rounded py-2" >
    <input
      type="text"
      id={field.id}
      placeholder={field.placeholder}
      required={field.required}
      value={value}
      readOnly={isReadOnly}
      onChange={onChange}
      className={`w-full p-2 border rounded ${error ? 'border-red-500' : ''}`}
    />
    </div>
  );
};
