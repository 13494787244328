// import React from 'react';
// import { CheckBoxProps } from '../types';
  
//   const Checkbox: React.FC<CheckBoxProps> = ({ field,
//     sectionId,
//     value,
//     error,
//     handleInputChange}) => {
//     return (
//         <div className="p-2 bg-gray-200 border rounded">
//           {/* Checkbox label */}
//           <label className="font-bold mb-2">
//             <input
//               type="checkbox"
//               id={field.id}
//               checked={Boolean(value)} // Set the current checked state
//               onChange={(e) => handleInputChange(sectionId, field.id, e.target.checked, field.type)} // Handle changes
//               className="mr-2"
//             />
//             {field.label || "Checkbox"}
//           </label>
    
//           {/* Display the current checked state */}
//           <p>Checked: {value ? "Yes" : "No"}</p>
    
//           {error && <p className="text-sm text-red-500">{error}</p>}
//         </div>
//       );
//   };
  
//   export default Checkbox;



import React from "react";
import { CheckBoxProps } from "../types";

const Checkbox: React.FC<CheckBoxProps> = ({
  field,
  sectionId,
  value,
  error,
  handleInputChange,
}) => {
  // Convert value to string to avoid TypeScript errors
  const isChecked = String(value) === field.checkedValue;

  return (
    <div className="p-2 bg-gray-200 border rounded">
      <label className="font-bold mb-2">
        <input
          type="checkbox"
          id={field.id}
          checked={isChecked}
          onChange={(e) =>
            handleInputChange(sectionId, field.id, e.target.checked, field.type ,field.checkedValue,field.uncheckedValue)
          }
          className="mr-2"
        />
        {field.label || "Checkbox"}
      </label>

      {/* Display the checked state */}
      <p>Checked: {isChecked ? "Yes" : "No"}</p>

      {error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default Checkbox;


