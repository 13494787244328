import React, { useEffect, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { BarChartProps, Field, Form, Section } from '../types';
import axios from 'axios';

am4core.useTheme(am4themes_animated.default);

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

const AmBarChart = ({ field, value, error, onChange }: BarChartProps) => {
  const [dynamicData, setDynamicData] = useState<Form[]>([]);
  const [allSections, setAllSections] = useState<Section[]>([]);
  const [filteredFields, setFilteredFields] = useState<Field[]>([]);
  const [filteredFieldsByLabelId, setFilteredFieldsByLabelId] = useState<
    Field[]
  >([]);
  const [filteredFieldsByValueId, setFilteredFieldsByValueId] = useState<
    Field[]
  >([]);
  const [valData, setValData] = useState<number[]>([]);
  const [labelData, setLabelData] = useState<(string | undefined)[]>([]);
  const [barLabel, setBarLabel] = useState<string>('');
  const authtoken = localStorage.getItem('token');

  // console.log(valData, 'barXdata barChart');
  // console.log(labelData, 'barYdata barChart');

  const getFormData = async () => {
    try {
      const response = await axios.post(
        `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
        { formId: field.formid },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      const data: Form[] = response.data;
      setDynamicData(data);

      const extractedSections = data.flatMap((form: Form) =>
        form.sections.map((section) => ({
          ...section,
          formId: form._id,
        }))
      );
      setAllSections(extractedSections);

      const extractsectionidwise = extractedSections.filter(
        (section) => section.id === field.sectionid
      );
      const fieldsFromSection = extractsectionidwise.flatMap((section) =>
        section.fields.map((fieldItem) => ({
          ...fieldItem,
          formId: section.formId,
        }))
      );
      setFilteredFields(fieldsFromSection);

      const filteredDataByLabelId = fieldsFromSection.filter(
        (fieldItem) => fieldItem.id === field.barLabelId
      );
      setFilteredFieldsByLabelId(filteredDataByLabelId);
      console.log(filteredDataByLabelId, 'filteredDataByLabelId');
      const barXdata = filteredDataByLabelId.map((data) => data.value);
      console.log(barXdata, 'barXdata');

      const filteredDataByValueId = fieldsFromSection.filter(
        (fieldItem) => fieldItem.id === field.barValId?.toString()
      );
      setFilteredFieldsByValueId(filteredDataByValueId);
      setBarLabel(filteredDataByValueId?.[0].label);

      const barYdata = filteredDataByValueId.map((data) => data.value);
      const arr = barYdata.map((val) => Number(val));
      setValData(arr);
      setLabelData(barXdata);
    } catch (err) {
      console.error('Error fetching form data:', err);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  useEffect(() => {
    const chart = am4core.create('chartdiv', am4charts.XYChart);
    chart.scrollbarX = new am4core.Scrollbar();
    chart.responsive.enabled = true;

    // This useEffect will trigger every time valData or labelData changes
    if (valData.length && labelData.length) {
      const chartData = labelData.map((label, index) => ({
        country: label,
        visits: valData[index],
      }));

      chart.data = chartData;
      console.log(chartData, '....chart data');
    }

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'country';
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.labels.template.horizontalCenter = 'right';
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.minGridDistance = 20;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    chart.logo.disabled = true;
    const series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'visits';
    series.dataFields.categoryX = 'country';
    series.tooltipText = '[{categoryX}: bold]{valueY}[/]';
    series.columns.template.strokeWidth = 0;
    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;

    const hoverState = series.columns.template.column.states.create('hover');
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    series.columns.template.adapter.add('fill', (fill, target) => {
      return chart.colors.getIndex(target.dataItem?.index ?? 0);
    });

    chart.cursor = new am4charts.XYCursor();

    return () => {
      chart.dispose();
    };
  }, [valData, labelData]); // Update chart whenever valData or labelData changes

  return (
    <div className="w-full ml-4  bg-white p-6 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
      <h2 className="text-2xl font-semibold text-center mb-4">{field.label}</h2>
      <div className="relative" style={{ height: '400px' }}>
        <div id="chartdiv" style={{ width: '100%', height: '400px' }}></div>
      </div>
    </div>
  );
};

export default AmBarChart;
