import React from 'react';
import { TimeInputProps } from '../types';

export const TimeInput = ({ field, value, error, onChange }: TimeInputProps) => {
  return (
    <div className="p-2 bg-gray-200 border rounded" >
    <input
      type="time"
      id={field.id}
      required={field.required}
      value={value}
      onChange={onChange}
      className={`w-full p-2 border rounded ${error ? 'border-red-500' : ''}`}
    />
    </div>
  );
};