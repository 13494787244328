import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import "../../../global.css"; // I
import interactionPlugin from "@fullcalendar/interaction";
import { Field, Form, FormJSON, User } from "../types";
import axios from "axios";
import { FormRenderer, useFormStore } from "../organisms";
import { toast } from "react-toastify";
import { FiEdit, FiMail, FiTrash, FiUser, FiUserPlus, FiX } from "react-icons/fi";

const BACKEND={
  BACKEND_API:process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
  PLUGIN_BACKEND_API: process.env.PLUGIN_BACKEND_API
}

interface Event {
formId ?:any;
id ?: string;
title?: string;
start?: any;
end?: any;
createdBy ?:any;
calendarType ?:any;
User?:any;
assignedUsers?:any;
assignedUserName ?: any;
}

export const Calendar = (field : any) => {
  const [events, setEvents] = useState<any>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [isEventOpen, setIsEventOpen] = useState<boolean>(false)
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
  const [formConfig, setFormConfig] = useState<FormJSON | null>(null);
  const authtoken=localStorage.getItem('token')
  const { userConfig, setUserConfig } = useFormStore();
  const userMap : any = new Map();
  
  const convertToEvents = (dynamicData: Form[], dynamicFlatData: Form[]) => {
    return dynamicData.map((data: any) => {
      const section = data.sections[0]; 
      const flatSection = dynamicFlatData.find((flatData: any) => flatData._id === data._id)?.sections[0];
     
      const fieldsMap = new Map(
        section.fields.map((f: any) => {
          let value = f.label === "Assigned To" ? f.multiValue : f.value;

          if (f.label === "Calendar Type" && flatSection) {
            const flatField = flatSection.fields.find((flatF: any) => flatF.label === "Calendar Type");
            if (flatField) {
              value = flatField.value;
            }
          }

          return [f.label, value];
        })
      );
      const assignedUsers: any = fieldsMap.get("Assigned To") || [];
      
      const assignedUserNames = assignedUsers.map((user: any) => userMap.get(user) || "Unknown User");
      
      return {
        formId : data.formId,
        id: data._id || null,
        title: fieldsMap.get("Title") || "",
        start: fieldsMap.get("start") || new Date(),
        end: fieldsMap.get("end") ||  new Date(),
        startTime: fieldsMap.get("start"),
        endTime: fieldsMap.get("end"),
        assignedUsers: fieldsMap.get("Assigned To") || null,
        assignedUserName : assignedUserNames,
        createdBy: fieldsMap.get("Created By"),
        User : userMap.get(fieldsMap.get("Created By") || "Unknown"),
        calendarType: fieldsMap.get("Calendar Type") 
      };
    });
  };

  const fetchdetail = async () => {
    if (!userConfig) {
      const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      });
      const data = await response.data;
      setUserConfig(data);
    }
  };

  const fetchMenuItems = async () => {
    try {
      const response = await axios.get(`${BACKEND.BACKEND_API}/other/api/users`,{
        headers: {
          'Authorization': `Bearer ${authtoken}`,
        },
      });

      response.data.forEach((user : any) => {
        userMap.set(user._id, user.name);
      });
    } catch (error) {
      console.error('Failed to fetch form data:', error);
    }
  };

      
  const getFormData = async () => {
    try {
        const flatDataResponse = await axios.post(`${BACKEND.BACKEND_API}/other/getflatdataformsvaluesbyid`, {formId: '67de805592ebbe2576325ade'} ,{
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });

        const flatdata = flatDataResponse.data || [];

        const response = await axios.post(`${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,{formId: '67de805592ebbe2576325ade'} ,{
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        })

        const data: Form[] = response.data || []; 

        const allEvent = convertToEvents(data, flatdata);

        const loggedInUserId =  userConfig?.userId != undefined
        ? userConfig?.userId
        : localStorage.getItem('userid');
        
        const filteredEvents = allEvent.filter((event: any) => {
            return (event.createdBy === loggedInUserId || event?.assignedUsers?.includes(loggedInUserId)) && event.calendarType===field.field.fieldValue;
        });
         setEvents(filteredEvents);
    } catch (err) {
        console.error("Error fetching data:", err);
    }
};

useEffect(() => {
    fetchdetail();
    fetchMenuItems();
    getFormData();
}, [modalOpen, isDeleteModal]); 

const handleDeleteEvent = async() => {
try {
  const respons = await axios.get(
    `${BACKEND.BACKEND_API}/forms/${selectedEvent?.formId}`,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );

  const plugins = respons.data.plugins;
  const matchedPlugin = plugins.find(
    (plugin: any) => plugin.selectedPluginId === '6763be2de3d5ca3d74981b2a'
  );

  const response = await axios.delete(
    `${BACKEND.BACKEND_API}/value/valueDelete/${selectedEvent?.id}`,
    {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    }
  );
  if (matchedPlugin) {
    await axios.delete(
      `${BACKEND.PLUGIN_BACKEND_API}/plugin/pluginvalueDelete/${selectedEvent?.id}`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    );
  }

  if (response.status === 200) {
    const newLocal = toast.success('Form deleted successfully', {
      position: 'bottom-right',
      autoClose: 2000,
    });
  } else {
    toast.error('Failed to delete the form. Please try again later.', {
      position: 'bottom-right',
      autoClose: 2000,
    });
  }
} catch (error) {
  console.error('Error while deleting the form:', error);
  toast.error('An unexpected error occurred. Please try again.', {
    position: 'bottom-right',
    autoClose: 2000,
  });
} finally {
  setIsDeleteModal(false)
  setSelectedEvent(null)
  setModalOpen(false)
}
}

const closeModal = () => {
setModalOpen(false)
localStorage.setItem('mode', 'view');
setFormConfig(null); 
};

const handleCardUpdate =  async() => {
  try {
    
    const formId = selectedEvent?.id;
    const response = await axios.get(`${BACKEND.BACKEND_API}/value/valuebyresid/${formId}`,{
      headers: {
        'Authorization': `Bearer ${authtoken}`,
      },
    });
    setFormConfig(response.data);
    localStorage.setItem('mode', 'edit')
    setIsEventOpen(false)
    setModalOpen(true); 
    }catch (error) {
    console.error('Error fetching card data:', error);
  }

};

const handleCardClick =  async(start:any, end:any, user:any) => {

  const startDate = `${start.getFullYear()}-${(start.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${start.getDate().toString().padStart(2, "0")}T${start
    .getHours()
    .toString()
    .padStart(2, "0")}:${start.getMinutes().toString().padStart(2, "0")}`;
  
  const endDate = `${end.getFullYear()}-${(end.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${end.getDate().toString().padStart(2, "0")}T${end
    .getHours()
    .toString()
    .padStart(2, "0")}:${end.getMinutes().toString().padStart(2, "0")}`;
  
  try {      
    const response = await axios.get(`${BACKEND.BACKEND_API}/forms/67de805592ebbe2576325ade`,{
      headers: {
        'Authorization': `Bearer ${authtoken}`,
      },
    });
    setFormConfig(response.data);

    const newValues: { [key: string]: any } = {
      "Created By": user,
      start: startDate,
      end: endDate,
    };
    const updatedFormConfig = {
      ...response.data,
      sections: response.data.sections.map((section: any) => ({
        ...section,
        fields: section.fields.map((field: any) => ({
          ...field,
          value: field.label as string === "Assigned To" ? field.multivalue : newValues[field.label] || field.value,
        })),
      })),
    };

    setFormConfig(updatedFormConfig);
    setModalOpen(true)
    }catch (error) {
    console.error('Error fetching card data:', error);
  }
};

const handleDateClick = (info: any) => {
    const start = new Date(info.date);
    const end = new Date(start.getTime() + 60 * 60 * 1000); 
    const loggedInUserId =  userConfig?.userId != undefined
  ? userConfig?.userId
  : localStorage.getItem('userid');

    handleCardClick(start,end,loggedInUserId)
    setModalOpen(true);
  };

  const handleSelect = (info: any) => {
    const startDate = new Date(info.start);
    const end =  new Date(info.end)
   
    const loggedInUserId =  userConfig?.userId != undefined
  ? userConfig?.userId
  : localStorage.getItem('userid');

  handleCardClick(startDate,end,loggedInUserId)
    setModalOpen(true);
  };


  const handleEventClick = (info: any) => {
    const clickedEvent = events.find((e : any) => e.id === info.event.id);
    setSelectedEvent(clickedEvent as Event)
    if (clickedEvent) {
      setIsEventOpen(true);
    }
  };

  const handleEventDropOrResize = (info: any) => {
    setEvents((prevEvents : any) =>
      prevEvents.map((event : any) =>
        event.id === info.event.id
          ? { ...event, start: info.event.start.toISOString(), end: info.event.end.toISOString() }
          : event
      )
    );
  };

  return (
    <div className="h-screen p-4 bg-white rounded-lg shadow-md">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={handleDateClick}
        select={handleSelect}
        eventClick={handleEventClick}
        editable={true}
        selectable={true}
        eventDrop={handleEventDropOrResize}
        eventResize={handleEventDropOrResize}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
      />
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[1000]">
          <div className="bg-white p-6 rounded shadow-lg space-y-4 w-auto md:w-[800px] lg:w-[900px] xl:w-[1000px] max-h-[70vh] overflow-y-auto relative z-[1010]">
            <div 
              onClick={closeModal} 
              className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 cursor-pointer"
            >
              ✖
            </div>
            <div className="p-2 mt-6 border rounded">
              {formConfig ? <FormRenderer formData={formConfig} /> : <div>No form configuration available</div>}
            </div>
          </div>
        </div>
      )}


      {isEventOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">

          <div className="bg-white rounded-2xl shadow-2xl w-[700px] max-w-full p-10 relative transition-all duration-300">

            <div className="absolute top-5 right-5 flex gap-5 text-gray-500">
              <FiEdit className="text-2xl cursor-pointer hover:text-black transition-colors" onClick={handleCardUpdate} />
              <FiTrash className="text-2xl cursor-pointer hover:text-black transition-colors" onClick={() => {
                setIsEventOpen(false);
                setIsDeleteModal(true);
              }} />
              <FiMail className="text-2xl cursor-pointer hover:text-black transition-colors" />
              <FiX className="text-2xl cursor-pointer hover:text-black transition-colors" onClick={() => setIsEventOpen(false)} />
            </div>
        
            <div className="flex items-center gap-4 mt-6">
              <div className="w-5 h-5 bg-blue-600 rounded-full"></div>
              <h2 className="text-3xl font-bold text-black">{selectedEvent?.title}</h2>
            </div>
        
            <div className="mt-6 bg-gray-100 p-5 rounded-lg">
              {selectedEvent?.start && selectedEvent?.end ? (
                new Date(selectedEvent.start).toDateString() === new Date(selectedEvent.end).toDateString() ? (
                  <div>
                    <p className="text-gray-600 text-lg font-semibold">Date</p>
                    <p className="text-gray-800 text-lg">
                      {new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(new Date(selectedEvent.start))}
                    </p>
                    <p className="text-gray-500 text-sm mt-1">
                      From <span className="font-medium text-black">
                        {new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }).format(new Date(selectedEvent.start))}
                      </span> 
                      &nbsp;to&nbsp;
                      <span className="font-medium text-black">
                        {new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }).format(new Date(selectedEvent.end))}
                      </span>
                    </p>
                  </div>
                ) : (
                  
                  <div className="grid grid-cols-2 gap-5">
                    <div>
                      <p className="text-gray-600 text-lg font-semibold">From</p>
                      <p className="text-gray-800 text-lg">
                        {new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }).format(new Date(selectedEvent.start))}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-600 text-lg font-semibold">To</p>
                      <p className="text-gray-800 text-lg">
                        {new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }).format(new Date(selectedEvent.end))}
                      </p>
                    </div>
                  </div>
                )
              ) : null}
            </div>
        
            <div className="mt-6 space-y-4 text-gray-600">

              <div className="flex items-center gap-3">
                <FiUser className="text-2xl" />
                <span className="text-lg font-medium">
                  <span className="font-semibold text-black">Created By: </span> 
                  {selectedEvent?.User || "Unknown"}
                </span>
              </div>
        
              {selectedEvent?.assignedUserName?.length > 0 && (
                <div className="flex items-center gap-3">
                  <FiUserPlus className="text-2xl" />
                  <span className="text-lg font-medium">
                    <span className="font-semibold text-black">Participants: </span> 
                    {selectedEvent?.assignedUserName.join(", ")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        
      )}

      {isDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
          <h2 className="text-lg font-semibold mb-4">Delete Event</h2>
          <p className="text-gray-600">Are you sure you want to delete this event? This action cannot be undone.</p>
          <div className="flex justify-end mt-4 space-x-3">
            <button 
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300"
              onClick={() => setIsDeleteModal(false)}
            >
              Cancel
            </button>
            <div 
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-red-700"
              onClick={handleDeleteEvent}
            >
              Delete
            </div>
          </div>
        </div>
      </div>
      )}

    </div>
  );
};
