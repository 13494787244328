import React from "react";
import { TextCardProps } from "../types";

export const TextCard = ({field} : TextCardProps) => {
    const colCount = field.textCardCol||2;
    
    const data = field.textCard ? field.textCard  : [];
    
    return (
        <div className="max-w-lg w-full mx-auto p-4 bg-white border rounded-md shadow-md">
        <h2 className="text-lg font-bold text-gray-800 font-roboto  text-left">{field.label}</h2>
        
        <div className={`grid grid-cols-${colCount}  gap-4 p-4`}>
          {data.map((item : any, index : any) => (
            <div key={index} className={`text-left`}>
              <p className="text-lg font-medium font-roboto text-gray-600">{item.title}</p>
              <p
                className={`text-lg ${item.bold ? "font-bold" : "font-normal"} font-roboto text-gray-800`}
              >
                {item.content}
              </p>
            </div>
          ))}
          {data.length===0 && 
          <div className="text-lg font-bold font-roboto text-black">No records found !</div>
       }
        </div>
      </div>
  );
};