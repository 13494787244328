import { title } from "process";
import React, { useState, useEffect, useRef } from "react";
import { BarChartProps, HeaderProps } from "../types";

export const Header = ({field} : HeaderProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const sidebarRef = useRef<any>();
  const buttonRef = useRef<any>();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event :  any) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const sidebarList = [
    {title:"Home"},
    {title:"Profile"},
    {title:"Contact Us"},
    {title:"Log Out"}
  ]

  return (
    <div className="min-h-64 h-full flex">
      <div className="w-full flex flex-col relative">
        <header className="bg-gray-800 p-4 text-white flex justify-between items-center">
          <button
            ref={buttonRef}
            className="text-white text-3xl"
            onClick={toggleSidebar}
          >
            &#9776;
          </button>
          <h2 className="text-xl">Header</h2>
        </header>

        {sidebarOpen && (
          <div
            ref={sidebarRef}
            className="absolute top-0 left-0 bg-gray-900 w-64 h-full p-4 z-10"
          >
            
            <h3 className="text-white text-2xl p-4">Sidebar</h3>
            {sidebarList.map((data) => (
                <>
                    <a
                        href="#"
                        className="block px-4 p-2 text-lg text-gray-500 rounded-lg hover:bg-gray-50 hover:text-gray-700"
                    >
                        {data.title}
                    </a>
                        
                </>
            ))}
          </div>
        )}

        <div className="flex h-full bg-white p-4 justify-center items-center" >
          <p >Content goes here!</p>
        </div>
      </div>
    </div>
  );
};

