import React, { useState } from 'react';
import { TimeInputProps } from '../types';

export const DateTimeInput = ({ field, value, error, onChange }: TimeInputProps) => {
  const [dateTime, setDateTime] = useState('');

  const handleChange = (event : any) => {
    setDateTime(event.target.value);
  };

  return (
    <div className="p-2 bg-gray-200 border rounded" >
      <input
        id={field.id}
        type="datetime-local"
        value={value}
        required={field.required}
        onChange={onChange}
        className={`w-full p-2 border rounded ${error ? 'border-red-500' : ''}`}
      />
    </div>
  );
};

