import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useFormStore } from '@empire/components/organisms';
import { FaLink } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import * as XLSX from 'xlsx'; 
import { use } from 'i18next';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
};

interface Mapping {
  source: string;
  target: string;
  arrowId: string;
}

export default function ImportStatus() {
   const [mappings, setMappings] = useState<Mapping[]>([])
  const { userConfig, setUserConfig } = useFormStore();
  const [queueData, setQueueData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<string[]>([])
  const [excelData, setExcelData] = useState<any[]>([])
  const [mappedData, setMappedData] = useState<any[]>([])
  const [updatedFormData, setUpdatedFormData] = useState<any[]>([])
  const [formDataa,setFormData]=useState<any>({})
  const [fromDataId,setFormDataId]=useState<string>('')
  const [queueId,setQueueId]=useState<any>()
  const [rowData, setRowData] = useState<any[]>([])
  const [columnDefss, setColumnDefss] = useState<ColDef[]>([])
  const [dynamicFields,setDynamicFields]=useState<any[]>([])
  const [userEntity,setUserEntity]=useState<any[]>([])
  const [formEntity,setFormEntity]=useState<any[]>([])
  const [dynamicEntity,setDynamicEntity]=useState<any[]>([])
  const [dataValidation,setDataValidation]=useState<any[]>([])
  const authtoken = localStorage.getItem('token');
  const OrgId = userConfig?.orgStatus[0].orgId || undefined;
  const userid = userConfig?.userId != undefined ? userConfig?.userId : localStorage.getItem('userid');
  const [isTrue, setIsTrue] = useState(false);
  const [isTruerunning, setIsTrueRunning] = useState(false);

  const columnDefs: ColDef[] = [
    { headerName: 'Date', field: 'date' },
    { headerName: 'Time', field: 'time' },
    { headerName: 'Form Name', field: 'formname' },
    { headerName: 'User Name', field: 'username' },
    { headerName: 'Status', field: 'status' },
    {
      headerName: 'Excel',
      field: 'fileurl',
      width: 250,
      cellRenderer: (params: any) => (
        <a
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline flex items-center justify-center"
          style={{ textAlign: 'center' }}
        >
          <FaLink className="mr-2" />
          Link
        </a>
      ),
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: (params: any) => (
        <div style={{ display: 'flex', gap: '10px' }} >
        <button
          onClick={() => {
            backendRun(params.data); 
          }}
          className="bg-blue-500 text-white px-4 rounded-md hover:bg-blue-700 transition-all duration-300"
          disabled={params.data.status === 'Running'} 
        >
          {params.data.status === 'Running' ? 'Running' : 'Run'} 
        </button>
        <button
        onClick={() => {
          setIsTrueRunning((prevState) => !prevState);
        }}
        className="bg-blue-500 text-white px-4 rounded-md hover:bg-blue-700 transition-all duration-300"
       
      >
        Refresh
      </button>
      </div>
      ),
    },
    {
      headerName: 'Processed Records',
      field: 'Processedrecord',
      cellRenderer: (params: any) => (
        <div>
          <span>{params.data.Processedrecord}</span> / <span>{params.data.datalength}</span>
        </div>
      ),
    }
  ];

  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
        const data = await response.data;
        setUserConfig(data);
      } catch (error) {
        console.error('Error fetching form configuration:', error);
      }
    };

    fetchFormConfig();
  }, []);


  useEffect(() => {
    if (OrgId) {
      const fetchQueueData = async () => {
        try {
          const response = await axios.post(
            `${BACKEND.BACKEND_API}/other/getqueuedata`,
            { OrgId },
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );
          setQueueData(response.data);
        } catch (error) {
          console.error('Error fetching queue data:', error);
        }
      };

      fetchQueueData();
    }
  }, [OrgId,isTrue,isTruerunning]);


  useEffect(()=>{
    const fetchSelectFlatData = async () => {
    try { const response = await axios.post(`${BACKEND.BACKEND_API}/other/excelflatdata`,{
            data:dynamicFields
           },
           {
             headers: {
               Authorization: `Bearer ${authtoken}`,
             },
           }
           );
           setDynamicEntity(response.data)
          
         } catch (error) {
   
           console.error('Failed to fetch form data:', error);
         }
       };
   
       fetchSelectFlatData();
   
     },[dynamicFields])
   
   function getDynamicFieldIds(formData:any) {
   
     const dynamicFieldIds:any = [];
   
     if (formData?.sections && Array.isArray(formData.sections)) {
       formData.sections.forEach((section:any) => {
         if (section?.fields && Array.isArray(section.fields)) {
           const filteredFieldIds = section.fields
             .filter((field:any) => field.selectType === "Dynamic" && field.id && field.fieldid && field.sectionid )
             .map((field:any) => ({ formid: field.formid, fieldid: field.fieldid , sectionid:field.sectionid}));
           dynamicFieldIds.push(...filteredFieldIds);
         }
       });
     }
     
     return dynamicFieldIds;
   }
   
   useEffect(()=>{
   
     const dynamicFieldIds = getDynamicFieldIds(formDataa);
   
     setDynamicFields(dynamicFieldIds);
   
   },[formDataa])
   
     useEffect(() => {
       const fetchMenuItems = async () => {
         try {
           const response = await axios.get(
             `${BACKEND.BACKEND_API}/other/api/users`,
             {
               headers: {
                 Authorization: `Bearer ${authtoken}`,
               },
             }
           );
   
           setUserEntity(response.data);
         } catch (error) {
           console.error('Failed to fetch form data:', error);
         }
       };
       fetchMenuItems();
     }, []);
   
   
     useEffect(() => {
       const fetchMenuItems = async () => {
         try {
           const response = await axios.post(
             `${BACKEND.BACKEND_API}/other/getForm`,
             {
               orgId: OrgId,
             },
             {
               headers: {
                 Authorization: `Bearer ${authtoken}`,
               },
             }
           );
   
           setFormEntity(response.data);
           const [formTitle, _id] = response.data;
         } catch (error) {
           console.error('Failed to fetch form data:', error);
         }
       };
       fetchMenuItems();
     }, []);



     
 const validateRequiredFields = async (data: any) => {

  let allFieldsFilled = true;
  data.forEach((form: any, index: number) => {
    form.sections.forEach((section: any) => {
      section.fields.forEach((field: any) => {
        if (field.required && field.value === "Not Matched") {
          toast.error(`Field ${field.label} Cell ${index+1} Row Not Matched`,{
            position: 'bottom-right',
            autoClose: 2000,
          });
          allFieldsFilled = false; 
        }
      });
    });
  });
  if (allFieldsFilled) {
    try {
      const response = await axios.post(
        `${BACKEND.BACKEND_API}/valueoperation/excelvalueinsert`,
        { data },
        { 
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      if (response.status === 200 && queueId!=='' && queueId!==null && queueId!==undefined) {
       
        try {
          const response = await axios.post(
            `${BACKEND.BACKEND_API}/other/queuestatusupdate`,
            {
               queueId
            },
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );
      
          if (response.status == 200) {
            toast.success('Data processed successfully!', {
              position: 'bottom-right',
              autoClose: 2000,
            });
          } else {
            toast.error('Status not updated. Please try again!', {
              position: 'bottom-right',
              autoClose: 2000,
            });
          }
        } catch (error: unknown) {
          if (error instanceof Error) {
            console.error('Error:', error);
            toast.error('Error: ' + error.message, {
              position: 'bottom-right',
              autoClose: 2000,
            });
          } else {
            console.error('Unknown error:', error);
            toast.error('An unknown error occurred', {
              position: 'bottom-right',
              autoClose: 2000,
            });
          }
        }
      }
    } catch (error) {
    
      toast.error('An error occurred while processing the request!',{
        position: 'bottom-right',
        autoClose: 2000,
      });
      console.error('Error:', error);
    }
  }
};


useEffect(()=>{


  const updatedData = dataValidation.map(item => {
    return {
      ...item,
      userId: userConfig?.userId
    };
  });

  
  const allUserIdAdded = updatedData.every(item => item.hasOwnProperty('userId'));


  if (allUserIdAdded && queueId!==null && queueId!==undefined) {

    validateRequiredFields(updatedData); 
  } else {
    console.log("Not all objects have the userId field.");
  }

},[dataValidation])




const backendRun = async (rowData: any) => {
  const { _id } = rowData;

  if (rowData.status === 'Processed') {
    toast.error('This Excel is already inserted', {
      position: 'bottom-right',
      autoClose: 2000,
    });
  } else {
    toast.info('Data is being processed...', {
      position: 'bottom-right',
      autoClose: 2000,
    });

    try {

      const runningresponse = await axios.post(
        `${BACKEND.BACKEND_API}/other/queuestatusupdaterunning`,
        {
          queueId: _id,
        },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      if (runningresponse.status == 200) {
        
        setIsTrueRunning((prevState) => !prevState);  

        
        const response = await axios.post(
          `${BACKEND.BACKEND_API}/other/queuerun`,
          {
            data: { ...rowData, orgId: OrgId, userId: userConfig?.userId },
          },
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        if (response.status === 200) {
          const statusUpdateResponse = await axios.post(
            `${BACKEND.BACKEND_API}/other/queuestatusupdate`,
            {
              queueId: _id,
            },
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );

          if (statusUpdateResponse.status == 200) {
            setIsTrue((prevState) => !prevState);
          }
        } else {
          toast.error('Failed to process data. Please try again later.', {
            position: 'bottom-right',
            autoClose: 2000,
          });
        }
      } else {
        toast.error('Running Status not updated. Please try again!', {
          position: 'bottom-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('API call failed:', error);
      toast.error('Error occurred while processing. Please try again.', {
        position: 'bottom-right',
        autoClose: 2000,
      });
    }
  }
};

useEffect(() => {
  if (OrgId) {
    const fetchQueueData = async () => {
      try {
        const response = await axios.post(
          `${BACKEND.BACKEND_API}/other/getqueuedata`,
          { OrgId },
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );
        setQueueData(response.data);
      } catch (error) {
        console.error('Error fetching queue data:', error);
      }
    };

    fetchQueueData();
  }
}, [OrgId, isTrue, isTruerunning]);  




// ..........................please dont remove the commented code 
// const handleRun = async (rowData: any) => {

//   setFormData(rowData?.form)
//   setFormDataId(rowData?.formid)
//   setMappings(rowData?.mappings)
//   const {_id}=rowData
//   setQueueId(_id)

//   if (rowData.status === 'Processed') {
//     toast.error('This Excel is already inserted', {
//       position: 'bottom-right',
//       autoClose: 2000,
//     });
//   } else {
//     try {
//       const fileUrl = rowData.fileurl;
//       const response = await fetch(fileUrl, { method: 'HEAD' });
//       if (!response.ok) {
//         throw new Error(`File not found at ${fileUrl}`);
//       }

     
//       const fileResponse = await axios.get(fileUrl, { responseType: 'arraybuffer' });
//       const file = fileResponse.data;

  
//       const workbook = XLSX.read(file, { type: 'array' });
//       const sheetNames = workbook.SheetNames;
//       const sheet = workbook.Sheets[sheetNames[0]]; 

      
//       const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
//       const headers = jsonData[0] as string[]; 
//       const data = jsonData.slice(1); 

     
//       const dataWithHeaders = (data as any[]).map((row: any[]) => {
//         let rowObject: { [key: string]: string } = {};
//         row.forEach((cell, index) => {
//           rowObject[headers[index]] = cell;
//         });
//         return rowObject;
//       });

    
//       setHeaders(headers); 
//       setExcelData(dataWithHeaders);

    
      
//     } catch (error: unknown) {
//       if (error instanceof Error) {
//         console.error('Error fetching Excel file:', error.message);
//         toast.error('Failed to fetch the Excel file', {
//           position: 'bottom-right',
//           autoClose: 2000,
//         });
//       } else {
//         console.error('Unexpected error:', error);
//         toast.error('An unexpected error occurred', {
//           position: 'bottom-right',
//           autoClose: 2000,
//         });
//       }
//     }
//   }
// };

// ..........................please dont remove the commented code 



const validateRequiredFieldss = () => {
  let allFieldsMapped = true;
  const missingMappings: string[] = [];


  
  formDataa?.sections?.forEach((section: any) => {
    section?.fields?.forEach((field: any) => {
      if (field.required && field.value === "") {
        
        const mapping = mappings.find((mapping) => mapping.target === field.id);

        if (!mapping) {
          
          missingMappings.push(field.label);
          allFieldsMapped = false;
        }
      }
    });
  });




  if (!allFieldsMapped) {
    toast.error(`Required fields are missing mappings: ${missingMappings.join(", ")}`,{
      position: 'bottom-right',
      autoClose: 2000,
    });
    return false; 
  }

  return true; 
};


const sourceItems = headers; 
let targetItems: { id: string; label: string }[] = [];
formDataa?.sections?.forEach((section: any) => {
  section?.fields?.forEach((field: any) => {
    targetItems.push({ id: field.id, label: field.label });
  });
});

const generateMappedData = () => {

const isValid = validateRequiredFieldss();  

if (!isValid) {
  return;  
}


    const mappedData = excelData.map((row) => {
    let mappedRow: any = {};

    mappings.forEach((mapping) => {
      const sourceColumnIndex = parseInt(mapping.source, 10) - 1;
      mappedRow[mapping.target] = row[sourceItems[sourceColumnIndex]];
    });

    return mappedRow; 
  });


  setMappedData(mappedData);


};


useEffect(()=>{


  generateMappedData()

  
},[formDataa,headers])



useEffect(() => {


if (mappedData.length > 0 && mappedData) {

    const updatedData = mappedData.map((row) => {
  
    const newFormData = structuredClone(formDataa); 

    newFormData.sections = newFormData.sections.map((section: any) => {
      section.fields = section.fields.map((field: any) => {
        if (row.hasOwnProperty(field.id)) {
          return { ...field, value: row[field.id] };
        }
        return field;
      });
      return section;
    });

    return newFormData;
  });



  setUpdatedFormData(updatedData);

}
}, [mappedData]);


useEffect(() => {
  if (updatedFormData.length > 0) {
    // Extract columns dynamically from all field labels
    const allFields = updatedFormData.flatMap((form) => form.sections.flatMap((section:any) => section.fields));
    const uniqueLabels = Array.from(new Set(allFields.map((field) => field.label)));

    const dynamicColumnDefs = [
      
      ...uniqueLabels.map((label) => ({
        headerName: label, 
        field: label, 
        cellStyle: (params:any) => {
         
          if (params.value === "Not Matched") {
            return { backgroundColor: 'red', color: 'white' }; 
          }
          return null; 
        },
      })),
    ];

    setColumnDefss(dynamicColumnDefs);

    const extractedData = updatedFormData.map((form) => {
      const row: any = { formTitle: form.formTitle };
      form.sections.forEach((section:any) => {
        section.fields.forEach((field:any) => {
          row[field.label] = field.value;
        });
      });
      return row;
    });

    setRowData(extractedData);
  }
}, [updatedFormData]);


function updateFormDataWithDynamicEntity(updatedFormData: any, dynamicEntity: any, userEntity: any, formEntity: any): any {
updatedFormData.forEach((form: any) => {
    form.sections.forEach((section: any) => {
        section.fields.forEach((field: any) => {
            if (field.selectType === "Dynamic") {
                let isMatched = false; 
                dynamicEntity.forEach((dynamic: any) => {
                    dynamic.sections.forEach((dynamicSection: any) => {
                        dynamicSection.fields.forEach((dynamicField: any) => {
                          if (field.value === dynamicField.value) {
                                field.value = dynamic._id; 
                                isMatched = true;  }});});});if (!isMatched) {field.value = 'Not Matched';} }
             if (field.selectType === "UserEntity") {
                let isUserMatched = false; 
                const matchedUser = userEntity.find((user: any) => user.name === field.value);
                if (matchedUser) {
                    field.value = matchedUser._id;  
                    isUserMatched = true; 
                }
                if (!isUserMatched) {
                    field.value = 'Not Matched';
                }
            }
            if (field.selectType === "FormEntity") {
                let isFormMatched = false; 
                const matchedForm = formEntity.find((formItem: any) => formItem.formTitle === field.value);
                if (matchedForm) {
                    field.value = matchedForm._id;  
                    isFormMatched = true; 
                }
                if (!isFormMatched) {
                    field.value = 'Not Matched';
                }
            }
        });
    });
});

return updatedFormData;
}



useEffect(() => {



const result = updateFormDataWithDynamicEntity(updatedFormData, dynamicEntity, userEntity, formEntity);
const updatedResult = result.map((item: any) => ({
  ...item,
  formId: fromDataId,
  userId: userConfig?._id
}));


setDataValidation(updatedResult) 


}, [updatedFormData]);

  
  return (
    <div className="p-6">
      <ToastContainer />
      <h4 className="mb-6">Excel Import Queue's List</h4>
      <hr />
      <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={queueData} 
          pagination={true}
        />
      </div>
    </div>
  );
}


