import React, { useEffect, useState } from 'react';

interface PrintButtonProps {
  printContentId: string;
  setExpandedForms: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  setExpandedSections: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  groupedForms: { [key: string]: any };
}

const PrintButton: React.FC<PrintButtonProps> = ({
  printContentId,
  setExpandedForms,
  setExpandedSections,
  groupedForms,
}) => {
  const [shouldPrint, setShouldPrint] = useState(false);

  useEffect(() => {
    if (shouldPrint) {
      // ✅ DOM update hone ka waqt dene ke liye ek aur delay lagaya
      setTimeout(() => {
        handlePrint();
        setShouldPrint(false);
      }, 500); // 🕐 500ms delay ensures DOM has updated before printing
    }
  }, [shouldPrint]);

  const expandAll = () => {
    const newExpandedForms: { [key: string]: boolean } = {};
    const newExpandedSections: { [key: string]: boolean } = {};

    Object.keys(groupedForms).forEach((formTitle) => {
      newExpandedForms[formTitle] = true;
      groupedForms[formTitle].forEach((form: any) => {
        form.sections.forEach((section: any) => {
          newExpandedSections[section.id] = true;
        });
      });
    });

    setExpandedForms(newExpandedForms);
    setExpandedSections(newExpandedSections);

    // ✅ Pehle expand karega, phir ek chhoti delay ke baad print karega
    setTimeout(() => {
      setShouldPrint(true);
    }, 300); // 🕐 Ensure state updates first
  };

  const handlePrint = () => {
    const printContent = document.getElementById(printContentId);
    if (!printContent) return;

    const newWin = window.open('', '', 'width=1200,height=1200');
    if (newWin) {
      const styles = Array.from(document.styleSheets)
        .map((sheet) => {
          try {
            return Array.from(sheet.cssRules)
              .map((rule) => rule.cssText)
              .join('\n');
          } catch (e) {
            return '';
          }
        })
        .join('\n');

      newWin.document.write(`
        <html>
        <head>
          <title>Print</title>
          <style>
            ${styles}
            
            @media screen {
              .print-header, .print-footer { display: none; }
            }

            @media print {
              .print-header, .print-footer {
                position: fixed;
                width: 100%;
                text-align: center;
                background: lightgray;
                padding: 10px;
                font-size: 14px;
              }
              .print-header { top: 0; }
              .print-footer { bottom: 0; }

              .print-content {
                margin-top: 60px;
                margin-bottom: 60px;
              }

              .page-break { page-break-before: always; }
            }
          </style>
        </head>
        <body>
          <div class="print-header">Adaptix Report</div>
          <div class="print-content">
            ${printContent.outerHTML}
          </div>
          <div class="print-footer">This is a Computer Generated Report</div>
        </body>
        </html>
      `);

      newWin.document.close();
      setTimeout(() => {
        newWin.print();
        newWin.close();
      }, 500);
    }
  };

  return (
    <button onClick={expandAll} className="ti-btn ti-btn-primary">
      <i className="ti ti-printer"></i> Print
    </button>
  );
};

export default PrintButton;
