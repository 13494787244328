import React, { useState } from 'react';
import { SideBarProps } from '../types';
import { RiArrowDropDownLine } from "react-icons/ri";

export const BottomSidebar = ({ field }: SideBarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('click me');

  const contentList = field.bottomSidebar ? field.bottomSidebar : [];

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item: any) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <div className="min-h-24">
      {isOpen && (
        <div
          className="fixed inset-0 bg-gray-500 opacity-50 z-10"
          onClick={closeSidebar}
        />
      )}

      {/* Toggle button */}
      <button
        onClick={toggleSidebar}
        className="bg-gray-200 p-2 rounded-lg z-20 flex flex-row items-center font-bold"
      >
        <RiArrowDropDownLine size={25} />
        {selectedItem}
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          className="absolute bottom-0 left-0 w-full bg-white shadow-lg rounded-t-3xl z-20"
        >
          <div className="p-4 text-center">
            <h2 className="text-xl font-bold mb-4">Choose an Item</h2>
            <ul>
              {contentList.map((item: any, id: number) => (
                <React.Fragment key={id}>
                  <li
                    onClick={() => handleItemClick(item)}
                    className="p-2 mb-2 cursor-pointer text-blue-600 hover:bg-gray-200 rounded"
                  >
                    {item}
                  </li>
                  <hr className="border-t border-gray-300" />
                </React.Fragment>
              ))}
              <li
                onClick={closeSidebar}
                className="p-2 mb-2 cursor-pointer hover:bg-gray-200 rounded"
              >
                Cancel
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
