import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DonutChartProps} from '../types'; 

const DonutChart = ({ field, value, error, onChange }: DonutChartProps) => {

  const series =  field.donutChart===undefined ? [0] : field.donutChart?.map((data) => Number(data.value));  
  const label = field.donutChart===undefined ? [] : field.donutChart?.map((data) => data.label);

  const [options] = useState<any>({
    chart: {
      width: '100%', 
      type: 'donut',
    },
    labels: label,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });

  return (
    <div className="max-w-3xl mx-auto p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold text-center mb-6">{field.label}</h2>
        <div className="flex justify-center">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
          />
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
