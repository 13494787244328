import React, { useEffect, useState } from 'react';
import { Field, Form, LineChartProps, Section } from '../types';
import axios from 'axios';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import LineChart from './LineChart';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

ChartJS.register(ArcElement, Tooltip, Legend);

export const LineGraph = ({
  field,
  value,
  error,
  onChange,
}: LineChartProps) => {
  return (
    <>
      {field.selectType === 'Dynamic' && (
        <LineChartInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      )}
      {field.selectType === 'Static' && field.lineChart && (
        <LineChart
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      )}
    </>
  );
};

export const LineChartInput = ({
  field,
  value,
  error,
  onChange,
}: LineChartProps) => {
  const [dynamicData, setDynamicData] = useState<Form[]>([]);
  const [allSections, setAllSections] = useState<Section[]>([]);
  const [filteredFields, setFilteredFields] = useState<Field[]>([]);
  const [filteredFieldsByLabelId, setFilteredFieldsByLabelId] = useState<
    Field[]
  >([]);
  const [filteredFieldsByValueId, setFilteredFieldsByValueId] = useState<
    Field[]
  >([]);
  const [valData, setValData] = useState<number[]>([]);
  const [labelData, setLabelData] = useState<string[]>([]);
  const [xVal, setXVal] = useState('');
  const [yVal, setYVal] = useState('');
  const authtoken = localStorage.getItem('token');

  const getFormData = async () => {
    try {
      //   const response = await axios.post(`${BACKEND.BACKEND_API}/getformsvaluesbyid`, {
      //     formId: field.formid,
      //   });

      const response = await axios.post(
        `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
        { formId: field.formid },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      const data: Form[] = response.data;
      setDynamicData(data);

      const extractedSections = data.flatMap((form: Form) =>
        form.sections.map((section) => ({
          ...section,
          formId: form._id,
        }))
      );
      setAllSections(extractedSections);

      const extractsectionidwise = extractedSections.filter(
        (section) => section.id === field.sectionid
      );

      const fieldsFromSection = extractsectionidwise.flatMap((section) =>
        section.fields.map((fieldItem) => ({
          ...fieldItem,
          formId: section.formId,
        }))
      );
      setFilteredFields(fieldsFromSection);

      const filteredDataByLabelId = fieldsFromSection.filter(
        (fieldItem) => fieldItem.id === field.lineLabelId
      );
      setFilteredFieldsByLabelId(filteredDataByLabelId);

      const barXdata = filteredDataByLabelId.map((data) => {
        const d = data.value === undefined ? '' : data.value.toString();
        return d;
      });

      const filteredDataByValueId = fieldsFromSection.filter(
        (fieldItem) => fieldItem.id === field.lineValId?.toString()
      );
      setFilteredFieldsByValueId(filteredDataByValueId);

      const barYdata = filteredDataByValueId.map((data) => data.value);
      const arr = barYdata.map((val) => Number(val));
      setValData(arr);
      setLabelData(barXdata);
      setXVal(filteredDataByLabelId?.[0].label);
      setYVal(filteredDataByValueId?.[0].label);
    } catch (err) {
      console.error('Error fetching form data:', err);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  const label = labelData === undefined ? [''] : labelData?.map((data) => data);
  const values = valData;
  const data = {
    labels: label,
    datasets: [
      {
        label: `${yVal}`,
        data: values,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: `${xVal}`,
        },
      },
      y: {
        title: {
          display: true,
          text: `${yVal}`,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    // <div className="w-full h-full max-w-xl mx-auto max-h-full bg-white p-6 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
    <div className="w-full ml-4  bg-white p-6 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
      <h2 className="text-2xl font-semibold text-center mb-4">{field.label}</h2>
      <div className="relative" style={{ height: '400px' }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default LineChartInput;
