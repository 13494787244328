import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface TextEditorProps {
  field?: any;
  onChange?: (content: string) => void;
  value?: string;
  isediting?: boolean;
}

export const TextEditor: React.FC<TextEditorProps> = ({ field, onChange, value, isediting }) => {
;
  const [content, setContent] = useState<string>(value || "");

  useEffect(() => {
    setContent(value || "");
  }, [value]);

  const handleChange = (newContent: string) => {
    setContent(newContent);
    if (onChange) {
      onChange(newContent);
    }
  };

  if (field) field.value = content ? content.toString() : "";

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg border">
      {/* <h2 className="text-lg font-semibold mb-3">Rich Text Editor</h2> */}
      <div className="border rounded-lg overflow-hidden">
        <ReactQuill
          value={content}
          onChange={handleChange}
          theme="snow"
          className="h-auto"
          readOnly={!isediting}
          modules={{ toolbar: isediting ? true : false }}
        />
      </div>
    </div>
  );
};

