import { useFormStore } from 'libs/components/organisms/UserStore';
import { GoogleTranslate } from '@empire/components/organisms';
import { Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { FiMenu, FiBell } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BsPlugin } from 'react-icons/bs';
import { RiHome2Line } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from './i18n';
import loginavata from '@empire/theme/assets/img/users/1.jpg';
import elonisk from '@empire/theme/assets/img/users/17.jpg';
import dlogos from '@empire/theme/assets/img/brand-logos/desktop-logo.png';
import blogo from '@empire/theme/assets/img/brand-logos/desktop-dark.png';
import flag10 from '@empire/theme/assets/img/flags/10.png';
import axios from 'axios';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(
    localStorage.getItem('language') ?? 'en'
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const home = localStorage.getItem('home');

  const handleChangeLanguage = (language: any) => {
    localStorage.setItem('language', language);
    // setLanguage(language);
    // changeLanguage(language);
  };

  // const toggleSidebar = () => {
  //   const htmlTag = document.documentElement;

  //   if (htmlTag.getAttribute('toggled') === 'icon-overlay-close') {
  //     htmlTag.removeAttribute('toggled');
  //   } else {
  //     htmlTag.setAttribute('toggled', 'icon-overlay-close');
  //   }
  // };

  const authtoken = localStorage.getItem('token');
  const { userConfig, setUserConfig } = useFormStore();

  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        });
        const data = await response.data;
        setUserConfig(data);
      } catch (error) {
        console.error('Error fetching form configuration:', error);
      }
    };

    fetchFormConfig();
  }, []);

  const handleLogout = async () => {
    const response = await fetch(`${BACKEND.BACKEND_API}/user/logout`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    const updateUserLang = async () => {
      let language = '';
      const cookies = document.cookie.split('; ');
      for (const cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key === 'googtrans') {
          language = value;
        }
      }
      const userId = localStorage.getItem('userid') || '';
      if (userId) {
        const updateUserData = {
          id: userId,
          language: language,
          type: 'updateUserlang',
        };
        await axios.post(
          `${BACKEND.BACKEND_API}/user/updateLang`,
          updateUserData
        );
      }
    };
    updateUserLang();

    if (response.ok) {
      localStorage.removeItem('token');
      localStorage.removeItem('userdata');
      localStorage.removeItem('permission');
      localStorage.removeItem('user');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('home');
      localStorage.removeItem('pluginsection');
      localStorage.removeItem('reporting');
      localStorage.removeItem('lastModuleId');
      localStorage.removeItem('userid');

      window.location.href = '/';
    } else {
      alert('Logout failed!');
    }
  };

  const events = ['click', 'keydown', 'scroll', 'mousemove'];

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        handleLogout();
      }, 60 * 60 * 1000);
    };

    events.forEach((event) => window.addEventListener(event, resetTimer));
    resetTimer();

    return () => {
      clearTimeout(timer);
      events.forEach((event) => window.removeEventListener(event, resetTimer));
    };
  }, []);

  const fieldStaggerVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: (index: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.1,
        duration: 0.4,
        ease: [0.25, 0.1, 0.25, 1],
      },
    }),
  };

  {
    /* Kalpesh Code Start */
  }
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Close dropdown on outside click
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // Notification Interface
  interface Notification {
    id: number;
    name: string;
    message: string;
    time: string;
    image: string;
  }

  // Sample Notifications
  const initialNotifications: Notification[] = [
    {
      id: 1,
      name: 'Elon Isk',
      message: 'Hello there! How are you doing?',
      time: '2 min',
      image: elonisk,
    },
   
  ];

  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [notifications, setNotifications] =
    useState<Notification[]>(initialNotifications);

  // Ref for dropdown
  const menuRef = useRef<HTMLDivElement>(null); // dropdownRef ka naam change karke menuRef kar diya gaya

  // Click outside to close dropdown
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setNotificationOpen(false);
      }
    }

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup listener on unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Remove single notification
  const removeNotification = (id: number) => {
    setNotifications(
      notifications.filter((notification) => notification.id !== id)
    );
  };

  // Mark all as read
  const clearNotifications = () => {
    setNotifications([]);
  };

  // App Link Interface
  interface AppLink {
    id: number;
    name: string;
    icon: string;
    color: string;
    link: string;
  }

  // User Data
  const user = {
    name: userConfig?.name.toUpperCase(),
    role: 'ROLE',
    avatar: loginavata,
    balance: '$7,12,950',
  };

  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);
  const flags = [
    { img: flag10, name: 'INDIA' },
  ];

  const countryRef = useRef<HTMLDivElement>(null); 


  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        countryRef.current &&
        !countryRef.current.contains(event.target as Node)
      ) {
        setIsCountryDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [theme, setTheme] = useState<string>(() => {
    return localStorage.getItem('theme') || 'light';
  });

  useEffect(() => {
    
    document.documentElement.classList.remove('light', 'dark');
    document.documentElement.classList.add(theme);
    localStorage.setItem('theme', theme);


    const header = document.getElementById('header');
    if (header) {
      if (theme === 'dark') {
        header.classList.add('bg-gray-900', 'text-white');
        header.classList.remove('bg-white', 'text-black');
      } else {
        header.classList.add('bg-white', 'text-black');
        header.classList.remove('bg-gray-900', 'text-white');
      }
    }
  }, [theme]);

  const toggleTheme = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () =>
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  const toggleFullscreen = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault(); 

    if (!document.fullscreenElement) {
      document.documentElement
        .requestFullscreen()
        .then(() => setIsFullscreen(true));
    } else {
      document.exitFullscreen().then(() => setIsFullscreen(false));
    }
  };

  const toggleSidebar = () => {
    const htmlTag = document.documentElement;
    const currentState = htmlTag.getAttribute('toggled');

    if (window.innerWidth >= 992) {
      // ✅ Large Screens: Use "icon-overlay-close"
      if (currentState === 'icon-overlay-close') {
        console.log('Opening sidebar on large screen...');
        htmlTag.removeAttribute('toggled'); // ✅ Large screens pe default open hoga
      } else {
        console.log('Closing sidebar on large screen...');
        htmlTag.setAttribute('toggled', 'icon-overlay-close'); 
      }
    } else {

      if (currentState === 'open') {
        console.log('Closing sidebar on small screen...');
        htmlTag.setAttribute('toggled', 'close');
      } else {
        console.log('Opening sidebar on small screen...');
        htmlTag.setAttribute('toggled', 'open');
      }
    }

    console.log('New sidebar state:', htmlTag.getAttribute('toggled'));
  };


  useEffect(() => {
    const updateSidebarState = () => {
      const htmlTag = document.documentElement;
      if (window.innerWidth < 992) {
        htmlTag.setAttribute('toggled', 'close'); 
      } else {
        htmlTag.removeAttribute('toggled'); 
      }
    };

    updateSidebarState(); 

    window.addEventListener('resize', updateSidebarState); 

    return () => {
      window.removeEventListener('resize', updateSidebarState);
    };
  }, []);

  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const htmlTag = document.documentElement;
      const sidebar = document.getElementById('sidebar');
      const toggleButton = document.querySelector('.sidebar-toggle');

      if (
        window.innerWidth < 992 && 
        sidebar &&
        !sidebar.contains(event.target as Node) &&
        toggleButton &&
        !toggleButton.contains(event.target as Node)
      ) {
        
        htmlTag.setAttribute('toggled', 'close'); 
      }
    };

    document.addEventListener('click', handleClickOutside);
    console.log('Event listener added for outside click...');

    return () => {
      document.removeEventListener('click', handleClickOutside);
      console.log('Event listener removed...');
    };
  }, []);

  // const toggleSidebar = () => {
  //   const htmlTag = document.documentElement;

  //   if (htmlTag.getAttribute('toggled') === 'icon-overlay-close') {
  //     htmlTag.removeAttribute('toggled');
  //   } else {
  //     htmlTag.setAttribute('toggled', 'icon-overlay-close');
  //   }
  // };

  const [isImportDropdownOpen, setIsImportDropdownOpen] = useState(false);
  const importRef = useRef<HTMLDivElement>(null);

  // ✅ Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        importRef.current &&
        !importRef.current.contains(event.target as Node)
      ) {
        setIsImportDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header
      id="header"
      className="header custom-sticky !top-0 !w-full sticky-pin"
    >
      {/* <div className="flex items-center">
        <motion.button
          className="mr-4 text-xl md:hidden"
          onClick={toggleSidebar}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FiMenu />
        </motion.button>
        <motion.div variants={fieldStaggerVariants}>
          <div className="text-2xl font-bold text-white font-roboto">{('Adaptix')}</div>
        </motion.div>
      </div> */}
      {/* <div className="flex items-center space-x-4">
        {home === "PLUGINHOME" ? (
          <div className="text-xl" onClick={() => { navigate('/modules'); localStorage.setItem('home', "CRMHOME"); }}>
            <RiHome2Line />
          </div>
        ) : (
          <div className="text-xl" onClick={() => { navigate('/plugin'); localStorage.setItem('home', "PLUGINHOME"); }}>
            <BsPlugin />
          </div>
        )}
        <div className="text-xl">
          <FiBell />
        </div>
        <div className="text-sm">
          <span translate="no">{userConfig?.name.toUpperCase()}</span>
        </div>


        <GoogleTranslate />
        
        <motion.button
          className="p-2 px-4 text-white rounded bg-curious-blue-600 font-roboto"
          onClick={handleLogout}
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {'logout'}
        </motion.button>
      </div> */}

      <nav className="main-header" aria-label="Global">
        <div className="header-content flex justify-between items-center p-4">
          {/* Left Section - Sidebar Toggle */}
          <div className="header-left">
            <button
              type="button"
              className="sidebar-toggle !w-100 !h-100 "
              onClick={toggleSidebar}
            >
              <span className="sr-only">Toggle Navigation</span>
              <i
                className="ri-arrow-left-circle-line header-icon"
                style={{ fontSize: '24px' }}
              ></i>
            </button>
          </div>
          {/* Center Section - Logo */}
          <div className="responsive-logo">
            <a className="responsive-logo-dark" href="/" aria-label="Brand">
              <img src={dlogos} alt="logo" className="mx-auto" />
            </a>
            <a
              className="responsive-logo-light"
              href="index.html"
              aria-label="Brand"
            >
              <img src={blogo} alt="logo" className="mx-auto" />
            </a>
          </div>
          {/* Right Section - Country Dropdown */}

          <div className="header-right flex items-center space-x-4">
            {home === 'PLUGINHOME' ? (
              <>
                <a
                  href="/modules"
                  aria-label="Go to Modules"
                  className="flex justify-center items-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 dark:hover:text-white transition-all"
                  onClick={() => localStorage.setItem('home', 'CRMHOME')}
                >
                  <i className="ti ti-home-2 text-lg"></i>
                </a>
                <a
                  href="/plugin"
                  aria-label="Go to Plugin"
                  className="flex justify-center items-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 dark:hover:text-white transition-all"
                  onClick={() => localStorage.setItem('home', 'PLUGINHOME')}
                >
                  <i className="ti ti-plug text-lg"></i>
                </a>
              </>
            ) : (
              <>
                <a
                  href="/plugin"
                  aria-label="Go to Plugin"
                  className="flex justify-center items-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 dark:hover:text-white transition-all"
                  onClick={() => localStorage.setItem('home', 'PLUGINHOME')}
                >
                  <i className="ti ti-plug text-lg"></i>
                </a>
                <a
                  href="/modules"
                  aria-label="Go to Modules"
                  className="flex justify-center items-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 dark:hover:text-white transition-all"
                  onClick={() => localStorage.setItem('home', 'CRMHOME')}
                >
                  <i className="ti ti-home-2 text-lg"></i>
                </a>
              </>
            )}

            {/* <a
              href="/modules"
              aria-label="Go to Modules"
              className="flex justify-center items-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 dark:hover:text-white transition-all"
            >
              <i className="ti ti-home-2 text-lg"></i>
            </a> */}
            <div className="relative sm:block" ref={importRef}>
              {/* ✅ Import Icon Button */}
              <a
                id="dropdown-import"
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default anchor behavior
                  setIsImportDropdownOpen(!isImportDropdownOpen);
                }}
                className="flex justify-center items-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 dark:hover:text-white transition-all"
              >
                <i className="ti ti-table-import"></i> {/* Import Icon */}
              </a>

              {/* ✅ Import Dropdown Menu */}
              {isImportDropdownOpen && (
                <div className="absolute right-0 mt-2 min-w-[12rem] bg-white border rounded-md shadow-lg dark:bg-gray-800">
                  <div className="divide-y divide-gray-200 dark:divide-white/10">
                    {/* ✅ Import Data Link */}
                    <Link
                      to="/import-data"
                      className="block w-full text-left px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                      onClick={() => setIsImportDropdownOpen(false)} //✅ Close dropdown after click
                    >
                      📂{' '}
                      <span className="text-sm font-medium">Import Data</span>
                    </Link>

                    {/* ✅ Import Status Link */}
                    <Link
                      to="/import-status"
                      className="block w-full text-left px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                      onClick={() => setIsImportDropdownOpen(false)} // ✅ Close dropdown after click
                    >
                      📊{' '}
                      <span className="text-sm font-medium">Import Status</span>
                    </Link>
                  </div>
                </div>
              )}
            </div>

            <div className="relative sm:block" ref={countryRef}>
              {' '}
              {/* ✅ Changed from dropdownRef to countryRef */}
              {/* ✅ **Button to Toggle Dropdown** */}
              <button
                id="dropdown-flag"
                onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                className="p-0 flex items-center justify-center flex-shrink-0 h-[2.375rem] w-[2.375rem] border-0 rounded-full shadow-none focus:ring-gray-400 text-xs dark:focus:ring-white/10"
              >
                <img
                  src={flag10}
                  alt="flag-img"
                  className="h-[1.5rem] w-[1.5rem]"
                />
              </button>
              {isCountryDropdownOpen && (
                <div className="absolute right-0 mt-2 min-w-[10rem] bg-white border rounded-md shadow-lg dark:bg-gray-800">
                  <div className="divide-y divide-gray-200 dark:divide-white/10">
                    {/* {flags.map((country, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <img
                          src={country.img}
                          alt="flag-img"
                          className="h-[1.375rem] w-[1.375rem] rounded-sm"
                        />
                        <p className="text-xs font-medium">{country.name}</p>
                      </div>
                    ))} */}

                    <div className="px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 dark:hover:bg-gray-700">
                      <GoogleTranslate />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="header-theme-mode  sm:block">
            {theme === 'light' ? (
              <a
                href="#"
                onClick={toggleTheme}
                aria-label="Switch to dark mode"
                className="flex justify-center items-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 dark:hover:text-white transition-all"
              >
                <i className="ri-moon-line header-icon"></i>
              </a>
            ) : (
              <a
                href="#"
                onClick={toggleTheme}
                aria-label="Switch to light mode"
                className="flex justify-center items-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500 dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 dark:hover:text-white"
              >
                <i className="ri-sun-line header-icon"></i>
              </a>
            )}
          </div>

          <div className="header-fullscreen  lg:block">
            <a
              href="#"
              aria-label="Toggle Fullscreen"
              onClick={toggleFullscreen}
              className="inline-flex flex-shrink-0 justify-center items-center gap-2 h-[2.375rem] w-[2.375rem] 
                   rounded-full font-medium bg-gray-100 hover:bg-gray-200 text-gray-500 align-middle 
                   focus:outline-none focus:ring-0 focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white 
                   transition-all text-xs dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 
                   dark:hover:text-white dark:focus:ring-white/10 dark:focus:ring-offset-white/10"
            >
              {isFullscreen ? (
                <i className="ri-fullscreen-exit-line header-icon"></i>
              ) : (
                <i className="ri-fullscreen-line header-icon"></i>
              )}
            </a>
          </div>

          <div className="relative">
            <div
              ref={menuRef}
              className="header-notification hs-dropdown ti-dropdown  sm:block"
            >
              {/* Notification Icon */}
              <a
                id="dropdown-notification"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setNotificationOpen(!isNotificationOpen);
                }}
                className="hs-dropdown-toggle ti-dropdown-toggle p-0 border-0 flex-shrink-0 h-[2.375rem] w-[2.375rem] rounded-full shadow-none bg-gray-100 hover:bg-gray-200 text-gray-500 align-middle 
                   focus:outline-none focus:ring-0 focus:ring-gray-400 focus:ring-offset-0 focus:ring-offset-white 
                   transition-all text-xs dark:bg-bgdark dark:hover:bg-black/20 dark:text-white/70 
                   dark:hover:text-white dark:focus:ring-white/10 dark:focus:ring-offset-white/10"
              >
                <i className="ri-notification-2-line header-icon"></i>
                {notifications.length > 0 && (
                  <span className="flex absolute h-5 w-5 top-0 end-0 -mt-1 -me-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-success/80 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-5 w-5 bg-success text-white justify-center items-center">
                      {notifications.length}
                    </span>
                  </span>
                )}
              </a>

              
              {isNotificationOpen && (
                <div className="absolute right-0 mt-3 w-[20rem] bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow-lg rounded-md">
                  <div className="ti-dropdown-header !bg-primary text-white flex justify-between items-center">
                    <p className="font-semibold">Notifications</p>
                    <button
                      onClick={clearNotifications}
                      className="badge bg-black/20 text-white rounded-sm"
                    >
                      Mark All Read
                    </button>
                  </div>

                  <div className="divide-y divide-gray-200 dark:divide-white/10">
                    <div className="py-2 first:pt-0 last:pb-0">
                      {notifications.length > 0 ? (
                        notifications.map((notification) => (
                          <div
                            key={notification.id}
                            className="flex items-center space-x-3 p-3"
                          >
                            <div className="avatar rounded-full ring-0">
                              <img
                                src={notification.image}
                                alt={notification.name}
                                className="rounded-sm w-10 h-10"
                              />
                            </div>
                            <div className="flex-1">
                              <h5 className="text-sm font-semibold">
                                {notification.name}
                              </h5>
                              <p className="text-xs text-gray-600 dark:text-gray-300 truncate max-w-[200px]">
                                {notification.message}
                              </p>
                              <p className="text-xs text-gray-400 dark:text-white/70">
                                {notification.time}
                              </p>
                            </div>
                            <button
                              aria-label="remove notification"
                              onClick={() =>
                                removeNotification(notification.id)
                              }
                              className="text-lg text-gray-500 hover:text-gray-800 dark:hover:text-white"
                            >
                              <i className="ri-close-circle-line"></i>
                            </button>
                          </div>
                        ))
                      ) : (
                        <p className="text-center text-gray-500 py-4">
                          No new notifications
                        </p>
                      )}
                    </div>

                    <div className="py-2 px-5">
                      <a className="w-full ti-btn ti-btn-primary p-2" href="#">
                        View All
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="relative" ref={dropdownRef}>
            {/* Avatar Button */}
            <button
              id="dropdown-profile"
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="gap-2 p-0 flex-shrink-0 h-8 w-8 rounded-full shadow-none"
            >
              <img
                className="inline-block rounded-full ring-2 ring-white dark:ring-white/10"
                src={user.avatar}
                alt="User"
              />
            </button>

            {/* Dropdown Menu */}
            <div
              className={`absolute right-0 mt-2 w-[20rem] bg-white dark:bg-gray-800 dark:text-white shadow-lg rounded-lg transition-all duration-200 ${
                isOpen
                  ? 'opacity-100 visible scale-100'
                  : 'opacity-0 invisible scale-95'
              }`}
            >
              <div className="!bg-primary flex p-3 rounded-t-lg">
                <div className="me-3">
                  <img
                    className="avatar shadow-none rounded-full ring-transparent"
                    src={user.avatar}
                    alt="profile-img"
                  />
                </div>
                <div>
                  <p className="text-white font-semibold">{user.name}</p>
                  <p className="text-white/50 text-sm">{user.role}</p>
                </div>
              </div>

              <div className="mt-2 divide-y divide-gray-200 dark:divide-gray-700">
                <a
                  // href="profile.html"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <i className="ti ti-user-circle text-lg"></i> Profile
                </a>
                <a
                  // href="mail-inbox.html"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <i className="ti ti-inbox text-lg"></i> Inbox
                </a>
                <a
                   
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <i className="ti ti-clipboard-check text-lg"></i> Task Manager
                </a>
                <a
                  // href="main/671f6b42359fa902c380f542"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <i className="ti ti-adjustments-horizontal text-lg"></i>{' '}
                  Settings
                </a>
                {/* <a
                  href="main/671f6b42359fa902c380f542"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  <i className="ti ti-wallet text-lg"></i> Bal: {user.balance}
                </a> */}
                <a
                  href="/"
                  className="block px-4 py-2 hover:bg-red-100 dark:hover:bg-red-600 text-red-600 dark:text-red-400"
                >
                  <i className="ti ti-logout text-lg"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default React.memo(Header);
