import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ViewLinkedDetails, useFormStore } from "@empire/components/organisms"
import { FormJSON } from "@empire/components"


interface FormRelationship {
  parentFormId: string;
  childFormId: string;
  childSectionId: string;
  parentFieldId: string;
  childFieldId: string;
}

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL
}

const ViewMode: React.FC = () => {
  const [formRelationships, setFormRelationships] = useState<FormRelationship[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [formConfig, setFormConfig] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [parentFormId, setParentFormId] = useState('')

  const { userConfig } = useFormStore();
  const { formId } = useParams();

  const authtoken = localStorage.getItem('token')

  const queries = formRelationships.map((item) => ({
    formId: item.childFormId,
    sectionId: item.childSectionId,
    fieldId: item.childFieldId,
    fieldValue: formId,
  }));

  useEffect(() => {
    const fetchForms = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/other/relation/${parentFormId}`, {
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
        setFormRelationships(response.data.data);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchForms();
  }, [parentFormId]);


  useEffect(() => {
    const getData = async () => {
      try {
        if (formId && typeof formId === 'string' && formId.length === 24) {
          const res = await axios.get(`${BACKEND.BACKEND_API}/other/flatvalue/${formId}`, {
            headers: {
              'Authorization': `Bearer ${authtoken}`,
            },
          });
          setParentFormId(res.data.formId);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);
  const transformData = (data:any) => {
    return formConfig.map((form: any) => {
        return {
            ...form,
            sections: form.sections.map((section: any) => {
                let transformedFields = [] as any;
                
                section.fields.forEach((field: any) => {
                    if (field.type === "Address" || field.type === "Customgroup" || field.type === "partialView" && field.fields) {
                        field.fields.forEach((subField: any) => {
                            transformedFields.push({
                                ...subField,
                                sectionId: field.sectionId,
                                value: field.value[subField.id] || ""
                            });
                        });
                    } else {
                        transformedFields.push(field);
                    }
                });
                
                return {
                    ...section,
                    fields: transformedFields
                };
            })
        };
    });
};

const inputData = [ ]as any;
const transformedData = transformData(inputData);

  
  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        const response = await axios.post(`${BACKEND.BACKEND_API}/other/getflatdatatimelineformsvaluesbyid`, { "formId": formId }, {
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
        setFormConfig(response.data);
      } catch (error) {
        console.error('Error fetching form configuration:', error);
        setError('Failed to load form configuration.');
      } finally {
        setLoading(false);
      }
    };

    fetchFormConfig();
  }, [formId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${BACKEND.BACKEND_API}/other/getField`, { queries }, {
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
         
        setFormConfig((prevFormConfig: FormJSON[]) => [...prevFormConfig, ...response.data]);
      } catch (error: any) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [formRelationships]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className=''>
      <div className="flex">
        <div className="flex flex-col flex-grow">
          <main className="py-16">
            {formConfig ? <ViewLinkedDetails forms={transformedData} /> : <div>No form configuration available</div>}
          </main>
        </div>
      </div>
    </div>
  );
};

export default ViewMode;
