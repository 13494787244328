import React, { useState } from 'react';
import { Field } from '../types';

interface TermsConditionProps {
     field: Field;
      value?: string;
      sectionId ?: string;
      error?: boolean;

      onChange?: any
}

export const TermsCondition: React.FC<TermsConditionProps> = ({ field,
  value,
  error, onChange }) => {

    const [isChecked, setIsChecked] = useState(field.value === "true"); 

   
    

    return (
        <div className="p-4 border rounded bg-gray-100">
            <h4 className="text-lg font-semibold text-gray-800">{field.fieldValue || "No Terms Available"}</h4>
            <br />
            <label className="font-bold mb-2">
            <input
                type="checkbox"
                id="termsCheckbox"
                checked={isChecked} // Set the current checked state
                onChange={(e) => {
                  setIsChecked(e.target.checked);  
                  field.value = e.target.checked.toString() || "";
                
                }} 
                className="mr-2"
              />
            {field.label || <span className="ml-2">I accept the terms and conditions</span>}
          </label>

            <br /><br />

            {/* Disable button until checkbox is checked */}
            {/* <button
                onClick={handleProceed}
                disabled={!isChecked}
                className={`px-4 py-2 rounded ${isChecked ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
            >
                Proceed
            </button> */}
        </div>
    );
};
