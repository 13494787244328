import React, { useState } from "react";

type WorkflowStep = {
  stepId: string;
  formId: string;
  children: string[];
};

type WorkflowData = {
  workflowId: string;
  name: string;
  steps: WorkflowStep[];
};

// Initial Workflow Data
const initialWorkflow: WorkflowData = {
  workflowId: "workflow_123",
  name: "Machine Inspection Workflow",
  steps: [
    { stepId: "step_1", formId: "form_101", children: ["step_2", "step_3"] },
    { stepId: "step_2", formId: "form_102", children: ["step_4"] },
    { stepId: "step_3", formId: "form_103", children: [] },
    { stepId: "step_4", formId: "form_104", children: [] },
  ],
};

const WorkflowBuilder: React.FC = () => {
  const [workflow, setWorkflow] = useState<WorkflowData>(initialWorkflow);

  // Function to add a new step
  const handleAddNode = (parentId: string) => {
    const newStepId = `step_${workflow.steps.length + 1}`;
    const newFormId = `form_${100 + workflow.steps.length + 1}`;

    const newStep: WorkflowStep = { stepId: newStepId, formId: newFormId, children: [] };

    const updatedSteps = workflow.steps.map((step) =>
      step.stepId === parentId ? { ...step, children: [...step.children, newStepId] } : step
    );

    setWorkflow({
      ...workflow,
      steps: [...updatedSteps, newStep],
    });

    console.log("Updated Workflow JSON:", JSON.stringify({ ...workflow, steps: [...updatedSteps, newStep] }, null, 2));
  };

  // Function to delete a step and its children
  const handleDeleteNode = (stepId: string) => {
    const getAllChildren = (id: string): string[] => {
      const step = workflow.steps.find((s) => s.stepId === id);
      return step ? step.children.flatMap(getAllChildren).concat(id) : [];
    };

    const stepsToDelete = getAllChildren(stepId);
    const updatedSteps = workflow.steps.filter((step) => !stepsToDelete.includes(step.stepId));

    setWorkflow({
      ...workflow,
      steps: updatedSteps.map((step) => ({
        ...step,
        children: step.children.filter((childId) => !stepsToDelete.includes(childId)),
      })),
    });

    console.log("Updated Workflow JSON:", JSON.stringify({ ...workflow, steps: updatedSteps }, null, 2));
  };

  // Recursive function to render steps
  const renderSteps = (stepId: string) => {
    const step = workflow.steps.find((s) => s.stepId === stepId);
    if (!step) return null;

    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-300 relative">
        <p className="font-semibold text-blue-600">📄 Form ID: {step.formId}</p>
        
        {/* Add & Delete Buttons */}
        <div className="flex gap-2 mt-2">
          <button
            onClick={() => handleAddNode(step.stepId)}
            className="bg-green-500 text-white px-3 py-1 text-sm rounded hover:bg-green-600"
          >
            ➕ Add Child
          </button>
          {stepId !== "step_1" && (
            <button
              onClick={() => handleDeleteNode(step.stepId)}
              className="bg-red-500 text-white px-3 py-1 text-sm rounded hover:bg-red-600"
            >
              ❌ Delete
            </button>
          )}
        </div>

        {/* Render Child Steps */}
        {step.children.length > 0 && (
          <div className="mt-3 space-y-3">
            {step.children.map((childId) => (
              <div key={childId} className="ml-6 border-l-4 border-gray-300 pl-4">
                {renderSteps(childId)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-8 max-w-3xl mx-auto bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-gray-700">📋 Workflow Builder</h2>

      {/* Render Workflow */}
      <div className="space-y-6">{renderSteps("step_1")}</div>
    </div>
  );
};

export default WorkflowBuilder;
