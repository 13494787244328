import React, { useEffect, useState } from 'react';
import { DateInputProps } from '../types';

export const MonthYearPicker = ({ field, value, error, onChange }: DateInputProps) => {
  if (field.defaultDate == 'yes') field.date = new Date().toISOString().split('T')[0];
  else field.date = field.dateFormat;

  return (
    <div className="p-2  rounded" >
    <input
      type="month"
      name="date"
      id={field.id}
      required={field.required}
      value={field.defaultDate ? value || field.date : value}
      onChange={onChange}
      className={`w-full p-2 border rounded ${error ? "border-red-500" : ""}`}
    />
    </div>
  );
};