import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL
}

const AutoLogin = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get the token from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    if (token) {
      // Send token to the backend for validation
      axios.post(`${BACKEND.BACKEND_API}/auto/validate-token`, { token }).then((response) => {
        if (response.data.success) {
          setUser(response.data.user);
          localStorage.setItem('token', response.data.data.data.token);
          localStorage.setItem('home', 'CRMHOME');
          localStorage.setItem('pluginsection', 'Installed'),
            navigate('/main');
        } else {
          alert("Invalid token");
          navigate("/login");
        }
      }).catch(() => {
        alert("Error validating token");
      });
    } else {
      alert("No token provided");
      navigate("/login");
    }
  }, [navigate]);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Welcome, {user}</h1>
    </div>
  );
};
export default AutoLogin;