import React, { useState } from 'react';

export const PageNotFound: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="relative h-screen bg-center bg-cover" style={{ backgroundImage: 'url(/path-to-your-bg-image.jpg)' }}>
      <div className="flex items-center justify-center h-full bg-gray-800 bg-opacity-50">
        <div className="px-6 py-12 text-center text-white bg-gray-900 bg-opacity-75 rounded-lg">
          <h1 className="mb-4 text-5xl font-bold">Oops!</h1>
          <p className="mb-8 text-lg">The page you're looking for doesn't exist.</p>
          <button
            onClick={openModal}
            className="px-4 py-2 font-bold text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600"
          >
            Open Oops Popup
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="p-6 text-center bg-white rounded-lg shadow-lg">
            <h2 className="mb-4 text-2xl font-bold">Oops!</h2>
            <p className="mb-4 text-lg">The page you are looking for cannot be found.</p>
            <button
              onClick={closeModal}
              className="px-4 py-2 font-bold text-white transition duration-300 bg-red-500 rounded hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageNotFound;
