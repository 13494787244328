import React, { useEffect, useState } from 'react';
import { DialogBoxProps } from '../types';

export const DialogBox: React.FC<DialogBoxProps> = ({
  title,
  content,
  onClose,
  onConfirm,
  cancelText,
  restoreText,
  confirmText,
  initialSelectedIndex,
}) => {

  const [selectedIndex, setSelectedIndex] = useState<number | null>(initialSelectedIndex ?? null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [lastConfirmedIndex, setLastConfirmedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (initialSelectedIndex !== null) {
      setSelectedIndex(initialSelectedIndex);
      setSelectedItem(content.dialogBoxList?.[initialSelectedIndex]?.label || null);
    }
  }, [initialSelectedIndex, content.dialogBoxList]);

  const handleItemClick = (index: number) => {
    const selectedLabel = content.dialogBoxList?.[index]?.label || null;
    if (index === selectedIndex) {
      setSelectedIndex(null);
      setSelectedItem(null);
    } else {
      setSelectedIndex(index);
      setSelectedItem(selectedLabel);
    }
  };

  // Handle confirm action
  const handleConfirmClick = () => {
    if (onConfirm && selectedItem) {
      onConfirm(selectedItem);
      setLastConfirmedIndex(selectedIndex); // Save the confirmed selection
    }
  };

  // Handle clear action (reset selection)
  const handleClearClick = () => {
    setSelectedIndex(null);
    setSelectedItem(null);
    // console.log('Selection cleared');
  };

  // Handle restore action (restore the last confirmed selection)
  const handleRestoreClick = () => {
    if (lastConfirmedIndex !== null) {
      setSelectedIndex(lastConfirmedIndex); // Restore to the last confirmed index
      setSelectedItem(content.dialogBoxList?.[lastConfirmedIndex]?.label || null);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-lg p-4 relative">
        {/* Close Icon */}
        <button
          className="absolute top-2 right-2 text-xl text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="border-b pb-2">
          <h2 className="text-lg font-semibold">{title}</h2>
        </div>
        <div className="py-4">
          <div className="grid grid-cols-2 gap-4">
            {content.dialogBoxList?.map((item, index) => (
              <div
                key={index}
                className={`text-sm text-gray-700 px-4 py-2 rounded-full shadow cursor-pointer ${
                  selectedIndex === index
                    ? 'bg-blue-500 text-white rounded-full'
                    : 'bg-gray-100 hover:bg-gray-200 rounded-full'
                }`}
                onClick={() => handleItemClick(index)}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-end gap-3 border-t pt-3">
          <button
            className="px-4 py-2 text-sm text-blue-600 hover:text-blue-700"
            onClick={handleClearClick}
          >
            {cancelText}
          </button>
          <button
            className="px-4 py-2 text-sm text-blue-600 hover:text-blue-700"
            onClick={handleRestoreClick}
          >
            {restoreText}
          </button>
          <button
            className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            onClick={handleConfirmClick}
            disabled={!selectedItem}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};
