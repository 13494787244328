import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import { useFormStore } from '@empire/components/organisms';
import { Plugin, OrgStatus } from '@empire/components';

import 'react-toastify/dist/ReactToastify.css';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  PLUGIN_BACKEND_API: process.env.PLUGIN_BACKEND_API,
};

const Private = () => {
  const [pluginlist, setPluginList] = useState<Plugin[]>([]);
  const [installedpluginlist, setInstalledPluginList] = useState<Plugin[]>([]);

  const { userConfig } = useFormStore();
  const authtoken = userConfig?.token;
  const userId: string = userConfig?.userId;
  const orgStatus: OrgStatus[] = userConfig?.orgStatus || [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${BACKEND.PLUGIN_BACKEND_API}/plugin/getprivetplugin`,
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        setPluginList(result.data);

        const installedResponse = await axios.post(
          `${BACKEND.PLUGIN_BACKEND_API}/plugin/getinstallplugin`,
          {
            orgId: orgStatus[0].orgId,
          },
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        setInstalledPluginList(installedResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const installPlugin = async (id: string, name: string, status: boolean) => {
    const plugin = {
      pluginId: id,
      pluginName: name,
      ispluginActive: status,
      orgId: orgStatus,
      userId: userId,
    };

    try {
      const response = await axios.post(
        `${BACKEND.PLUGIN_BACKEND_API}/installplugin`,
        plugin
      );

      if (response.status === 201) {
        toast.success(`The plugin "${name}" was successfully installed.`, {
          position: 'bottom-right',
          autoClose: 2000,
        });
        // Refresh the plugin list after successful installation
        const installedResponse = await axios.post(
          `${BACKEND.PLUGIN_BACKEND_API}/getInstalledPlugin`,
          {
            orgId: orgStatus[0]?.orgId,
          }
        );
        setInstalledPluginList(installedResponse.data);
      }
    } catch (error) {
      toast.error(`Failed to install the plugin "${name}". Please try again.`, {
        position: 'bottom-right',
        autoClose: 2000,
      });
    }
  };

  const isPluginInstalled = (pluginId: string): boolean => {
    return installedpluginlist.some(
      (installedPlugin) => installedPlugin._id === pluginId
    );
  };

  return (
    <div className="box">
      <div className="box-header">
        {' '}
        <h5 className="box-title">Private Plugins</h5>{' '}
      </div>
      {/* <hr className="mb-6" /> */}
      <div className="box-body">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {pluginlist.map((plugin) => {
            const installed = isPluginInstalled(plugin._id);
            return (
              <motion.div
                key={plugin._id}
                className="overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-bgdark"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                whileHover={{
                  scale: 1.05,
                  boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
                }}
              >
                <img
                  src={plugin.files.screenshot1}
                  alt={`${plugin.name} Screenshot`}
                  className="object-cover w-full h-40"
                />
                <div className="p-4">
                  <h3 className="text-xl font-bold">{plugin.name}</h3>
                  <p className="mt-2 text-gray-600">{plugin.description}</p>
                  <p className="mt-2 text-sm text-gray-500">
                    Created on: {plugin.creationDate} at {plugin.creationTime}
                  </p>
                  <motion.button
                    className={`mt-4 w-full py-2 rounded ${
                      installed
                        ? 'bg-gray-400 text-white cursor-not-allowed'
                        : 'bg-blue-500 text-white hover:bg-blue-600'
                    }`}
                    whileTap={{ scale: 0.95 }}
                    onClick={() =>
                      !installed &&
                      installPlugin(plugin._id, plugin.name, plugin.isActive)
                    }
                    disabled={installed}
                  >
                    {installed ? 'Installed' : 'Install Plugin'}
                  </motion.button>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Private;
