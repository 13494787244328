import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import { BarChartProps, datagridProps, Form } from '../types';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

export const DataGrid = ({ field, value, error, onChange }: datagridProps) => {
  const authtoken = localStorage.getItem('token');
  const [columnDefs] = useState<ColDef[]>([
    { headerName: 'ID', field: 'id', sortable: true, filter: true },
    { headerName: 'Name', field: 'name', sortable: true, filter: true },
    { headerName: 'Email', field: 'email', sortable: true, filter: true },
    { headerName: 'Age', field: 'age', sortable: true, filter: true },
    { headerName: 'City', field: 'city', sortable: true, filter: true },
  ]);

  const getFormData = async () => {
    try {
      const response = await axios.post(
        `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
        {
          formId: field.formid,
          isFilterd: field.isFilterd || false,
          filters: field.filters || {},
        },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      const data: Form[] = response.data;
      console.log(data, 'test test');
    } catch (err) {
      console.error('Error fetching form data:', err);
    }
  };

   useEffect(() => {
      getFormData();
    }, []);

  const [rowData] = useState([
    {
      id: 1,
      name: 'Alice',
      email: 'alice@example.com',
      age: 25,
      city: 'Delhi',
    },
    { id: 2, name: 'Bob', email: 'bob@example.com', age: 30, city: 'Mumbai' },
    {
      id: 3,
      name: 'Charlie',
      email: 'charlie@example.com',
      age: 35,
      city: 'Bangalore',
    },
    {
      id: 4,
      name: 'David',
      email: 'david@example.com',
      age: 28,
      city: 'Hyderabad',
    },
    { id: 5, name: 'Eve', email: 'eve@example.com', age: 22, city: 'Chennai' },
  ]);

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
      <AgGridReact rowData={rowData} columnDefs={columnDefs} />
    </div>
  );
};
