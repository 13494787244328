import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import React from 'react';
import { Rnd } from 'react-rnd';
import { v4 as uuidv4 } from 'uuid';
import { Form, useLocation, useParams } from 'react-router-dom';
import { InputField } from './InputFields';
import RangeField from '../molecules/RangeField';
import CheckBox from '../molecules/Checkbox';
import ProfileImage from '../molecules/ProfileImage';
import ToggleSwitch from '../molecules/Toggleswitch';
import { DialogBox } from '../molecules/DialogBox';
import { LiveClock, Website } from '../molecules';
import DialogBoxForm from '../molecules/DialogBoxForm';
import { Field, FormJSON, Section } from '../types';
import '../styles.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Decode } from './Decode';
import { useFormStore } from '../organisms/UserStore';
import DynamicCalculationField from '../molecules/CalculationField';
import renderToaster from '../molecules/TimelineToaster';
import { SignaturePad } from '../molecules/SignaturePad';
import { ImageUploader, TermsCondition } from '../molecules';
import { TextEditor } from '../molecules/TextEditor';
import { FiPlus, FiX } from 'react-icons/fi';

export interface UpdatedForm extends FormJSON {
  userId?: string;
}

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  PLUGIN_BACKEND_API: process.env.PLUGIN_BACKEND_API,
};

const getValueFromFormData = (formDataa: any) => {
  if (!formDataa?.sections || formDataa.sections.length === 0) return null;
  // Extract first section
  const section = formDataa.sections[0];
  if (!section?.fields || section.fields.length === 0) return null;
  // Extract first field
  const field = section.fields[0];
  // Return the value if exists
  return field.value || null;
};

const validateForm = (formValues: any, formDataa: FormJSON) => {
  const errors: Record<string, string> = {};
  formDataa.sections.forEach((section) => {
    section.fields.forEach((field) => {
      const extractedValue = getValueFromFormData(formDataa);
      if (field.required === true && !formValues[section.id]?.[field.id]) {
        errors[`${section.id}-${field.id}`] = `${field.label} is required`;
      }
      if (field.type === 'termscondition' && field.value !== 'true') {
        errors[`${section.id}-${field.id}`] = `${field.label} is required`;
      }
      if (field.type === 'email') {
      } else if (field.type === 'website') {
        const urlPattern =
          /^(https?:\/\/|www\.)[\w-]+(\.[\w-]+)+([\w-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#])?$/;
        if (!urlPattern.test(field.value || '')) {
          errors[
            `${section.id}-${field.id}`
          ] = `${field.label} "Invalid URL! It must start with 'http://', 'https://', or 'www.'"`;
        }
      } else if (field.type === 'email') {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(field.value || '')) {
          errors[`${section.id}-${field.id}`] = `${field.label} is required`;
        }
      }
    });
  });
  return errors;
};


export const FormRenderer = React.memo(({ formData}: { formData: FormJSON}) => {

  const [dependentFormsData, setDependentFormsData] = useState<
    Record<string, any>
  >({});
  const [formDataa, setFormData] = useState(formData);
  
  
  useEffect(()=>{
setFormData(formData) 
  },[formData])


  const [selectedFirst, setSelectedFirst] = useState<{ [key: string]: any }>(
    {}
  );
  const { userConfig, setUserConfig } = useFormStore();
  const userid =
    userConfig?.userId != undefined
      ? userConfig?.userId
      : localStorage.getItem('userid');
  const [dummyvalueforselect, setdummyvalueforselect] = useState('');
  const [signaturesave, setSignaturesave] = useState(false);
  const [website, setWebsite] = useState(false);
  const [drawingsave, setDrawingsave] = useState(false);
  const { formId } = useParams();
  const fid = formDataa.formId;
  const [initialFormValues, setInitialFormValues] = useState<any>(() => {
    const initialValues: any = {};
    formDataa.sections.forEach((section) => {
      section.fields.forEach((field) => {
        const label = field.label;
        const val = field.value ? field.value : '';
        initialValues[field.id] = { label, val };
      });
    });
    return initialValues;
  });




  formDataa.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (
        field.type === 'date' &&
        field.defaultDate === 'yes' &&
        !field.value
      ) {
        field.value = new Date().toISOString().split('T')[0];
      } else if (
        field.type === 'select' &&
        field.DefaultUserEntity &&
        !field.value
      ) {
        field.value = userid;
      }
    });
  });
  const location = useLocation();
  const editmode =
    location.state && location.state.mode ? location.state.mode : 'view';
  const authtoken = localStorage.getItem('token');
  const [canShowDraftButton, setCanShowDraftButton] = useState(true);
  const [canUpdateFormValues, setUpdateFormValues] = useState(false);
  const { currentformId } = useParams();
  const [cnt, setCnt] = useState(1);

  const handleClick = (fieldd: Field, sectionId: any, flag: string) => {
    
    fieldd.count = fieldd.count===undefined ? 1 :  fieldd.count;
    const section = formDataa.sections.find(sec => sec.id === sectionId);
  
  const fild =  section?.fields.find(field => field.id === fieldd.id) || null;
    flag === "Add"
      ? setFormData((prevFormData: any) => {
          let newFieldHeight = 0;
  
          const updatedSections = prevFormData.sections.map((section: any, index: number, sections: any[]) => {
            if (section.id === sectionId) {
              const lowestY = section.fields.reduce((maxY: any, field: any) => {
                return Math.max(maxY, field.y + field.height);
              }, 0);
  
              const newX = 0;
              const newY = fieldd.type === "partialView" ? lowestY + 10 : lowestY + 15;
  
              const newField = {
                ...fieldd,
                id: uuidv4(),
                x: newX,
                y: newY,
                label: fieldd.label + `_${fieldd.count}`,
                isrepeatedField: "delete",
                fields: fieldd.fields
                  ? fieldd.fields.map((subField: any) => ({
                      ...subField,
                      id: uuidv4(),
                      sectionId: sectionId,
                      label: subField.label + `_${fieldd.count}`,
                    }))
                  : [],
                
              };
  
              setCnt(cnt + 1);
              Object.assign(fieldd,{
                count: fieldd.count ? fieldd.count + 1 : 1,
              })

  
              newFieldHeight = newField.height + 10; // Capture height of the new field
  
              return {
                ...section,
                fields: [...section.fields, newField], // Keep x the same, modify y
                height: section.height + newFieldHeight,
              };
            }
  
            // Shift the sections that come after the affected section
            const previousSectionIndex = sections.findIndex((sec) => sec.id === sectionId);
            if (index > previousSectionIndex) {
              return {
                ...section,
                x: section.x, // Keep x the same, modify y
                y: section.y + newFieldHeight, // Shift downward
              };
            }
  
            return section;
          });
  
          return {
            ...prevFormData,
            sections: updatedSections,
          };
        })
      : setFormData((prevFormData: any) => {
          return {
            ...prevFormData,
            sections: prevFormData.sections.map((section: any) => {
              if (section.id === sectionId) {
                const updatedFields = section.fields.filter((f: any) => f.id !== fieldd.id);
  
                return {
                  ...section,
                  fields: [...updatedFields],
                };
              }
              return section;
            }),
          };
        });
  };
  
  

  // to check draft record is there or not
  useEffect(() => {
    const fetchdetails = async () => {
      const response = await axios.get(
        `${BACKEND.BACKEND_API}/value/valueCheckByStatus/${formId}/draft/formresponse/${userid}`,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      if (response.data._id !== undefined) {
        setCanShowDraftButton(false);
        setUpdateFormValues(false);
      } else {
        setUpdateFormValues(false);
      }
      if (formDataa.moduleId === '671f6b42359fa902c380f542') {
        setCanShowDraftButton(false);
      }
    };
    fetchdetails();
  }, [formId]);

  useEffect(() => {
    const fetchdetail = async () => {
      if (!userConfig) {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        });
        const data = await response.data;
        setUserConfig(data);
      }
    };
    fetchdetail();
  }, []);

  const [formValues, setFormValues] = useState<any>(() => {
    const initialValues: any = {};

    formDataa.sections.forEach((section) => {
      initialValues[section.id] = {};

      section.fields.forEach((field) => {
        if (field.type === 'select') {
          initialValues[section.id][field.id] = field.value || '';
        } else if (field.value) {
          initialValues[section.id][field.id] = field.value;
        }
      });
    });
    return initialValues;
  });
  const [formValuesUpdated, setFormValuesUpdated] = useState<{
    [key: string]: any;
  }>(formValues);
  const [errors, setErrors] = useState<any>({});
  const [openSection, setOpenSection] = useState<string | null>(
    formDataa.sections[0]?.id || null
  );
  const [selectedFiles, setSelectedFiles] = useState<{
    [key: string]: File[] | null;
  }>({});
  const [fieldChanges, setFieldChanges] = useState<any>({});

//  Dont Remove this code
//   useEffect(() => {
//     if (selectedFirst !== undefined) {
//       const keys =
//         selectedFirst['inputData'] !== undefined
//           ? Object.keys(selectedFirst['inputData'])
//           : [];
//       if (keys.length > 0) {
//         keys.forEach((key) => {
//           const newValues = selectedFirst?.inputData?.[key] || {};
//           const transformedArray = Object.keys(newValues).map((key) => ({
//             id: key,
//             value: newValues[key],
//           }));
//           transformedArray.forEach((value: any, index: any) => {
//             setFormValues((prevValues: any) => ({
//               ...prevValues,
//               [key]: {
//                 ...(prevValues[key] || {}),
//                 [value.id]: value.value,
//               },
//             }));
//           });
//         });
//       }
//     }
//     console.log(selectedFirst)
//   }, [selectedFirst]);

  useEffect(() => {
    if (canUpdateFormValues) {
      setFormValues((prevState: any) => ({
        ...prevState,
        ...Object.keys(formValuesUpdated).reduce(
          (acc, key) => ({
            ...acc,
            [key]: { ...prevState[key], ...formValuesUpdated[key] },
          }),
          {}
        ),
      }));
    }
  }, [formValuesUpdated]);

  const deepEqual = (obj1: any, obj2: any): boolean => {
    if (obj1 === obj2) return true;
    if (
      typeof obj1 !== 'object' ||
      typeof obj2 !== 'object' ||
      obj1 === null ||
      obj2 === null
    )
      return false;

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    return keys1.every((key) => deepEqual(obj1[key], obj2[key]));
  };

  const handleCameraFieldChange = (
    sectionId: string,
    fieldId: string,
    value: any,
    fieldType: string
  ) => {
    const file = value;
    const realfileExtension = file.name.split('.').pop();
    const uniqueFileName = `${uuidv4()}.${realfileExtension}`;
    const fileUrl = `${BACKEND.BACKEND_API}/uploads/${uniqueFileName}`;
    const updatedFile = new File([file], uniqueFileName, {
      type: file.type,
      lastModified: file.lastModified,
    });

    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [`${sectionId}-${fieldId}`]: [updatedFile],
    }));

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [sectionId]: {
        ...(prevValues[sectionId] || {}),
        [fieldId]: fileUrl,
        [`${fieldId}fileName`]: file.name,
      },
    }));
    setFieldChanges((prevChanges: any) => ({
      ...prevChanges,
      [`${fieldId}`]: {
        current: fileUrl,
      },
    }));
  };

  const handleInputChange = (
    sectionId: string,
    fieldId: string,
    value: any,
    fieldType: string,
    checkedValue?: string,
    uncheckedValue?: string
  ) => {
    if (
      fieldType === 'file' ||
      fieldType === 'signaturePad' ||
      fieldType === 'drawingPad'
    ) {
      if (value && value.length > 0) {
        for (let i = 1; i <= value.length; i++) {
          const file = value[i - 1];
          const realfileExtension = file.name.split('.').pop();
          const uniqueFileName = `${uuidv4()}.${realfileExtension}`;
          const fileUrl = `${BACKEND.BACKEND_API}/uploads/${uniqueFileName}`;
          const updatedFile = new File([file], uniqueFileName, {
            type: file.type,
            lastModified: file.lastModified,
          });
          const key = `${sectionId}-${fieldId}`;
          setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [key]: [
              ...(Array.isArray(prevFiles[key]) ? prevFiles[key] : []),
              updatedFile,
            ],
          }));

          setFormValues((prevValues: any) => ({
            ...prevValues,
            [sectionId]: {
              ...(prevValues[sectionId] || {}),
              [fieldId]: fileUrl,
              [`${fieldId}fileName`]: file.name,
            },
          }));
          setFieldChanges((prevChanges: any) => ({
            ...prevChanges,
            [`${fieldId}`]: {
              current: fileUrl,
            },
          }));
        }
      }
    } else if (fieldType === 'checkbox') {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        [sectionId]: {
          ...(prevValues[sectionId] || {}),
          [fieldId]: value ? checkedValue : uncheckedValue,
        },
      }));
    } else if (fieldType === 'phone'){

      setFormValues((prevValues: any) => ({
        ...prevValues,
        [sectionId]: {
          ...(prevValues[sectionId] || {}),
          [fieldId]: parseInt(value),
        },
      }));

    }else {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        [sectionId]: {
          ...(prevValues[sectionId] || {}),
          [fieldId]: value,
        },
      }));
      setFieldChanges((prevChanges: any) => ({
        ...prevChanges,
        [`${fieldId}`]: fieldType === 'select' ? 'selectType' : value,
      }));
    }
  };

  function transformJson(firstJson: any, secondJson: any, status: any) {
    const result: any = [];
    for (const id in secondJson) {
      status === 'Added'
        ? result.push(`${firstJson[id]['label']} Added to ${secondJson[id]}`)
        : firstJson[id]['val'] === 'selectType' ||
          secondJson[id] === 'selectType'
        ? result.push(`${firstJson[id]['label']} Updated`)
        : result.push(
            `${firstJson[id]['label']} ${status} From ${firstJson[id]['val']} to ${secondJson[id]}`
          );
    }
    return result;
  }

  const handleFileUpload = async () => {
    try {
      for (const key in selectedFiles) {
        const files = selectedFiles[key];
        if (files !== null) {
          const formDataa = new FormData();
          for (let i = 0; i < files.length; i++) {
            formDataa.append('image', files[i]);
          }
          const response = await axios.post(
            `${BACKEND.BACKEND_API}/upload`,
            formDataa,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          console.log('File uploaded successfully:');
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };
  const handleParentFormData = (
    e: React.ChangeEvent<HTMLInputElement>,
    sectionId: string,
    fieldId: string,
    value: any,
    fieldType: string,
    formId?: string,
    field?: Field
  ) => {
    let selectedvalue = "";
    if(field?.type === 'select'){
      if (!event) return;
      const target = event.target as HTMLSelectElement;
      const selectedOption = target.options[target.selectedIndex];
      selectedvalue = selectedOption.text;
    } else {
      selectedvalue = e.target.value;
    }

//     setDependentFormsData((prevValues: any) => ({
//       ...prevValues,
//       [sectionId]: {
//         ...(prevValues[sectionId] ?? {}),
// //         [field?.parentFormId ?? 'fields']: {
// //           ...(prevValues[sectionId]?.[field?.parentFormId ?? 'fields'] ?? {}),
// //           [fieldId]: selectedvalue,
// //         },
//         [fieldId] : {
//           id: e.target.value,
//           value: selectedvalue
//         }
//       },
//     }));
    setSelectedFirst((prev) => ({
      ...prev,
      [sectionId]: {
          ...(prev[sectionId] ?? {}),
          [fieldId] : {
           id: e.target.value,
           value: selectedvalue
         }
      },
    }));

//     const addDynamicKeyValuePair = (formId: any, selectedvalue: string) => {
//       setDependentFormsData({
//         ...dependentFormsData,
//         [formId]: selectedvalue,
//       });
//     };
//     addDynamicKeyValuePair(formId, selectedvalue);
  };

  const renderField = (field: Field, sectionId: string) => {
    const dependentFormData = {
      parentFormId: field.parentFormId ?? '',
      isDependent: field.markAsDependent ?? false,

    };
    const isAddressField = field.type === 'Address';
    const isFullNameField = field.type === 'fullname';
    const isPartialView = field.type === 'partialView';
    const isDynamicField = field.type === 'Customgroup';
    const uniqueid=field.type==='unique_id'
    const isSwitchField = field.type === 'switch';
    const isdynamicFieldRangs = field.type === 'range';
    const isDynamicCheckBox = field.type === 'checkbox';
    const isLiveClock = field.type === 'liveWatch';
    const isCameraField = field.type === 'camerafile';
    const istermandcondition = field.type === 'termscondition';
    const isReadOnly = false;
    const isDymanicProfile = field.type === 'profile';
    const isCard = field.type === 'card';
    const isDate = field.type === 'date';
    const isCalculation = field.type === 'formula';
    const isDynamicDialogBox = field.type === 'dialog';
    const isSignaturePad = field.type === 'signaturePad';
    const isDrawingPad = field.type === 'drawingPad';
    const isWebsite = field.type === 'website';
    const textEditor = field.type === 'textEditor';
    const value =
      isAddressField || isDynamicField || isFullNameField
        ? formValues?.[sectionId]?.[field.id] || {}
        : isSwitchField
        ? formValues[sectionId]?.[field.id] || field.uncheckedValue
        : formValues?.[sectionId]?.[field.id] || '';

    const mode =
      editmode === 'editfields' || localStorage.getItem('mode') === 'edit';
    const error = !!errors[`${sectionId}-${field.id}`];
    const selectFieldValue = formValues?.[sectionId]?.[field.id] || '';
    if (isSwitchField) {
      return (
        <ToggleSwitch
          field={{
            ...field,
            checkedValue: field.checkedValue || 'Checked',
            uncheckedValue: field.uncheckedValue || 'Unchecked',
          }}
          sectionId={sectionId}
          value={value}
          onChange={(sectionId, fieldId, newValue) =>
            handleInputChange(sectionId, fieldId, newValue, 'switch')
          }
        />
      );
    }
    if (isdynamicFieldRangs) {
      return (
        <RangeField
          field={field}
          sectionId={sectionId}
          value={formValues[sectionId]?.[field.id] || field.default || 0}
          error={errors[`${sectionId}-${field.id}`] || null}
          handleInputChange={handleInputChange}
          deviedation={field.deviedation || '1/1'}
        />
      );
    }
    if (textEditor) {
      return (
        <TextEditor
          field={field}
          isediting={field.isReadOnly}
          value={field.value}
        />
      );
    }

    if (isCameraField) {
      return (
        <ImageUploader
          field={field}
          error={error}
          onChange={(e: any) => {
            handleCameraFieldChange(sectionId, field.id, e, field.type);
          }}
        />
      );
    }
    if (isLiveClock) {
      return <LiveClock />;
    }

    if (istermandcondition) {
      return (
        <TermsCondition
          field={field}
          value={formValues[sectionId]?.[field.id] || field.default || 0}
          error={error}
          onChange={handleInputChange}
        />
      );
    }

    if (isCalculation) {
      return (
        <div>
          {formDataa.sections.map((section) => (
            <DynamicCalculationField
              key={section.id}
              fields={section.fields}
              initialFormula={(field as any).formula || ''}
              formValues={formValues}
              field={field}
            />
          ))}
        </div>
      );
    }
    if (isDynamicCheckBox) {
      return (
        <CheckBox
          field={field}
          sectionId={sectionId}
          value={formValues[sectionId]?.[field.id] || field.default || 0}
          error={error}
          handleInputChange={handleInputChange}
        />
      );
    }

    if (isDymanicProfile) {
      return (
        <ProfileImage
          field={{
            id: field.id,
            label: field.label,
            imageUrl: field.imageProfileUrl,
            name: field.name,
            mobile: field.mobile,
            email: field.email,
          }}
        />
      );
    }
    if(uniqueid){
      return(
        <div className='p-2 border bg-white'>{field.value}</div>
      )
    }
    if (isDynamicDialogBox) {
      return <DialogBoxForm isDynamicDialogBox={true} field={field} />;
    }
   

    return isAddressField || isDynamicField || isFullNameField || isPartialView ? (
      <div className="p-2 bg-gray-200 border rounded">
  {!isPartialView && <h2 className="mb-6 font-bold">{field.label}</h2>}

  {field.isrepeatedField === 'yes' && (
    <div className="flex gap-2 justify-end">
      <div
        className="cursor-pointer text-blue-500 hover:text-blue-700"
        onClick={() => handleClick(field, sectionId, "Add")}
      >
        <FiPlus size={28} />
      </div>
      <div
        className="cursor-pointer text-blue-500 hover:text-blue-700"
        onClick={() => handleClick(field, sectionId, "Delete")}
      >
        <FiX size={28} />
      </div>
    </div>
  )}

  {field.isrepeatedField === 'delete' &&
    editmode !== 'editfields' &&
    localStorage.getItem('mode') !== 'edit' && (
      <div className="flex gap-2 justify-end">
        <div
          className="cursor-pointer text-blue-500 hover:text-blue-700"
          onClick={() => handleClick(field, sectionId, "Delete")}
        >
          <FiX size={28} />
        </div>
      </div>
    )}

  {/* FLEX CONTAINER FOR 2-FIELDS PER ROW */}
  <div className="flex flex-wrap gap-4">
    {field.fields?.map((subfield, index) => (
      <div key={subfield.id} className="flex flex-col mb-2 flex-1 min-w-[45%]">
        <label htmlFor={subfield.id} className="mb-1">
          {subfield.label}
        </label>

        <InputField
          isReadOnly={isReadOnly}
          field={subfield}
          sectionId={sectionId}
          dummyvalue={(e) => setdummyvalueforselect(e)}
          value={value?.[subfield.id] || ''}
          dependentFormData={dependentFormData}
          selectedFirst={selectedFirst}
          setSelectedFirst={setSelectedFirst}
          formValuesUpdated={formValuesUpdated}
          setFormValuesUpdated={setFormValuesUpdated}
          error={errors[`${sectionId}-${subfield.id}`]}
          mode={mode}
          onChange={(e) => {
            handleInputChange(
              sectionId,
              field.id,
              {
                ...value,
                [subfield.id]: e.target.value,
              },
              field.type
            );
          }}
        />

        {errors[`${sectionId}-${subfield.id}`] && (
          <p className="text-sm text-red-500">
            {errors[`${sectionId}-${subfield.id}`]}
          </p>
        )}
      </div>
    ))}
  </div>
</div>

    ) : (
      <>
        {field.isrepeatedField === 'yes' && (
          <div className="flex gap-2 justify-end">
            <div
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={() => handleClick(field, sectionId, 'Add')}
            >
              <FiPlus size={28} />
            </div>
            <div
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={() => handleClick(field, sectionId, 'Delete')}
            >
              <FiX size={28} />
            </div>
          </div>
        )}

        {field.isrepeatedField === 'delete' && (
          <div className="flex gap-2 justify-end">
            <div
              className="cursor-pointer text-blue-500 hover:text-blue-700"
              onClick={() => handleClick(field, sectionId, 'Delete')}
            >
              <FiX size={20} />
            </div>
          </div>
        )}

        <InputField
          isReadOnly={isReadOnly}
          field={field}
          sectionId={sectionId}
          value={value}
          dummyvalue={(e) => setdummyvalueforselect(e)}
          selectedFirst={selectedFirst}
          setSelectedFirst={setSelectedFirst}
          formValuesUpdated={formValuesUpdated}
          setFormValuesUpdated={setFormValuesUpdated}
          mode={mode}
          dependentFormData={dependentFormData}
          error={error}
          onChange={(e) => {
            if (field.type === 'file') {
              handleInputChange(
                sectionId,
                field.id,
                e.target.files,
                field.type
              );
            } else {
              handleInputChange(
                sectionId,
                field.id,
                e.target.value,
                field.type
              );
              handleParentFormData(
                e,
                sectionId,
                field.id,
                e.target.value,
                field.type,
                field.formid,
                field
              );
            }
          }}
        />
      </>
    );
  };
  const mergeValuesOnly = (target:any, source:any) => {
    for (const key in source) {
        if (typeof source[key] === "object" && !Array.isArray(source[key])) {
            // If the object has a "value" field, store only the value
            if ("id" in source[key]) {
                target[key] = source[key].id;
            } else {
                // Ensure target[key] exists as an object
                if (!target[key] || typeof target[key] !== "object") {
                    target[key] = {};
                }
                mergeValuesOnly(target[key], source[key]); // Recursive merge
            }
        }
    }
    return target;
  }

  const handleSubmit = async (e: React.FormEvent, draftmode?: any) => {
    e.preventDefault();
    setSignaturesave(true);
    setWebsite(true);
    setDrawingsave(true);
    await handleFileUpload();

    mergeValuesOnly(formValues, selectedFirst);


    if (!formValues || !formDataa) {
      console.error('Form values or data is undefined or null');
      alert('please fill the information');
      return;
    }
    const updatedFormData = { ...formDataa, userId: userid };

  
    updatedFormData.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (formValues[section.id] && formValues[section.id][field.id]) {
          field.value = formValues[section.id][field.id];
          if (
            field.type === 'file' &&
            formValues[section.id][`${field.id}fileName`]
          ) {
            field.fileName = formValues[section.id][`${field.id}fileName`];
          }
        }
      });
    });

    const validationErrors = validateForm(formValues, formDataa);

    let allFieldsEmpty = true;

    formDataa.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (formValues[section.id] && formValues[section.id][field.id]) {
          if (formValues[section.id]) {
            allFieldsEmpty = false;
          }
        }
      });
    });

    if (formDataa.isDashboard === true && allFieldsEmpty) {
      // toast.error(`Please fill out at least one field before submitting!`, {
      //   position: 'bottom-right',
      //   autoClose: 2000,
      // });
      return;
    }

    if (Object.keys(validationErrors).length > 0 && draftmode !== 'draft') {
      setErrors(validationErrors);
      alert('Please fill the required information.');
    } else {
      if (Object.keys(validationErrors).length > 0 && draftmode === 'draft') {
        setErrors(validationErrors);
        alert('please fill the information3');
      }
      let updatedFormData = { ...formDataa, userId: userid };

   
      
      let parentFormId: string | undefined = '';
      updatedFormData.sections.forEach((section) => {
        section.fields.forEach((field) => {
          if (field.markAsDependent && updatedFormData._id === formId) {
            parentFormId = field.parentField;
          }
          if (formValues[section.id] && formValues[section.id][field.id]) {
            field.value = formValues[section.id][field.id];

            if (
              field.type === 'file' &&
              formValues[section.id][`${field.id}fileName`]
            ) {
              field.fileName = formValues[section.id][`${field.id}fileName`];
              for (const key in selectedFiles) {
                const files = selectedFiles[key];
                if (files !== null) {
                  field.fileUrls = [];
                  for (let i = 0; i < files.length - 1; i++) {
                    const fileUrl = `${BACKEND.BACKEND_API}/uploads/${files[i].name}`;
                    field.fileUrls[i] = fileUrl;
                  }
                }
              }
            }
          }
        });
      });
//       updatedFormData = { ...updatedFormData, parentFormId: parentFormId };
      updatedFormData.sections.forEach((section,secIndex) => {
        section.fields.forEach((field, index) => {
          if (field.markAsDependent) {
            const dependentFormDataobj: { [key: string]: any } = selectedFirst;
            const parentValueId = dependentFormDataobj?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['id'];
            const parentValue = dependentFormDataobj?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['value'];
            if (parentValue != undefined && parentValue !== '') {
              updatedFormData.sections[secIndex].fields[index] = {
                ...updatedFormData.sections[secIndex].fields[index],
                parentFormValue: parentValue,
                parentFormId: parentValueId,
              };
            }
          }
        });
      });

      try {
        updatedFormData.sections.forEach((section) => {
          section.fields.forEach((field) => {
            if (
              field.type === 'file' &&
              formValues[section.id][`${field.id}fileName`]
            ) {
              handleFileUpload();
            }
          });
        });

        if (
          editmode === 'editfields' ||
          localStorage.getItem('mode') === 'edit'
        ) {
          const response = await axios.put(
            `${BACKEND.BACKEND_API}/value/valueupdate/${updatedFormData?._id}`,
            updatedFormData,
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );

          if (response.status === 200 || response.status === 201) {
           
            const responsee = await axios.put(
              `${BACKEND.PLUGIN_BACKEND_API}/plugin/pluginvalueupdate/${updatedFormData?._id}`,
              updatedFormData,
              {
                headers: {
                  Authorization: `Bearer ${authtoken}`,
                },
              }
            );

            if (fid == '67b47c96f6c99a5c3874d867') {
             
              const result = await axios.post(
                `${BACKEND.PLUGIN_BACKEND_API}/plugin/pluginresend`,
                { responseId: updatedFormData?._id, formId: fid },
                {
                  headers: {
                    Authorization: `Bearer ${authtoken}`,
                  },
                }
              );
            }

            if (responsee.status === 200 || responsee.status === 201) {
              setFormValues({});
              toast.success(`Response update successfully.`, {
                position: 'bottom-right',
                autoClose: 2000,
              });

              const changeField = transformJson(
                initialFormValues,
                fieldChanges,
                'Updated'
              );
              renderToaster(
                updatedFormData,
                changeField,
                response.data.insertedId,
                `${updatedFormData.formTitle} Updated`,
                updatedFormData._id,
                userConfig?.name
              );
            } else {
              console.log(
                'Server or Network issue on flatdata value submission'
              );
            }
          } else {
            console.log('Server or Network issue on value submission');
          }
        } else {
          if (draftmode === 'draft') {
            updatedFormData = { ...updatedFormData, status: 'draft' };
          }
          try {
            //to check whether draft record is there or not
            const response = await axios.get(
              `${BACKEND.BACKEND_API}/value/draftValueCheck/${formId}/${updatedFormData.userId}`,
              {
                headers: {
                  Authorization: `Bearer ${authtoken}`,
                },
              }
            );
            if (response.data._id !== undefined) {
              //update the same document
              const valesFormData = Object.fromEntries(
                Object.entries(updatedFormData).filter(([key]) => key !== '_id')
              );
              const valuesFormData = Object.fromEntries(
                Object.entries(valesFormData).filter(
                  ([key]) => key !== 'plugins'
                )
              );

              const valuesData = { ...valuesFormData, status: '' };
              const respons = await axios.put(
                `${BACKEND.BACKEND_API}/value/${response.data._id}`,
                valuesData,
                {
                  headers: {
                    Authorization: `Bearer ${authtoken}`,
                  },
                }
              );

              if (respons.status === 201 || respons.status === 200) {
                setFormValues({});
                setCanShowDraftButton(false);
                toast.success(`Response Added successfully.`, {
                  position: 'bottom-right',
                  autoClose: 2000,
                });

                if (
                  updatedFormData.plugins?.length > 0 &&
                  draftmode !== 'draft'
                  
                ) {
                  
                  const response = await axios.post(
                    `${BACKEND.PLUGIN_BACKEND_API}/plugin/pluginvalueinsert`,
                    {
                      ...updatedFormData,
                      flatCopyId: respons.data?.insertedId,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${authtoken}`,
                      },
                    }
                  );
                }
                if(response.status===200)
                {
                  console.log(updatedFormData,'updatedFormData')
                }
              } else {
                console.log('Server or Network issue');
              }
            }
          } catch (error: any) {
            const respons = await axios.post(
              `${BACKEND.BACKEND_API}/valueoperation/valueinsert`,
              updatedFormData,
              {
                headers: {
                  Authorization: `Bearer ${authtoken}`,
                },
              }
            );

            if (respons.status === 201 || respons.status === 200) {
              const result = await axios.post(
                `${BACKEND.PLUGIN_BACKEND_API}/plugin/flatpluginvalueinsert`,
                {
                  ...updatedFormData,
                  flatCopyId: respons.data?.result?.insertedId,
                },
                {
                  headers: {
                    Authorization: `Bearer ${authtoken}`,
                  },
                }
              );

              setFormValues({});
              toast.success(`Response Added successfully.`, {
                position: 'bottom-right',
                autoClose: 2000,
              });
                
          

              if (
                updatedFormData.plugins?.length > 0 &&
                draftmode !== 'draft'
              ) {
                const response = await axios.post(
                  `${BACKEND.PLUGIN_BACKEND_API}/plugin/pluginvalueinsert`,
                  {
                    ...updatedFormData,
                    flatCopyId: respons.data?.result?.insertedId,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${authtoken}`,
                    },
                  }
                );
              }
              
              const changeField = transformJson(
                initialFormValues,
                fieldChanges,
                'Added'
              );
              renderToaster(
                updatedFormData,
                [`${updatedFormData.formTitle} Added`],
                respons.data.insertedId,
                `${updatedFormData.formTitle} Added`,
                respons.data?.result?.insertedId,
                userConfig?.name
              );
            } else {
              console.log('Server or Network issue');
            }
          }
        }
      } catch (error: any) {
        console.error(error, 'error');
        if (error.response) {
          toast.error(`${error.response.data.error}`, {
            position: 'bottom-right',
            autoClose: 2000,
          });
        } else {
          toast.error('An unknown error occurred', {
            position: 'bottom-right',
            autoClose: 2000,
          });
        }
      }
    }
  };

  return (
    <div className=" ">
      <div className="block justify-between page-header md:flex">
        <div>
          <h3 className="text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
            {formDataa.formTitle}
          </h3>
        </div>
      </div>
      <form className="space-y-6">
        <div className="relative h-[80vh] overflow-auto">
          {formDataa.sections.map((section) => (
            <div
              key={section.id}
              className="box absolute p-2 -gray-300  order bg-gray-50"
              style={{
                left: section.x,
                top: section.y,
                width: section.width,
                height: section.height,
              }}
            >
              {/** Section Name */}
              <div className="box-header w-full p-2 text-left bg-gray-200 dark:bg-gray-800 border dark:border-gray-700 rounded">
                <h5 className="box-title">{section.name}</h5>
              </div>

              {/** Section Fields */}
              <div className="box-body p-4">
                {section.fields.map((field) => (
                  <div
                    key={field.id}
                    className="absolute bg-gray-50 dark:bg-gray-900 "
                    style={{
                      left: field.x,
                      top: field.y,
                      width: field.width,
                      height: field.height,
                    }}
                  >
                    {field.type !== 'Address' && field.type !== 'fullname' ? (
                      <>
                        <label htmlFor={field.id} className="ti-form-label">
                          {field.label}
                        </label>
                      </>
                    ) : (
                      <></>
                    )}
                    {renderField(field, section.id)}
                    {errors[`${section.id}-${field.id}`] && (
                      <p className="text-sm text-red-500">
                        {errors[`${section.id}-${field.id}`]}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {!formDataa.isDashboard && (
          <div className="flex items-center justify-center">
            {editmode !== 'editfields' && canShowDraftButton && (
              <button
                onClick={(e) => handleSubmit(e, 'draft')}
                className="ti-btn ti-btn-primary"
              >
                Submit as Draft
              </button>
            )}
            <button
              onClick={(e) => handleSubmit(e)}
              className="ti-btn ti-btn-primary"
            >
              Submit
            </button>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
});
