import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { motion } from 'framer-motion';
import { FaEdit } from 'react-icons/fa';
import { BiArrowBack } from 'react-icons/bi';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { FormRenderer, useFormStore } from '@empire/components/organisms';
import { Plugin, OrgStatus, FormJSON } from '@empire/components';

import 'react-toastify/dist/ReactToastify.css';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  PLUGIN_BACKEND_API: process.env.PLUGIN_BACKEND_API,
};

interface ModalProps {
  isOpen: boolean;
  formNames: string[];
  onClose: () => void;
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const Installed = () => {
  const [formConfig, setFormConfig] = useState<FormJSON | null>(null);
  const [editformConfig, setEditFormConfig] = useState<FormJSON | null>(null);
  const [formconfiglist, setFormConfigList] = useState<Plugin[]>([]);
  const [pluginformlist, setPluginFormList] = useState<any[]>([]);
  console.log(pluginformlist, 'pluginformlist');
  const [pluginlist, setPluginList] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPluginId, setSelectedPluginId] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'ADD' | 'UPDATE' | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { userConfig } = useFormStore();
  const authtoken = userConfig?.token;
  const orgStatus: OrgStatus[] = userConfig?.orgStatus || [];
  const [expandedDescriptions, setExpandedDescriptions] = useState<
    Record<string, boolean>
  >({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formNames, setFormNames] = useState<string[]>([]);
  const [isModalOpenn, setIsModalOpenn] = useState(false);
  const [pluginToDelete, setPluginToDelete] = useState<string | null>(null);

  const fetchInstalledPlugins = async () => {
    if (!orgStatus || orgStatus.length === 0 || !orgStatus[0]?.orgId) {
      console.error('Invalid orgStatus or orgId');
      return;
    }

    try {
      const response = await axios.post(
        `${BACKEND.PLUGIN_BACKEND_API}/plugin/getinstallplugin`,
        {
          orgId: orgStatus[0].orgId,
        },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      setPluginList(response.data);
    } catch (err: any) {
      console.error('Error fetching plugins:', err);
    }
  };

  const handleConfigClick = (pluginId: string) => {
    setSelectedPluginId(pluginId);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedPluginId(null);
    setViewMode(null);
    setFormConfig(null);
  };

  const handleViewMode = (mode: 'ADD' | 'UPDATE') => {
    setViewMode(mode);
  };

  useEffect(() => {
    fetchInstalledPlugins();
    localStorage.setItem('mode', 'view');
  }, []);

  const fetchPluginForm = async () => {
    if (!selectedPluginId || !orgStatus[0]?.orgId) {
      console.error('Invalid pluginId or orgId');
      return;
    }
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${BACKEND.PLUGIN_BACKEND_API}/plugin/getinstallplugin/${selectedPluginId}`,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      setFormConfig(response.data);
      setIsLoading(false);
    } catch (err: any) {
      console.error('Error fetching plugins:', err);
      setIsLoading(false);
    }

    try {
      const result = await axios.get(
        `${BACKEND.PLUGIN_BACKEND_API}/plugin/getpluginvalue/${selectedPluginId}`,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      setPluginFormList(result.data);
      const filteredData = result.data
        .filter(
          (val: any) =>
            val.sections &&
            val.sections.some(
              (section: any) =>
                section.id === 'a8986274-0c09-42ce-80fd-2576289316dc'
            )
        )
        .map((val: any) => ({
          ...val,
          sections: val.sections.filter(
            (section: any) =>
              section.id === 'a8986274-0c09-42ce-80fd-2576289316dc'
          ),
        }));

      setFormConfigList(filteredData);
      setIsLoading(false);
    } catch (err: any) {
      console.error('Error fetching plugins:', err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPluginId) {
      fetchPluginForm();
    }
  }, [viewMode, selectedPluginId]);

  const handleEditClick = (pluginId: string) => {
    const editableform = pluginformlist.filter((val) => val._id === pluginId);
    setEditFormConfig(editableform[0]);
    localStorage.setItem('mode', 'edit');
  };

  const toggleDescription = (id: string) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    message,
  }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="p-6 bg-white rounded-lg shadow-lg w-96">
          <p className="text-lg font-medium text-gray-700">{message}</p>
          <div className="flex justify-end gap-4 mt-6">
            <button
              onClick={onConfirm}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Yes
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              No
            </button>
          </div>
        </div>
      </div>
    );
  };

  const PluginDisableModal: React.FC<ModalProps> = ({
    isOpen,
    formNames,
    onClose,
  }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
        <div className="p-6 bg-white rounded-lg shadow-lg w-96">
          <p className="mb-4">
            Before you unistall the plugin, please disable it in the following
            forms:
          </p>
          <ul className="mb-4">
            {formNames.map((formName, index) => (
              <li key={index} className="ml-5 list-disc">
                {formName}
              </li>
            ))}
          </ul>
          <button
            onClick={onClose}
            className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const confirmUninstall = async () => {
    if (!pluginToDelete) return;

    try {
      const response = await axios.delete(
        `${BACKEND.PLUGIN_BACKEND_API}/plugin/deleteplugin/${pluginToDelete}`,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success('Plugin uninstalled successfully', {
          position: 'bottom-right',
          autoClose: 2000,
        });
        fetchInstalledPlugins();
      } else {
        toast.error('Failed to uninstall plugin. Please try again later.', {
          position: 'bottom-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('Error while uninstalling plugin:', error);
    } finally {
      setIsModalOpenn(false);
      setPluginToDelete(null);
    }
  };

  const handleUninstall = async (pluginId: string) => {
    try {
      const response = await axios.post(
        `${BACKEND.PLUGIN_BACKEND_API}/plugin/getinstalledpluginbyorg/${pluginId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      if (response.data?.pluginData && response.data.pluginData.length > 0) {
        setFormNames(
          response.data.pluginData.map((form: any) => form.formTitle)
        );
        setIsModalOpen(true);
      } else {
        setPluginToDelete(pluginId);
        setIsModalOpenn(true);
      }
    } catch (error: any) {
      if (error.response?.status === 404) {
        console.log('No plugin data found, nothing to uninstall');
      } else {
        console.error('Error while fetching plugin:', error);
      }
    }
  };

  const closeModall = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="box">
      <PluginDisableModal
        isOpen={isModalOpen}
        formNames={formNames}
        onClose={closeModall}
      />

      <ConfirmationModal
        isOpen={isModalOpenn}
        onClose={() => setIsModalOpenn(false)}
        onConfirm={confirmUninstall}
        message="Are you sure you want to uninstall this plugin?"
      />
      <div className="box-header">
        <h5 className="box-title">Installed Plugins</h5>
      </div>
      {/* <hr className='mb-6' /> */}
      <div className="box-body">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {pluginlist.map((plugin) => (
            <motion.div
              key={plugin._id}
              className="overflow-hidden bg-white border rounded-lg shadow-lg dark:bg-bgdark"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{
                scale: 1.05,
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
              }}
            >
              <img
                src={plugin.files.screenshot1}
                alt={`${plugin.name} Screenshot`}
                className="object-cover w-full h-40"
              />

              <div className="p-4">
                <h3 className="text-xl font-bold">{plugin.name}</h3>
                <p className="mt-2 text-gray-600">
                  <button onClick={() => toggleDescription(plugin._id)}>
                    {expandedDescriptions[plugin._id] ? (
                      <FiChevronUp />
                    ) : (
                      <FiChevronDown />
                    )}
                  </button>
                  {expandedDescriptions[plugin._id] && (
                    <p>Description for {plugin.name}</p>
                  )}
                </p>

                <p className="mt-2 text-sm text-gray-500">
                  Created on: {plugin.creationDate} at {plugin.creationTime}
                </p>

                <motion.button
                  className="w-full py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleConfigClick(plugin._id)}
                >
                  Configuration
                </motion.button>
                <motion.button
                  className="w-full py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleUninstall(plugin._id)}
                >
                  Uninstall
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full relative max-h-[80vh] overflow-y-auto"
          >
            <button
              onClick={() => {
                closeModal();
                localStorage.setItem('mode', 'view');
              }}
              className="absolute text-gray-600 top-4 right-4 hover:text-gray-800"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {isLoading ? (
              <p>Loading...</p>
            ) : viewMode === 'ADD' && formConfig ? (
              <div>
                <button
                  className="flex items-center mb-4 underline"
                  onClick={() => {
                    setViewMode(null);
                    localStorage.setItem('mode', 'view');
                  }}
                >
                  <BiArrowBack className="mr-2 text-lg" />
                </button>
                <FormRenderer formData={formConfig} />
              </div>
            ) : viewMode === 'UPDATE' && editformConfig ? (
              <div>
                <button
                  className="flex items-center mb-4 underline"
                  onClick={() => {
                    setViewMode(null);
                    localStorage.setItem('mode', 'view');
                  }}
                >
                  <BiArrowBack className="mr-2 text-lg" />
                </button>
                <FormRenderer formData={editformConfig} />
              </div>
            ) : (
              <>
                <div className="text-left">
                  <button
                    className="px-4 py-2 mb-4 mr-2 text-white bg-blue-500 rounded"
                    onClick={() => handleViewMode('ADD')}
                  >
                    Add
                  </button>
                  <hr />
                </div>
                <table className="min-w-full border border-collapse border-gray-300">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-left border">Form Title</th>
                      <th className="px-4 py-2 text-left border">
                        Config Name
                      </th>
                      <th className="px-4 py-2 text-center border">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formconfiglist.map((plugin) =>
                      plugin.sections ? (
                        <motion.tr
                          key={plugin._id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 0.5 }}
                        >
                          <td className="px-4 py-2 border">
                            {plugin.formTitle}
                          </td>
                          <td className="px-4 py-2 border">
                            {plugin.sections[0]?.fields[0]?.value}
                          </td>
                          <td className="px-4 py-2 text-center border">
                            <button
                              onClick={() => {
                                handleEditClick(plugin._id); // Fetch editformConfig
                                setViewMode('UPDATE'); // Set mode to update
                              }}
                              className="text-blue-500 hover:text-blue-700"
                              aria-label="Edit"
                            >
                              <FaEdit />
                            </button>
                          </td>
                        </motion.tr>
                      ) : null
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Installed;
