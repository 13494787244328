import React, { useState } from 'react';
import { FaSearch } from "react-icons/fa";

interface ActionButton {
  label: string;
  onClick: (id: number) => void;
  className?: string;
}

interface DataTableProps {
  rows: any[];
  columns: any[];
  actions: ActionButton[];
}

export const DataTable: React.FC<DataTableProps> = ({ rows, columns, actions }) => {
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(null);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  const sortedRows = React.useMemo(() => {
    let sortableRows = [...rows];
    if (sortConfig !== null) {
      sortableRows.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableRows;
  }, [rows, sortConfig]);

  const filteredRows = sortedRows.filter(row =>
    columns.every(col => {
      const filterValue = filters[col.field] || '';
      if (!filterValue) return true;
      return row[col.field].toString().toLowerCase().includes(filterValue.toLowerCase());
    })
  );

  const requestSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
  };

  return (

    <div className="overflow-x-auto p-6 bg-gray-50 rounded-lg">
      <table className="min-w-full table-auto border-collapse rounded-lg bg-white shadow-xl sm:w-full">
        <thead className="bg-gray-100 border-b-2 border-gray-200">
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                onClick={() => requestSort(col.field)}
                className="text-left px-6 py-3 border-b-2 border-gray-200 font-semibold text-gray-700 cursor-pointer hover:bg-gray-200 transition-colors duration-200 sm:p-4"
              >
                {col.headerName}
                {sortConfig?.key === col.field && sortConfig ? (sortConfig.direction === 'asc' ? ' ▲' : ' ▼') : null}
              </th>
            ))}
            <th className="px-6 py-3 border-b-2 border-gray-200 text-left sm:p-4">Actions</th>
          </tr>
          <tr>
            {columns.map((col, index) => (
              <th key={index} className="px-6 py-2 border-b border-gray-200 sm:p-4">
                <div className="relative">
                  <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400 " />

                  <input
                    type="text"
                    placeholder={`Filter ${col.headerName}`}
                    value={filters[col.field] || ''}
                    onChange={(e) => handleFilterChange(e, col.field)}

                    className="w-full pl-10 pr-6 py-2  border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 sm:w-full"

                  />
                </div>
              </th>
            ))}

            <th className="px-6 py-2 border-b border-gray-200 sm:p-4"></th>

          </tr>
        </thead>
        <tbody>
          {filteredRows.map((row, rowIndex) => (

            <tr key={rowIndex} className="even:bg-gray-50 hover:bg-gray-100 transition-colors duration-150  cursor-pointer sm:bg-transparent">
              {columns.map((col, colIndex) => (
                <td
                  key={colIndex}
                  className="px-6 py-4 border-b border-gray-200 text-gray-600 text-sm hover:text-blue-600 transition-all duration-200  hover:bg-gray-100 sm:p-4"

                >
                  {typeof col.field === 'function' ? col.field(row) : row[col.field]}
                </td>
              ))}

              <td className="px-6 py-4 border-b border-gray-200 sm:p-4">
                <div className="flex space-x-3 sm:space-x-6">
                  {actions.map((action, actionIndex) => (
                    <button
                      key={actionIndex}
                      className={`${action.className || 'text-blue-500 hover:text-blue-700'} px-4 py-2 rounded-lg bg-gray-50 hover:bg-gray-100 transition-all duration-200 shadow-sm sm:px-6 sm:py-3`}

                      onClick={() => action.onClick(row.id)}
                    >
                      {action.label}
                    </button>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


