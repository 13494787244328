import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FormList from '../../../../libs/components/organisms/SideNav';
import { useFormStore } from '@empire/components/organisms';
import { RiHome2Line, RiListCheck2 } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { FiX } from 'react-icons/fi';
import BrandLogo from '@empire/theme/assets/img/brand-logos/desktop-logo.png';
import toggleLogo from '@empire/theme/assets/img/brand-logos/toggle-logo.png';
import desktopLogo from '@empire/theme/assets/img/brand-logos/desktop-dark.png';
import toggleDark from '@empire/theme/assets/img/brand-logos/toggle-dark.png';
import axios from 'axios';

interface FormItem {
  status?: string;
  _id: string;
  formTitle: string;
  ismaster: boolean;
  formtype: string[];
  group: string;
}

interface Group {
  _id: string;
  name: string;
}

interface Master {
  group: string;
  formTitle: string;
  _id: string;
}

interface PermissionField {
  value: string;
  label: string;
}

interface Permission {
  fields: PermissionField[];
}

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

const Sidebar: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<FormItem[]>([]);
  const { id: moduleIdO } = useParams();
  const [moduleId, setModuleId] = useState(
    moduleIdO || localStorage.getItem('lastModuleId')
  );
  const [finalmenuItems, setFinalMenuItems] = useState<FormItem[]>([]);
  const [expandedMaster, setExpandedMaster] = useState(false);
  const [expandedUser, setExpandedUser] = useState(false);
  const [expandedReports, setExpandedReports] = useState(false);
  const [masterForms, setMasterForm] = useState<FormItem[]>([]);
  const [userForms, setUserForm] = useState<FormItem[]>([]);
  const [reportForms, setReportForm] = useState<FormItem[]>([]);
  const [masterPermissions, setMasterPermissions] = useState<Permission[]>([]);
  const [userPermissions, setUserPermissions] = useState<Permission[]>([]);
  const [reportPermissions, setReportPermissions] = useState<Permission[]>([]);
  const [formGroups, setFormGroups] = useState<any[]>([]);
  const [masterGroupForms, setMasterGroupForm] = useState<Record<string, Master[]>>({});
  const [userGroupForms, setUserGroupForm] = useState<Record<string, Master[]>>({});
  const [reportGroupForms, setReportGroupForm] = useState<Record<string, Master[]>>({});
  const { userConfig } = useFormStore();
  const location = useLocation();
  const navigate = useNavigate();

  const orgId = userConfig?.orgStatus[0]?.orgId;

  const permission = userConfig?.permission;
  const parsedPermission = useMemo(
    () => (permission ? JSON.parse(permission) : []),
    [permission]
  );
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const authtoken = localStorage.getItem('token');
  const home = localStorage.getItem('home');

  useEffect(() => {
    const storedSection = localStorage.getItem('pluginsection');
    if (storedSection) {
      setActiveSection(storedSection);
    }
  }, []);

  const handleSectionClick = (section: string) => {
    localStorage.setItem('pluginsection', section);
    setActiveSection(section);
  };

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(
          `${BACKEND.BACKEND_API}/forms/orgid/${orgId}`,
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        const modulewiseform = response.data.filter(
          (item: any) => item.moduleId === moduleId
        );
        setMenuItems(modulewiseform);

        if (moduleId) {
          localStorage.setItem('lastModuleId', moduleId);
        }
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };
    fetchMenuItems();
  }, [orgId, moduleId]);

  useEffect(() => {
    if (moduleIdO) {
      setModuleId(moduleIdO);
    }
  }, [moduleIdO]);

  useEffect(() => {
    const filteredMenuItems = menuItems.filter((menuItem) =>
      parsedPermission.some(
        (permission: Permission) =>
          permission.fields.length > 1 &&
          permission.fields[1].value === menuItem._id
      )
    );
    const filteredMenuItemsByStatus = filteredMenuItems.filter((menuItem) => {
      if (menuItem.status !== '' && menuItem.status !== undefined) {
        return menuItem.status !== 'draft' && menuItem;
      }
    });
    setFinalMenuItems(filteredMenuItemsByStatus);
  }, [menuItems, parsedPermission]);

  useEffect(() => {
    axios.get(`${BACKEND.BACKEND_API}/other/getGroups`, {
      headers: {
        'Authorization': `Bearer ${authtoken}`,
      },
    }).then(response => {
      if(response.data.groups.length > 0){
        const groupsKeyValueArray = response.data.groups.map((group: any) => ({ [group._id]: group.name }));
        setFormGroups(response.data.groups.reverse())
      }
    }).catch(error => console.error('Error fetching form:', error));
  },[])

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleNavigation = (formId: string, path: 'form' | 'list') => {
    if (formId) navigate(`/test/${path}/${formId}`);
  };

  useEffect(() => {
    const masters = finalmenuItems.filter((item) =>
      item.formtype.includes('6731eb931d47fae8ef5b4293')
    );
    const users = finalmenuItems.filter((item) =>
      item.formtype.includes('6731eb9b1d47fae8ef5b4294')
    );
    const reports = finalmenuItems.filter((item) =>
      item.formtype.includes('6731ec261d47fae8ef5b4295')
    );

    const masterPermissions = parsedPermission.filter(
      (permission: Permission) =>
        permission.fields.length > 1 &&
        masters.some((master) => master._id === permission.fields[1].value)
    );

    const userPermissions = parsedPermission.filter(
      (permission: Permission) =>
        permission.fields.length > 1 &&
        users.some((users) => users._id === permission.fields[1].value)
    );

    const reportPermissions = parsedPermission.filter(
      (permission: Permission) =>
        permission.fields.length > 1 &&
        reports.some((reports) => reports._id === permission.fields[1].value)
    );

    setMasterForm(masters);
    setUserForm(users);
    setReportForm(reports);
    setMasterPermissions(masterPermissions);
    setUserPermissions(userPermissions);
    setReportPermissions(reportPermissions);

    const groupMap: Record<string, string> = formGroups.reduce((acc, group) => {
      acc[group._id] = group.name;
      return acc;
    }, {});

    const groupedMasters = masters.reduce<Record<string, Master[]>>((acc, item) => {
      const groupName: any = groupMap[item.group] || 'Others';
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item);
      return acc;
    }, {});
    setMasterGroupForm(groupedMasters)
    const groupedUsers = users.reduce<Record<string, Master[]>>((acc, item) => {
      const groupName: any = groupMap[item.group] || 'Others';
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item);
      return acc;
    }, {});
    setUserGroupForm(groupedUsers)
    const groupedReports = reports.reduce<Record<string, Master[]>>((acc, item) => {
      const groupName: any = groupMap[item.group] || 'Others';
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item);
      return acc;
    }, {});
    setReportGroupForm(groupedReports)
  }, [finalmenuItems]);

  return (
    // <div
    //   className={`bg-dark-blue text-white min-h-full w-56 p-4 overflow-y-auto transition-transform duration-300 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}
    // >
    //   <div className="flex items-center justify-between mb-4">
    //     <button className="text-xl md:hidden" onClick={toggleSidebar}>
    //       <FiX />
    //     </button>
    //   </div>

    //   {location.pathname !== "/modules" && (
    //     home === "CRMHOME" ? (
    //       <nav className="py-10">
    //         <div className="flex text-white between space-m-2">
    //           <button
    //             className="px-2 py-2 mb-3 mr-2 text-white bg-gray-800 rounded cursor-pointer font-roboto hover:bg-curious-blue-800"
    //             onClick={() => {
    //               navigate('/modules');
    //             }}
    //           >
    //             <RiHome2Line className="inline-block w-5 h-5 " /> Home
    //           </button>
    //           <button
    //             className="px-2 py-2 mb-3 ml-2 text-white bg-gray-800 rounded cursor-pointer font-roboto hover:bg-curious-blue-800"
    //             onClick={() => {
    //               navigate(-1);
    //             }}
    //           >
    //             <RiListCheck2 className="inline-block w-5 h-5 " /> Back
    //           </button>
    //         </div>
    //         <FormList forms={masterForms} expanded={expandedMaster} setExpanded={setExpandedMaster} title="Master" handleNavigation={handleNavigation} permissiontype={masterPermissions} />
    //         <FormList forms={userForms} expanded={expandedUser} setExpanded={setExpandedUser} title="Transaction" handleNavigation={handleNavigation} permissiontype={userPermissions} />
    //         <FormList forms={reportForms} expanded={expandedReports} setExpanded={setExpandedReports} title="Settings" handleNavigation={handleNavigation} permissiontype={reportPermissions} />
    //       </nav>
    //     ) : (
    //       <ul className="py-10 space-y-4">
    //         {['Installed', 'Public', 'Private'].map((section) => (
    //           <motion.li
    //             key={section}
    //             className={`w-full text-left p-2 rounded ${activeSection === section ? 'bg-gray-500' : ''}`}
    //             onClick={() => {
    //               handleSectionClick(section);
    //               navigate(section);
    //             }}
    //             whileHover={{
    //               scale: 1.05,
    //               backgroundColor: activeSection === section ? '#6b7280' : '#4b5563',
    //             }}
    //             whileTap={{ scale: 0.95 }}
    //             animate={{
    //               backgroundColor: activeSection === section ? '#6b7280' : '#1f2937',
    //             }}
    //             transition={{ duration: 0.2, ease: 'easeInOut' }}
    //           >
    //             {section}
    //           </motion.li>
    //         ))}
    //       </ul>
    //     )
    //   )}
    // </div>

    <aside className="app-sidebar" data-menu-styles="dark" id="sidebar">
      <div className="main-sidebar-header">
        <a   className="header-logo">
          <img src={BrandLogo} alt="logo" className="main-logo desktop-logo" />
          <img src={toggleLogo} alt="logo" className="main-logo toggle-logo" />
          {/* <img
            src={desktopLogo}
            alt="logo"
            className="main-logo desktop-dark"
          /> */}
          <span className="text-white font-bold text-2xl ml-10">ADAPTIX</span>
          <img src={toggleDark} alt="logo" className="main-logo toggle-dark" />
        </a>
      </div>
      <div className="main-sidebar " id="sidebar-scroll" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: '0px 0px -80px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
              <div
                className="simplebar-content-wrapper"
                tabIndex={0}
                role="region"
                aria-label="scrollable content"
                style={{ height: '100%', overflow: 'hidden scroll' }}
              >
                <div
                  className="simplebar-content"
                  style={{ padding: '0px 0px 80px' }}
                >
                  <nav className="main-menu-container nav nav-pills flex-column sub-open open active">
                    <div className="slide-left active hidden" id="slide-left">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7b8191"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      >
                        <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                      </svg>
                    </div>
                    <ul
                      className="main-menu"
                      style={{
                        display: 'block',
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    >
                      <li className="slide__category">
                        <span className="category-name">Main</span>
                      </li>

                      <li className="slide">
                        <span
                          className="side-menu__label"
                          style={{ color: 'white' }}
                        >
                          {location.pathname !== '/modules' &&
                            (home === 'CRMHOME' ? (
                              <>
                                <div className="flex text-gray-300  hover:text-white between space-m-2">
                                  <button
                                    className="px-2 py-2 mb-3 mr-2 text-gray-300  hover:text-white"
                                    onClick={() => {
                                      navigate('/modules');
                                    }}
                                  >
                                    <i className="ri-home-3-line w-5 h-5"></i>
                                    Home
                                  </button>
                                  <button
                                    className="px-2 py-2 mb-3 ml-2 text-gray-300  hover:text-white"
                                    onClick={() => {
                                      navigate(-1);
                                    }}
                                  >
                                    <i className="ti ti-arrow-left w-5 h-5"></i>
                                   
                                    Back
                                  </button>
                                </div>
                                <FormList
                                  forms={masterForms}
                                  groupForms={masterGroupForms}
                                  expanded={expandedMaster}
                                  setExpanded={setExpandedMaster}
                                  title="Master"
                                  handleNavigation={handleNavigation}
                                  permissiontype={masterPermissions}
                                />
                                <FormList
                                  forms={userForms}
                                  groupForms={userGroupForms}
                                  expanded={expandedUser}
                                  setExpanded={setExpandedUser}
                                  title="Transaction"
                                  handleNavigation={handleNavigation}
                                  permissiontype={userPermissions}
                                />
                                <FormList
                                  forms={reportForms}
                                  groupForms={reportGroupForms}
                                  expanded={expandedReports}
                                  setExpanded={setExpandedReports}
                                  title="Settings"
                                  handleNavigation={handleNavigation}
                                  permissiontype={reportPermissions}
                                />
                              </>
                            ) : (
                              <ul className="py-10 space-y-4">
                                {['Installed', 'Public', 'Private'].map((section) => (
                                  <motion.li
                                    key={section}
                                    className={`w-full text-left p-2 rounded ${activeSection === section ? 'bg-gray-500' : ''}`}
                                    onClick={() => {
                                      handleSectionClick(section);
                                      navigate(section);
                                    }}
                                    whileHover={{
                                      scale: 1.05,
                                      backgroundColor: activeSection === section ? '#6B7280' : '#4B5563',
                                    }}
                                    whileTap={{ scale: 0.95 }}
                                    animate={{
                                      backgroundColor: activeSection === section ? '#6B7280' : '#1F2937',
                                    }}
                                    transition={{ duration: 0.2, ease: 'easeInOut' }}
                                  >
                                    {section}
                                  </motion.li>
                                ))}
                              </ul>
                            ))}{' '}
                        </span>
                      </li>
                    </ul>
                    <div className="slide-right hidden" id="slide-right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#7b8191"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      >
                        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                      </svg>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 'auto', height: 1230 }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              width: 0,
              transform: 'translate3d(0px, 0px, 0px)',
              display: 'none',
            }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: 'visible' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              height: 69,
              transform: 'translate3d(0px, 0px, 0px)',
              display: 'block',
            }}
          />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
