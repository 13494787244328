import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IoMdAttach, IoMdClose } from 'react-icons/io';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { FaFileImport } from 'react-icons/fa6';
import { FaSearch } from 'react-icons/fa';
import { CiEdit } from 'react-icons/ci';
import { IoEye } from 'react-icons/io5';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { toast, ToastContainer } from 'react-toastify';
import { useFormStore } from '@empire/components/organisms';
import axios from 'axios';
import { format } from 'date-fns';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  PLUGIN_BACKEND_API: process.env.PLUGIN_BACKEND_API,
};

const DataTable: React.FC = ({}) => {
  const [columns, setColumns] = useState<ColDef[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<Set<string>>(
    new Set()
  );
  const [data, setData] = useState<any[]>([]);
  const [formData, setFormData] = useState<any[]>([]);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletevalue, setDeleteVale] = useState(false);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const authtoken = localStorage.getItem('token');
  const [isModalOpenn, setIsModalOpenn] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState<any | null>(
    null
  );
  const { userConfig, setUserConfig } = useFormStore();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const navigate = useNavigate();
  const formId = useParams().formId;

  const matchingRelation = JSON.parse(
    localStorage.getItem('matchingRelation') || '{}'
  );
  useEffect(() => {
    async function fetchUserDetails() {
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        });
        setUserConfig(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    }

    fetchUserDetails();
  }, [authtoken, setUserConfig, BACKEND.BACKEND_API]);

  const hierarchicalreporting = userConfig?.reporting;

  const formidd = useParams();
  const formpermission = JSON.parse(
    localStorage.getItem('formpermission') || 'null'
  );
  const permission = formpermission?.fields || [];

  const showDelete = permission.some((_: any) => true);

  const urlCellRenderer = (params: any) => {
    const value = params.value || '';

    if (
      value.toString().startsWith('http://') ||
      value.toString().startsWith('https://') ||
      // value.startsWith('data:')
      value.toString().startsWith('data:') 
    ) {
      return (
        <>
          {value.toString().startsWith('data:') ? (
            <a href={value} download>
              <img
                src={value}
                alt="image"
                style={{ width: '100%', height: '50px' }}
              />
            </a>
          ) : (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <a
                href={value}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <div className="flex items-center justify-center pr-6">
                  <IoMdAttach
                    className="mt-2 ml-4 text-curious-blue-600 hover:text-curious-blue-800"
                    style={{ transform: 'rotate(90deg)', fontSize: '1.5rem' }}
                  />
                  {params.colDef.fieldType === 'website' ? value : ''}
                </div>
              </a>
            </a>
          )}
        </>
      );
    }

    return value;
  };


  
  useEffect(() => {
    if (
      !formId ||
      !Array.isArray(hierarchicalreporting) ||
      hierarchicalreporting.length === 0
    ) {
      console.error('Invalid formId or hierarchicalreporting');
      return;
    }
    const fetchData = async () => {
      const response = await axios.get(
        `${BACKEND.BACKEND_API}/forms/${formId}`,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      if (
        response.data.formtype.includes('6731eb931d47fae8ef5b4293') === true ||
        response.data.formtype.includes('6731ec261d47fae8ef5b4295') === true
      ) {
        try {
          let results;
          if (Object.keys(matchingRelation).length === 0) {
            const response = await axios.get(
              `${BACKEND.BACKEND_API}/value/valueCheckFlat/${formId}`,
              {
                headers: {
                  Authorization: `Bearer ${authtoken}`,
                },
              }
            );

            results = response.data;
          }

          setFormData(results);
          setProcessedData(results);
         
        } catch (error) {
          console.error('Error in fetchData:', error);
        }
      } else {
        try {
          let results;

          if (Object.keys(matchingRelation).length === 0) {
            const response = await axios.post(
              `${BACKEND.BACKEND_API}/other/getallformcrm`,
              {
                formId,
                userId: hierarchicalreporting,
              },
              {
                headers: {
                  Authorization: `Bearer ${authtoken}`,
                },
              }
            );

            results = response.data;
          }

          setFormData(results);
          setProcessedData(results);
         
        } catch (error) {
          console.error('Error in fetchData:', error);
        } finally {
          console.log('finally');
        }
      }
    };
    fetchData();
  }, [formId, deletevalue, hierarchicalreporting]);

 
  useEffect(() => {
    if (!processedData.length) return;
    const fields = processedData[0]?.sections?.flatMap(
      (section: any) =>
        section?.fields?.flatMap((field: any) => {
          if (field?.fields && field.type !== 'range') {
            return field.fields.map((innerField: any) => ({
              headerName: innerField.label,
              field: innerField.id,
              fieldtype: innerField.type,
              filter: true,
              cellRenderer: urlCellRenderer,
            }));
          }
          return [
            {
              headerName: field.label,
              field: field.id,
              fieldtype: field.type,
              filter: true,
              cellRenderer: urlCellRenderer,
            },
          ];
        }) || []
    );
    setColumns(fields || []);
    const rows = processedData.map((form: any) => {
      const row: any = { formTitle: form.formTitle, formId: form._id };
      form.sections?.forEach((section: any) =>
        section.fields?.forEach((field: any) => {
          if (field.fields) {
            field.fields.forEach(
              (innerField: any) =>
                (row[innerField.id] = field.value?.[innerField.id] || '')
            );
          } else {
            const fieldType = field.type;
            if (fieldType === 'monthYearPicker') {
              const formattedValue =
                fieldType === 'monthYearPicker' && field.value
                  ? form(new Date(field.value), 'MM/yyyy')
                  : '';
              row[field.id] = field.linkedValue || formattedValue || '';
            } else {
              // console.log(field.type, 'field.valuepp')
              row[field.id] = field.linkedValue || field.value || '';
            }
          }
        })
      );
      return row;
    });
    setData(rows);
  }, [processedData]);

  useEffect(() => {
    const fetchdetail = async () => {
      if (!userConfig) {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        });
        const data = await response.data;
        setUserConfig(data);
      }
    };
    fetchdetail();
  }, []);

  const handleEdit = useCallback(
    (row: any) => {
      navigate(`/test/form/${row.formId}`, {
        state: { row, mode: 'editfields' },
      });
    },
    [navigate]
  );

  const handleView = useCallback(
    (row: any) => {
      navigate(`/test/publiclist/${row.formId}`, {
        state: { row, mode: 'editfields' },
      });
    },
    [navigate]
  );

  const confirmDelete = async () => {
    if (!selectedRowForDelete) return;

    try {
      const respons = await axios.get(
        `${BACKEND.BACKEND_API}/forms/${formidd.formId}`,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      const plugins = respons.data.plugins;
      const matchedPlugin = plugins.find(
        (plugin: any) => plugin.selectedPluginId === '6763be2de3d5ca3d74981b2a'
      );

      const response = await axios.delete(
        `${BACKEND.BACKEND_API}/value/valueDelete/${selectedRowForDelete.formId}`,
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      if (matchedPlugin) {
        await axios.delete(
          `${BACKEND.PLUGIN_BACKEND_API}/plugin/pluginvalueDelete/${selectedRowForDelete.formId}`,
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );
      }

      if (response.status === 200) {
        setDeleteVale(!deletevalue);
        const newLocal = toast.success('Form deleted successfully', {
          position: 'bottom-right',
          autoClose: 2000,
        });
      } else {
        toast.error('Failed to delete the form. Please try again later.', {
          position: 'bottom-right',
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error('Error while deleting the form:', error);
      toast.error('An unexpected error occurred. Please try again.', {
        position: 'bottom-right',
        autoClose: 2000,
      });
    } finally {
      setIsModalOpenn(false);
      setSelectedRowForDelete(null);
    }
  };

  const openDeleteModal = (row: any) => {
    setSelectedRowForDelete(row);
    setIsModalOpenn(true);
  };

  const filteredData = useMemo(() => {
    return data.filter((row) =>
      Object.values(row).some((val) =>
        String(val || '')
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm]);

  

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleColumnChange = (columnId: string) => {
    setSelectedColumns((prevSelectedColumns) => {
      const newSelectedColumns = new Set(prevSelectedColumns);
      if (newSelectedColumns.has(columnId)) {
        newSelectedColumns.delete(columnId);
      } else {
        newSelectedColumns.add(columnId);
      }
      return newSelectedColumns;
    });
  };

  useEffect(() => {
    const defaultSelectedColumns = new Set<string>();
    columns
      .slice(0, 5)
      .forEach((col) => col.field && defaultSelectedColumns.add(col.field));
    setSelectedColumns(defaultSelectedColumns);
  }, [columns]);

  const displayedColumns = useMemo(() => {
    return columns.filter((col) => col.field && selectedColumns.has(col.field));
  }, [columns, selectedColumns]);

  const actionColumn: ColDef = {
    headerName: 'Action',
    field: 'action',
    cellRenderer: (params: any) => {
      return (
        <>
          <button
            onClick={() => handleEdit(params.data)}
            className="text-indigo-500 hover:text-curious-blue-800 "
          >
            <CiEdit />
          </button>
          <button
            onClick={() => handleView(params.data)}
            className="ml-6 text-indigo-500 hover:text-curious-blue-800"
          >
            <IoEye />
          </button>
          {showDelete && (
            <button
              onClick={() => openDeleteModal(params.data)}
              className="ml-6 text-indigo-500 hover:text-curious-blue-800"
            >
              <RiDeleteBin6Line />
            </button>
          )}
        </>
      );
    },
  };

  const handleCheckboxChange = (isChecked: boolean, rowData: any) => {
    setSelectedRowIds((prev) => {
      if (isChecked) {
        return [...prev, rowData.formId];
      } else {
        return prev.filter((id) => id !== rowData.formId);
      }
    });
  };

  const checkActionColumn: ColDef = {
    headerName: 'Check',
    field: 'check',
    width: 80,
    cellRenderer: (params: any) => {
      const isChecked = selectedRowIds.includes(params.data.formId);
      return (
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => handleCheckboxChange(e.target.checked, params.data)}
        />
      );
    },
  };

  const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    isOpen,
    onClose,
    onConfirm,
    message,
  }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="p-6 bg-white rounded-md shadow-md w-96">
          <p className="mb-4 text-center text-gray-700">{message}</p>
          <div className="flex justify-end gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };

  const gridRef = useRef<AgGridReact<any>>(null);

  const exportToExcel = () => {
    if (!gridRef.current || !gridRef.current.api) {
      console.error('❌ Grid reference is not ready');
      return;
    }

    if (!columns || columns.length === 0) {
      console.error('❌ No columns available to export');
      return;
    }

    // ✅ Filter all columns except "Check" and "Action"
    const exportColumns = columns
      .filter(
        (col) =>
          col.headerName !== 'Check' &&
          col.headerName !== 'Action' &&
          col.field?.trim() !== ''
      )
      .map((col) => ({
        field: col.field!,
        headerName: col.headerName || col.field!,
      }));

    if (exportColumns.length === 0) {
      console.error('❌ No valid columns found for export');
      return;
    }

    // ✅ Extract data from grid using all fields (even hidden)
    const exportData: any[] = [];
    gridRef.current.api.forEachNode((node) => {
      const row: any = {};
      exportColumns.forEach((col) => {
        row[col.headerName] = node.data?.[col.field] ?? '';
      });
      exportData.push(row);
    });

    {
      /* Export To Excel Start */
    }
    const headerRow = exportColumns.map((col) => col.headerName);

    // ✅ Generate Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(exportData, {
      header: headerRow,
    });

    // ✅ Apply header styles
    headerRow.forEach((_, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!worksheet[cellRef]) worksheet[cellRef] = {};
      worksheet[cellRef].s = {
        font: { bold: true },
        alignment: { horizontal: 'center' },
      };
    });

    // ✅ Apply border styles
    const range = XLSX.utils.decode_range(worksheet['!ref'] || '');
    for (let R = range.s.r; R <= range.e.r; R++) {
      for (let C = range.s.c; C <= range.e.c; C++) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        if (!worksheet[cellRef]) continue;
        worksheet[cellRef].s = {
          ...worksheet[cellRef].s,
          border: {
            top: { style: 'thin', color: { auto: 1 } },
            bottom: { style: 'thin', color: { auto: 1 } },
            left: { style: 'thin', color: { auto: 1 } },
            right: { style: 'thin', color: { auto: 1 } },
          },
        };
      }
    }

    // ✅ Generate file name
    const fileName = formData[0]?.formTitle
      ? `${formData[0]?.formTitle}.xlsx`
      : 'Export.xlsx';

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');
    XLSX.writeFile(workbook, fileName);

    console.log(`✅ Excel file "${fileName}" generated with all columns!`);
  };
  {
    /* Export to Excel End Code */
  }

  {
    /* Export to PDF Code Start*/
  }

  const exportToPDF = () => {
    if (!gridRef.current) return;

    const exportColumns = columns.filter(
      (col) =>
        col.field &&
        col.headerName &&
        col.field !== 'check' &&
        col.field !== 'action'
    );

    const tableColumnHeaders = exportColumns.map((col) => col.headerName || '');

    const tableRows: string[][] = [];

    gridRef.current.api.forEachNode((node: any) => {
      const row: string[] = exportColumns.map((col) => {
        const value = node.data?.[col.field!] ?? '';
        return String(value);
      });
      tableRows.push(row);
    });

    // 🔍 Dynamic Width Calculation
    const columnWidth = 100; // Adjust this if needed
    const totalColumns = exportColumns.length;
    const pdfWidth = Math.max(totalColumns * columnWidth, 595); // 595 is default A4 width
    const pdfHeight = 842; // A4 height in pt

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'pt',
      format: [pdfWidth, pdfHeight], // 👈 Dynamic width here
    });

    autoTable(doc, {
      head: [tableColumnHeaders],
      body: tableRows,
      styles: {
        fontSize: 9,
        cellPadding: 4,
        overflow: 'linebreak',
        valign: 'top',
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontStyle: 'bold',
        halign: 'left',
      },
      bodyStyles: {
        halign: 'left',
      },
      margin: { top: 40, left: 20, right: 20 },
      startY: 40,
      tableWidth: 'wrap', // 👈 Ensures content doesn’t get squished
      theme: 'grid',
    });

    const fileName = formData[0]?.formTitle
      ? `${formData[0]?.formTitle}.pdf`
      : 'Leads_Report.pdf';

    doc.save(fileName);
  };

  {
    /*Export PDF Code End */
  }



  return (
    <>
      <div className="block justify-between page-header md:flex">
        <div>
          <h3 className="text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
            {formData[0]?.formTitle}
          </h3>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-6">
        <div className=" col-span-12">
          <div className="box">
            <div className="box-body">
              <ConfirmationModal
                isOpen={isModalOpenn}
                onClose={() => setIsModalOpenn(false)}
                onConfirm={confirmDelete}
                message="Are you sure you want to delete this form?"
              />

              <div className="flex justify-between mt-2">
                <div className="flex gap-4">
                  <button
                    onClick={handleModalOpen}
                    className="ti-btn bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-indigo-500 dark:focus:ring-offset-white/10"
                  >
                    <CiEdit />
                  </button>

                  <button
                    onClick={exportToExcel}
                    style={{ marginBottom: 10 }}
                    className="ti-btn bg-indigo-500 text-white hover:bg-indigo-600 focus:ring-indigo-500 dark:focus:ring-offset-white/10"
                  >
                    Export to Excel
                  </button>

                  <button
                    onClick={exportToPDF}
                    className="ti-btn bg-red-500 text-white hover:bg-red-600 focus:ring-red-500 dark:focus:ring-offset-white/10 mb-2 ml-2"
                  >
                    Export to PDF
                  </button>
                </div>

                <div className="relative w-70">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="p-2 pl-10 ti-form-input"
                  />
                  <div className="absolute top-3.5 left-3 text-gray-500">
                    <FaSearch />
                  </div>
                </div>
              </div>

              {isModalOpen && (
                <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-500 bg-opacity-75 rounded-lg">
                  <div className="p-4 overflow-y-auto bg-white rounded shadow-lg w-96 max-h-96">
                    <div className="flex justify-between ">
                      <h3 className="mb-4 text-xl">Select Columns</h3>
                      <div className="flex justify-end">
                        <button
                          onClick={handleModalClose}
                          className="fixed px-4 py-2 text-white rounded bg-curious-blue-600"
                        >
                          <IoMdClose />
                        </button>
                      </div>
                    </div>
                    <div className="space-y-2 overflow-y-auto">
                      {columns.map((col) => (
                        <div key={col.field} className="flex items-center">
                          <input
                            type="checkbox"
                            checked={
                              col.field ? selectedColumns.has(col.field) : false
                            }
                            onChange={() =>
                              col.field && handleColumnChange(col.field)
                            }
                            className="mr-2"
                          />
                          <label>{col.headerName}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* {loading && (
        <div className="flex items-center justify-center py-8">
          <div className="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full loader animate-spin"></div>
        </div>
      )} */}

              {!loading && (
                <div className="w-full dark:ag-theme-alpine-dark ag-theme-alpine  dark:bg-gray-900 dark:text-gray-300">
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={[
                      checkActionColumn,
                      actionColumn,
                      ...displayedColumns,
                    ]}
                    rowData={filteredData}
                    pagination={true}
                    paginationPageSize={itemsPerPage}
                    domLayout="autoHeight"
                    suppressScrollOnNewData={true}
                    rowSelection="multiple"
                    defaultColDef={{
                      filter: true,
                      resizable: true,
                      editable: true,
                    }}
                  />
                </div>
              )}
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTable;
