import "@lourenci/react-kanban/dist/styles.css";
// @ts-ignore
import Board, { moveCard } from "@lourenci/react-kanban";
import { useEffect, useState } from 'react';
import React from "react";
import axios from 'axios';
import { Field, Form, Section, TodoCardProps } from '../types';
import {FormRenderer} from '../organisms/FormRenderer'
import { GiReturnArrow } from "react-icons/gi";
import { ImCheckboxChecked } from "react-icons/im";
import { useFormStore } from "../organisms";
import { FaUser } from "react-icons/fa";

//This component is still under development. Functionality is being implemented, please don't rely on it yet.

const BACKEND={
    BACKEND_API:process.env.BACKEND_API,
    BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
    PLUGIN_BACKEND_API: process.env.PLUGIN_BACKEND_API
  }

interface CardProps {
    id:string,
    title: string | undefined;
    description: string | undefined;
}

interface BoardProps {
    title: string;
    events: CardProps[];
}

export interface FormJSON extends Form {
  isDashboard: boolean;
  flatCopyId: string;
}
const statusMap: { [key: string]: string } = {}; 


const getStatusFormID = (jsonArray: Form[], editedField:any): any=>{
  const field = jsonArray
  .flatMap((section) => section.sections) 
  .flatMap((subSection) => subSection.fields) 
  .find((field: Field) => field.id === editedField.fieldid);
  return field!==undefined ? field?.formid : ""
}

const setStausMap = (jsonArray: Form[]) => {
  
  jsonArray.forEach((json: Form) =>{
    json.sections.forEach((section: Section) =>{
      section.fields.forEach((field: Field) => {
            const statusName = field.value || "";
            const formId = json._id;
            if(!statusMap[statusName])statusMap[statusName] = formId;
      })
    })
  })
}

const transformToCardJson =  (jsonArray: Form[],  editedField: Field) => {
    const events : any = [];
    if(jsonArray.length===0) return events;
    Object.entries(statusMap).forEach(([key, value]) => {
      const titleEntry = {
        id:  `${value}`,
        title:`${key.split(' (')[0]} (${0})`,
        cards: [],
    };
    events.push(titleEntry);
    });

    let TitleId = '';

    jsonArray.forEach((json: Form) => {
        
        json.sections.forEach((section: Section) => {
            const title = section.fields.find((field: Field) => field.id === editedField.fieldid)?.value || "";
            section.fields.forEach((field: Field) => {
                if (field.id === editedField.fieldid) {
                    const statusName = field.value ||"";
                    TitleId = statusMap[statusName]
                }
            })

            const description = editedField?.displayfieldId
                ?.map((id: string) => section.fields.find((field: Field) => field.id === id)?.value)
                .filter((value: string | undefined) => value !== undefined) 
                .join(' ');

            const titl = editedField?.dragCardfieldId
                ?.map((id: string) => section.fields.find((field: Field) => field.id === id)?.value)
                .filter((value: string | undefined) => value !== undefined) 
                .join(' ');
            const footer = editedField?.dragCardfieldId?.length
                ? section.fields.find((field: Field) => field.id === editedField?.dragCardfieldId?.[0])?.value
                : undefined;
            if (TitleId) {
                let titleEntry = events.find((event: any) => event.id === statusMap[title]);
                if (!titleEntry) {
                    titleEntry = {
                        id:  `${statusMap[title]}`, 
                        title,
                        cards: [],
                    };
                    events.push(titleEntry);
                }

                titleEntry.cards.push({
                    id: `${json._id}`, 
                    title: titl,
                    description: description,
                    footer : footer,
                });

                titleEntry.title =  `${title.split(' (')[0]} (${titleEntry.cards.length})`;
            }
        });
    });
    return events;
};

export const TodoCard = ({ field } : TodoCardProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [dynamicFlatData, setDynamicFlatData] = useState<Form[]>([]); 
    const [dynamicData, setDynamicData] = useState<Form[]>([]); 
    const [board, setBoard] = useState<any>({});
    const [formConfig, setFormConfig] = useState<FormJSON | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {cardModalOpen, setCardModalOpenConfig } = useFormStore();
    const authtoken=localStorage.getItem('token')

    
    const getFormData = async () => {
        try {
            const flatDataResponse = await axios.post(`${BACKEND.BACKEND_API}/other/getflatdataformsvaluesbyid`, {
                formId: field.formid,
            },{
              headers: {
                'Authorization': `Bearer ${authtoken}`,
              },
            });

            const flatdata = flatDataResponse.data || [];
            setDynamicFlatData(flatdata);

            const response = await axios.post(`${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,{formId: field.formid} ,{
              headers: {
                'Authorization': `Bearer ${authtoken}`,
              },
            })

            const data: Form[] = response.data || []; 
            setDynamicData(data);
            if(flatdata.length===0 || data.length===0) GiReturnArrow
            const statusFormid = getStatusFormID(data,field);
            
            const resp = await axios.post(`${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,{formId: statusFormid} ,{
              headers: {
                'Authorization': `Bearer ${authtoken}`,
              },
            })

            const statusdata: Form[] = resp.data;
            setStausMap(statusdata);
            const Board = {columns:transformToCardJson(flatdata,field)};
            setBoard(Board);     
            setLoading(false);
        } catch (err) {
            console.error("Error fetching data:", err);
            setLoading(false);
        }
    };

    useEffect(() => {
        getFormData();
    }, [cardModalOpen]);  

    if (loading) {
        return <div>Loading...</div>;
    }

  const updateDatabase = async (updatedData: Form, id:any) => {
      try {
            const response = await axios.put(`${BACKEND.BACKEND_API}/value/valueupdate/${id}`, updatedData,{
              headers: {
                'Authorization': `Bearer ${authtoken}`,
              },
            });
             const res = await axios.put(`${BACKEND.PLUGIN_BACKEND_API}/plugin/pluginvalueupdate/${id}`,
              updatedData,{
                headers: {
                  'Authorization': `Bearer ${authtoken}`,
                },
              }
            );
        } catch (error) {
            console.error('Error updating database:', error);
        }
    };

const closeModal = () => {
  setCardModalOpenConfig(false);
  localStorage.setItem('mode', 'view');
  setFormConfig(null); 
};

const handleCardClick =  async(card: any) => {
  try {
    
    const formId = card.id; 
    const response = await axios.get(`${BACKEND.BACKEND_API}/value/valuebyresid/${formId}`,{
      headers: {
        'Authorization': `Bearer ${authtoken}`,
      },
    });
    setFormConfig(response.data);
    localStorage.setItem('mode', 'edit')
    setCardModalOpenConfig(true); 
    }catch (error) {
    console.error('Error fetching card data:', error);
  }

};

    
function ControlledBoard() {
  const [controlledBoard, setUpdateBoard] = useState(board);

  function handleCardMove(_card: any, source: any, destination: any) {
    let statusID = destination.toColumnId;
    const updatedBoard = moveCard(controlledBoard, source, destination);

    const updatedColumns = updatedBoard.columns.map((column: any) => ({
      ...column,
      title: `${column.title.split(' (')[0]} (${column.cards.length})`,
    }));

    const updatedControlledBoard = { columns: updatedColumns };
    setUpdateBoard(updatedControlledBoard);
    dynamicData.forEach((json: Form) => {
      if (json._id === _card.id) {
        json.sections.forEach((section: Section) => {
          section.fields.map((fieldd: Field) => {
            if (fieldd.id === field.fieldid) fieldd.value = statusID;
            return fieldd;
          });
        });
        updateDatabase(json, json._id);
      }
    });
  }

return (
    <div className="bg-white p-4 rounded-lg shadow-md font-roboto">
      <Board
        onCardDragEnd={handleCardMove}
        disableColumnDrag
        renderCard={(card: any) => (
          <div
            className="bg-white w-80 p-4 rounded-lg shadow-lg cursor-pointer border border-gray-200 hover:shadow-black transition-shadow duration-300"
            onClick={() => handleCardClick(card)}
          >
            <h4 className="text-lg font-semibold text-gray-800">{card.title}</h4>
            <hr className="my-2 border-gray-300" />
            <p className="text-gray-600">{card.description}</p>
            <div className="flex items-center justify-between mt-4">
              <div className="flex items-center gap-2">
                <ImCheckboxChecked className="w-5 h-5 text-blue-500" />
                <span className="text-gray-700 font-medium">{card.footer}</span>
              </div>

              <div className="w-9 h-9 flex items-center justify-center bg-blue-600 text-white font-bold rounded-full">
              <FaUser size={24} />
              </div>
            </div>
          </div>
        )}
      >
        {controlledBoard}
      </Board>
    </div>
);
}

return (
    <div className="min-h-screen bg-gray-100 p-8">
        
          <ControlledBoard />
          
          {cardModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
              <div className="bg-white p-6 rounded shadow-lg space-y-4 w-auto md:w-[800px] lg:w-[900px] xl:w-[1000px] max-h-[70vh] overflow-y-auto relative">
                <div 
                  onClick={() => closeModal()} 
                  className="absolute top-3 right-3 text-gray-600 hover:text-gray-800 cursor-pointer"
                >
                  ✖
                </div>
                <div className="p-2 mt-6 border rounded">
                  {formConfig ? <FormRenderer formData={formConfig} /> : <div>No form configuration available</div>}
                </div>
              </div>
            </div>
)}



       
    </div>
  );
}
export default TodoCard;


