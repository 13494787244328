
import React, { useEffect, useRef, useState } from 'react';
import { SelectInputProps, Field, Form, Section, MultiSelectInputProps } from '../types';
import axios from 'axios';
import { FaAngleDown } from "react-icons/fa";
import { useFormStore } from '../organisms/UserStore';


interface MenuItem {
  _id: string;
  formTitle: string;
}


import { User } from '../types';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL
}

export const MultiSelectInput = ({ field, value, error, onChange, _id, formTitle ,dummyvalue}: MultiSelectInputProps) => {

  const [dynamicData, setDynamicData] = useState<Form[]>([]);
  const [allSections, setAllSections] = useState<Section[]>([]);
  const [filteredFields, setFilteredFields] = useState<Field[]>([]);
  const [filteredFieldsById, setFilteredFieldsById] = useState<Field[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [menuuserItems, setMenuUserItems] = useState<User[]>([]);
  const [dynamicfieldvalue, setDynamicFieldValue] = useState('');
  const [valueDummy, setDummyValue ] = useState('');
  const [dynamicfieldvalueentity, setDynamicFieldValueEntity] = useState('');
  const [dynamicfieldvaluemultiple, setDynamicFieldValueMultiple] = useState<string[]>([]);
  dummyvalue(valueDummy)
  const { userConfig} = useFormStore();
  const OrgId=userConfig?.orgStatus[0].orgId  || undefined
  const authtoken=localStorage.getItem('token')
  const foodItems = ["Pizza", "Burger", "Pasta", "Sushi", "Tacos", "Salad"];
  const [selectedItemsValues, setSelectedItemsValues] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<any>();

  const handleClickOutside = (event : any) => {
    if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSelection = (item : any) => {
    setSelectedItems((prevSelected : any) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i : any) => i !== item)
        : [...prevSelected, item]
    );
  };

  const toggleSelectionValue = (item : any) => {
    setSelectedItemsValues((prevSelected : any) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i : any) => i !== item)
        : [...prevSelected, item]
    );
  };


  if(field) (field as any).dummyvalue =   valueDummy;
  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
       

        const response = await axios.post(`${BACKEND.BACKEND_API}/other/getForm`, {
          orgId: OrgId ,
        },{
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
        setMenuItems(response.data);
        const [formTitle, _id] = response.data;
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };
    fetchMenuItems();
  }, [OrgId]);

  

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/other/api/users`,{
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });



        setMenuUserItems(response.data);

      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };
    fetchMenuItems();
  }, [OrgId]);

  const handleSelect = (fieldd : Field) =>{

    toggleSelection(fieldd.formId)
    toggleSelectionValue(fieldd.value);
        if (!field.multiValue) {
          field.multiValue = [fieldd.formId || ""];
        } else if (fieldd.formId && !field.multiValue.includes(fieldd.formId)) {
          field.multiValue.push(fieldd.formId);
        }
        else {
          // Remove the field.id if it exists in the array
          if (field.multiValue) {
            field.multiValue = field.multiValue.filter((id) => id !== fieldd.formId)
          }
        }     
       
      }

      const handleSelectEntity = (fieldd : any, value : any) =>{

        toggleSelection(fieldd._id)
        toggleSelectionValue(value);
           if (!field.multiValue) {
              field.multiValue = [fieldd._id || ""];
            } else if (fieldd._id && !field.multiValue.includes(fieldd._id)) {
              field.multiValue.push(fieldd._id);
            }
            else {
              if (field.multiValue) {
                field.multiValue = field.multiValue.filter((id) => id !== fieldd._id)
              }
            }     
          }


  const getFormData = async () => {
    try {
      const response = await axios.post(`${BACKEND.BACKEND_API}/other/getformsvaluesbyid`, {
        formId: field.formid, 
      },{
        headers: {
          'Authorization': `Bearer ${authtoken}`,
        },
      });

      const data: Form[] = response.data;
      setDynamicData(data);

      const extractedSections = data.flatMap((form: Form) =>
        form.sections.map((section) => ({
          ...section,
          formId: form._id 
        }))
      );
      setAllSections(extractedSections);

      const extractsectionidwise = extractedSections.filter((section) => section.id === field.sectionid);

      const fieldsFromSection = extractsectionidwise.flatMap((section) =>
        section.fields.map((fieldItem) => ({
          ...fieldItem,
          formId: section.formId,
        }))
      );
      setFilteredFields(fieldsFromSection);

      const filteredDataById = fieldsFromSection.filter((fieldItem) => fieldItem.id === field.fieldid);
      setFilteredFieldsById(filteredDataById);

    } catch (error) {
      console.error('Error fetching form data:', error);
    }
  };
  useEffect(() => {
    getFormData();
  }, [])


  if (field?.selectType) {
    if (field?.selectType === 'Static') {
      return (
        <select
          id={field.id}
          required={field.required}
          value={value}
          onChange={onChange}
          className={`w-full p-2 border rounded ${error ? 'border-red-500' : ''}`}
        >
          <option value="">{field.placeholder}</option>
          {field.options?.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      );
    }
    else if (field?.selectType === 'Dynamic') {

      useEffect(() => {
        const matchingField = filteredFieldsById.find(filteredField => filteredField.formId === value);
        if (matchingField) {
          setDynamicFieldValue(matchingField.formId || '');
          setDummyValue(matchingField.value || ''); 
          const dummyvalue = matchingField?.value ?? '';
         
        }
      }, [value, filteredFieldsById,dummyvalue]);

      return (
          <div className="relative p-2  rounded py-2">
            <div
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className= {`w-full p-2 border rounded ${error ? 'border-red-500' : ''} p-3 border bg-white flex justify-between items-center hover:bg-gray-200 transition`} //"w-full p-3 border bg-gray-100 text-gray-700 flex justify-between items-center hover:bg-gray-200 transition"
            >
              <span className='font-roboto'>{selectedItems.length===0 ? "Select multiple items" : selectedItemsValues.join(", ")}</span>
              <FaAngleDown className="w-5 h-5 text-gray-600" />
            </div>
            {isDropdownOpen && (
              <ul className="absolute w-full mt-2 bg-white border shadow-lg z-10 overflow-hidden">
                {filteredFieldsById.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelect(item)}
                    className={`p-3 cursor-pointer flex items-center gap-2 transition-all
                                duration-200 border-b border-gray-100 last:border-none
                                ${selectedItems.includes(item.formId || "") ? "bg-gray-100 " : "hover:bg-gray-100"}`}
                  >
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item.formId || "")}
                      readOnly
                      className="w-4 h-4 accent-blue-500"
                    />
                    {item.value}
                  </li>
                ))}
              </ul>
            )}
          </div>
      );
    }

    else if (field?.selectType === 'FormEntity') {
    
          useEffect(() => {
            const matchingField = filteredFieldsById.find(filteredField => filteredField.formId === value);
            if (matchingField) {
              setDynamicFieldValue(matchingField.formId || '');
            }
          }, [value, filteredFieldsById]);
          return (
          <div className="relative p-2  rounded py-2">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className= {`w-full p-2 border rounded ${error ? 'border-red-500' : ''} p-3 border bg-white flex justify-between items-center hover:bg-gray-200 transition`} //"w-full p-3 border bg-gray-100 text-gray-700 flex justify-between items-center hover:bg-gray-200 transition"
            >
              <span className='font-roboto'>{selectedItems.length===0 ? "Select multiple items" : selectedItemsValues.join(", ")}</span>
              <FaAngleDown className="w-5 h-5 text-gray-600" />
            </button>
            {isDropdownOpen && (
              <ul className="absolute w-full mt-2 bg-white border shadow-lg z-10 overflow-hidden">
                {menuItems.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectEntity(item, item.formTitle)}
                    className={`p-3 cursor-pointer flex items-center gap-2 transition-all
                                duration-200 border-b border-gray-100 last:border-none
                                ${selectedItems.includes(item._id || "") ? "bg-gray-100 " : "hover:bg-gray-100"}`}
                  >
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item._id || "")}
                      readOnly
                      className="w-4 h-4 accent-blue-500"
                    />
                    {item.formTitle}
                  </li>
                ))}
              </ul>
            )}
          </div>           

          );
    
        }
    
    else if (field?.selectType === 'UserEntity') {
    
          useEffect(() => {
            const matchingField = filteredFieldsById.find(filteredField => filteredField.formId === value);
            if (matchingField) {
              setDynamicFieldValue(matchingField.formId || '');
            }
          }, [value, filteredFieldsById]);
    
          return (
            <div className="relative p-2  rounded py-2">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className= {`w-full p-2 border rounded ${error ? 'border-red-500' : ''} p-3 border bg-white flex justify-between items-center hover:bg-gray-200 transition`} //"w-full p-3 border bg-gray-100 text-gray-700 flex justify-between items-center hover:bg-gray-200 transition"
            >
              <span className='font-roboto'>{selectedItems.length===0 ? "Select multiple items" : selectedItemsValues.join(", ")}</span>
              <FaAngleDown className="w-5 h-5 text-gray-600" />
            </button>
            {isDropdownOpen && (
              <ul className="absolute w-full mt-2 bg-white border shadow-lg z-10 overflow-hidden">
                {menuuserItems.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectEntity(item, item.name)}
                    className={`p-3 cursor-pointer flex items-center gap-2 transition-all
                                duration-200 border-b border-gray-100 last:border-none
                                ${selectedItems.includes(item._id || "") ? "bg-gray-100 " : "hover:bg-gray-100"}`}
                  >
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(item._id || "")}
                      readOnly
                      className="w-4 h-4 accent-blue-500"
                    />
                    {item.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          );
    
        }
  }
  else {
    console.log('selectType is not defined')
  }
};
