import React, { useState } from 'react';
import { FormEditorProps } from '../types';
import {DialogBox} from './DialogBox';

const DialogBoxForm: React.FC<FormEditorProps> = ({
  isDynamicDialogBox,
  field,
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>('Click me');

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  const handleConfirm = (value: string) => {
    if (value.trim()) {
      setSelectedValue(value);
    }
    setIsDialogOpen(false);
  };
 
  return (
    isDynamicDialogBox && (
      <div>
        <button
          className="px-4 py-2 text-black bg-gray-300 rounded hover:bg-gray-600 hover:text-white"
          onClick={handleOpenDialog}>
          {selectedValue}
        </button>

        {isDialogOpen && (
          <DialogBox
            title="Dialog Title"
            content={field}
            onClose={handleCloseDialog}
            onConfirm={(selected) => {
                const index = field.dialogBoxList?.findIndex(
                  (item) => item.label === selected
                );
                if (index !== undefined && index !== -1) {
                  setSelectedIndex(index);
                  handleConfirm(selected);
                } else {
                  setSelectedIndex(null);
                }
              }}
            cancelText="CLEAR"
            restoreText="RESTORE"
            confirmText="CONFIRM"
            initialSelectedIndex={selectedIndex}
          />
        )}
      </div>
    )
  );
};

export default DialogBoxForm;
