import React, { useEffect, useState } from 'react';
import { SelectInputProps, Field, Form, Section } from '../types';
import axios from 'axios';
import { useFormStore } from '../organisms/UserStore';

interface MenuItem {
  _id: string;
  formTitle: string;
}

import { User } from '../types';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

export const SelectInput = React.memo(({
  field,
  value,
  error,
  onChange,
  _id,
  formTitle,
  dummyvalue,
  dependentFormData,
  selectedFirst,
  setSelectedFirst,
  mode,
  formValuesUpdated,
  setFormValuesUpdated,
}: SelectInputProps) => {
  const [selectedDDValue, setSelectedDDValue] = useState("");
  const [dynamicData, setDynamicData] = useState<Form[]>([]);
  const [allSections, setAllSections] = useState<Section[]>([]);
  const [filteredFields, setFilteredFields] = useState<Field[]>([]);
  const [filteredFieldsById, setFilteredFieldsById] = useState<Field[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [menuuserItems, setMenuUserItems] = useState<User[]>([]);
  const [dynamicfieldvalue, setDynamicFieldValue] = useState('');
  const [valueDummy, setDummyValue] = useState('');
  const [dynamicfieldvalueentity, setDynamicFieldValueEntity] = useState('');
  const [dynamicfieldvaluemultiple, setDynamicFieldValueMultiple] = useState<
    string[]
  >([]);
//   dummyvalue(valueDummy); //commented it out due to reccursive issue
  const { userConfig } = useFormStore();
  const OrgId = userConfig?.orgStatus[0].orgId || undefined;
  const authtoken = localStorage.getItem('token');

  useEffect(() => {
    if (dummyvalue) {
      dummyvalue(valueDummy);
    }
  }, [valueDummy, dummyvalue]);

  if (field) (field as any).dummyvalue = valueDummy;
  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.post(
          `${BACKEND.BACKEND_API}/other/getForm`,
          {
            orgId: OrgId,
          },
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        setMenuItems(response.data);
        const [formTitle, _id] = response.data;
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };
    fetchMenuItems();
  }, [OrgId]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(
          `${BACKEND.BACKEND_API}/other/api/users`,
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        setMenuUserItems(response.data);
      } catch (error) {
        console.error('Failed to fetch form data:', error);
      }
    };
    fetchMenuItems();
  }, [OrgId]);

  useEffect(() => {
//     if (field.formid) {
//       const formId: string = field.formid ?? 'formId';
//       setSelectedFirst((prev) => ({
//         ...prev,
//         [formId]: value,
//       }));
//     }
      if(value !== ''){
        setSelectedFirst((prev) => ({
          ...prev,
          [field.sectionId]: {
              ...(prev[field.sectionId] ?? {}),
              [field.id] : {
                 id : value,
                 value: selectedDDValue ?? value,
              }
          },
        }));
      }
  }, [value,selectedDDValue]);

  useEffect(() => {
    const dependentFormDataobj: { [key: string]: any } = dependentFormData;
    const matchingField = filteredFieldsById.find(
      (filteredField) => filteredField.formId === value
    );
    setFormValuesUpdated((prevValues: any) => {
      const newState = {
        ...prevValues,
        [field.sectionId]: {
          ...(prevValues[field.sectionId] || {}),
          [field.id]: matchingField
            ? matchingField?.formId
            : dependentFormDataobj['isDependent']
            ? ''
            : value,
        },
      };
      return newState;
    });
  }, [value, filteredFieldsById]);

  const getChildFormData = async (
    selectedVal: any,
    fieldId: any,
    formId: any,
    field: any,
    event: any
  ) => {
    let selectedvalue = "";
    if(field?.type === 'select'){
      if (!event) return;
      const target = event.target as HTMLSelectElement;
      const selectedOption = target.options[target.selectedIndex];
      selectedvalue = selectedOption.text;
    } else {
      selectedvalue = event.target.value;
    }

    setSelectedFirst((prev) => ({
      ...prev,
      //[formId]: selectedVal,
      [field.sectionId]: {
          ...(prev[field.sectionId] ?? {}),
          [field.id] : {
           id: event.target.value,
           value: selectedvalue
         }
      },
    }));
  };
  const getFormData = async (selectedVal?: any) => {
    try {
      const dependentFormDataobj: { [key: string]: any } = dependentFormData;
      let canFetchValuesData = false;
      if (!dependentFormDataobj['isDependent']) {
        canFetchValuesData = true;
      }
      let response;

      response = await axios.post(
        `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
        {
          formId: field.formid,
          isFilterd: field.isFilterd || false,
          filters: field.filters || {},
        },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      let data: Form[] = response.data;
      setDynamicData(data);

      let matchedValues:any;
      let parentFieldValue = selectedFirst?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['value'];
      let parentFieldId = selectedFirst?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['id'];
      if(field.markAsDependent && (parentFieldValue !== parentFieldId) && parentFieldValue !== '') {
        const filterresponse = await axios.post(
          `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
          {
            formId: field.formid,
            isFilterd: field.isFilterd || false,
            filters: field.filters || {},
            parentFieldValue : selectedFirst?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['value'] ?? "",
//             parentFieldId : selectedFirst?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['id'] ?? "",
            markAsDependent : field.markAsDependent ?? false
          },
          {
            headers: {
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );
        const data: Form[] = filterresponse.data;
        matchedValues = data
          .flatMap(form => form.sections) // Flatten sections
          .flatMap(section => section.fields) // Flatten fields
          .filter(secfield =>
            selectedFirst?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['value'] === secfield.parentFormValue
//             selectedFirst?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['id'] === secfield.parentFormId
          )
          .map(secfield => secfield.value); // Extract value only from matched field
      }
      if(matchedValues !== undefined){
        data = data.filter(item => matchedValues.includes(item._id));
      }
      const extractedSections = data.flatMap((form: Form) =>
        form.sections.map((section) => ({
          ...section,
          formId: form._id,
        }))
      );
      setAllSections(extractedSections);

      const extractsectionidwise = extractedSections.filter(
        (section) => section.id === field.sectionid
      );

      const fieldsFromSection = extractsectionidwise.flatMap((section) =>
        section.fields.map((fieldItem) => ({
          ...fieldItem,
          formId: section.formId,
        }))
      );
      setFilteredFields(fieldsFromSection);

      const filteredDataById = fieldsFromSection.filter(
        (fieldItem) => fieldItem.id === field.fieldid
      );

      setFilteredFieldsById(filteredDataById);
//       const filteredDataBySelectedVal = filteredDataById.filter(
//         (fieldItem) =>
//           fieldItem.parentFormValue ===
//           selectedFirst?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]['value']
//       );
//       if (filteredDataBySelectedVal) {
//         setFilteredFieldsById(filteredDataBySelectedVal);
//       }
//       if (canFetchValuesData) {
//         setFilteredFieldsById(filteredDataById);
//       }
    } catch (error) {
      console.error('Error fetching form data:', error);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  useEffect(() => {
    if (Object.keys(selectedFirst).length > 0) {
      getFormData(selectedFirst);
    }
  }, [selectedFirst]);

  if (field?.selectType) {
    if (field?.selectType === 'Static') {
      return (
        <select
          id={field.id}
          required={field.required}
          value={value}
          onChange={onChange}
          className={`w-full p-2 border rounded ${
            error ? 'border-red-500' : ''
          }`}
        >
          <option value="">{field.placeholder}</option>
          {field.options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      );
    } else if (field?.selectType === 'Dynamic') {
      useEffect(() => {
        // Set dynamicFieldValue to the prop value if it matches any filteredField's formId
        const matchingField = filteredFieldsById.find(
          (filteredField) => filteredField.formId === value
        );
        if (matchingField) {
          setDynamicFieldValue(matchingField.formId || '');
          setDummyValue(matchingField.value || '');
          setSelectedDDValue(matchingField.value || '');
          const dummyvalue = matchingField?.value ?? '';
        } else {
          setDynamicFieldValue('');
        }
      }, [value, filteredFieldsById, dummyvalue]);

      return (
        <div className="p-2 rounded">
          <select
            name={field.displayname}
            id={`${field.id} ${field.formid}`}
            required={field.required}
            value={dynamicfieldvalue}
            onChange={(e) => {
              onChange(e);
              setDynamicFieldValue(e.target.value);
              setDummyValue(e.target.value);
              getChildFormData(
                e.target.value,
                field.id,
                field.formid,
                field,
                e
              );
            }}
            className={`ti-form-select ${error ? 'border-red-500' : ''} `}
          >
            <option value="">{field.placeholder}</option>
            {filteredFieldsById.map((filteredFieldItem) => (
              <option
                key={filteredFieldItem.formId}
                value={filteredFieldItem.formId}
              >
                {filteredFieldItem.value}
              </option>
            ))}
          </select>
        </div>
      );
    } else if (field?.selectType === 'FormEntity') {
      const sortedMenuItems = menuItems?.slice().sort((a, b) => a.formTitle.localeCompare(b.formTitle)) || [];
      useEffect(() => {
        // Set dynamicFieldValue to the prop value if it matches any filteredField's formId
        const matchingField = filteredFieldsById.find(
          (filteredField) => filteredField.formId === value
        );
        if (matchingField) {
          setDynamicFieldValue(matchingField.formId || '');
        }
      }, [value, filteredFieldsById]);

      return (
        <div className="p-2 bg-gray-200 border rounded">
          <select
            name={field.displayname}
            id={field.id}
            required={field.required}
            value={dynamicfieldvalueentity}
            onChange={(e) => {
              onChange(e);
              setDynamicFieldValueEntity(e.target.value);
            }}
            className={`ti-form-select ${error ? 'border-red-500' : ''}`}
          >
            <option value="">{field.placeholder}</option>
            {sortedMenuItems.map((menuItems) => (
              <option key={menuItems._id} value={menuItems._id}>
                {menuItems.formTitle}
              </option>
            ))}
          </select>
        </div>
      );
    } else if (field?.selectType === 'UserEntity') {
      useEffect(() => {
        // Set dynamicFieldValue to the prop value if it matches any filteredField's formId
        const matchingField = filteredFieldsById.find(
          (filteredField) => filteredField.formId === value
        );
        if (matchingField) {
          setDynamicFieldValue(matchingField.formId || '');
        }
      }, [value, filteredFieldsById]);

      useEffect(() => {

        if(field.DefaultUserEntity){
          const defaultValue = userConfig?.userId;
          setDynamicFieldValueEntity(defaultValue);
        }
      }, [menuuserItems]);

      return (
        <div className="p-2 rounded">
          <select
            name={field.displayname}
            id={field.id}
            required={field.required}
            value={dynamicfieldvalueentity}
            onChange={(e) => {
              onChange(e);
              setDynamicFieldValueEntity(e.target.value);
            }}
            disabled={field.isReadOnly}
            className={`ti-form-select ${error ? 'border-red-500' : ''}`}
          >
            <option value="">{field.placeholder}</option>
            {menuuserItems.map((menuuserItems) => (
              <option
                key={menuuserItems._id as string}
                value={menuuserItems._id as string}
              >
                {menuuserItems.name}
              </option>
            ))}
          </select>
        </div>
      );
    } else if (field?.selectType === 'ReportingEntity') {
      useEffect(() => {
        // Set dynamicFieldValue to the prop value if it matches any filteredField's formId
        const matchingField = filteredFieldsById.find(
          (filteredField) => filteredField.formId === value
        );
        if (matchingField) {
          setDynamicFieldValue(matchingField.formId || '');
        }
      }, [value, filteredFieldsById]);

      return (
        <div className="p-2 bg-gray-200 border rounded">
          <select
            name={field.displayname}
            id={field.id}
            required={field.required}
            value={dynamicfieldvalueentity}
            onChange={(e) => {
              onChange(e);
              setDynamicFieldValueEntity(e.target.value);
            }}
            className={`ti-form-select ${error ? 'border-red-500' : ''}`}
          >
            <option value="">{field.placeholder}</option>
            {menuuserItems.map((menuuserItems) => (
              <option
                key={menuuserItems._id as string}
                value={menuuserItems._id as string}
              >
                {menuuserItems.name}
              </option>
            ))}
          </select>
        </div>
      );
    }
  } else if (field?.selectType === 'MultipleSelect') {
    useEffect(() => {
      const matchingField = filteredFieldsById.find(
        (filteredField) => filteredField.formId === value
      );
      if (matchingField) {
        setDynamicFieldValueMultiple([matchingField.formId || '']);
      }
    }, [value, filteredFieldsById]);

    const handleMultipleSelectChange = (
      e: React.ChangeEvent<HTMLSelectElement>
    ) => {
      const selectedValues = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setDynamicFieldValueMultiple(selectedValues);
      onChange(e);
    };

    return (
      <div className="p-2 border rounded">
        <select
          name={field.displayname}
          id={field.id}
          required={field.required}
          value={dynamicfieldvaluemultiple}
          multiple
          onChange={handleMultipleSelectChange}
          className={`ti-form-select ${
            error ? 'border-red-500' : ''
          }`}
        >
          <option value="">{field.placeholder}</option>
          {filteredFieldsById.map((filteredFieldItem) => (
            <option
              key={filteredFieldItem.formId}
              value={filteredFieldItem.formId}
            >
              {filteredFieldItem.value}
            </option>
          ))}
        </select>
      </div>
    );
  } else {
    console.log('selectType is not defined');
  }
});
