import React, { useEffect, useState } from 'react';
import { BarChartProps, Field, Form, Section } from '../types';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import BarChartInput from './BarChartInput';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

export const BarGraph = ({ field, value, error, onChange }: BarChartProps) => {
  return (
    <>
      {field.selectType === 'Dynamic' && (
        <BarChart
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      )}
      {field.selectType === 'Static' && (
        <BarChartInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      )}
    </>
  );
};

export const BarChart = ({ field, value, error, onChange }: BarChartProps) => {
  const [dynamicData, setDynamicData] = useState<Form[]>([]);
  const [allSections, setAllSections] = useState<Section[]>([]);
  const [filteredFields, setFilteredFields] = useState<Field[]>([]);
  const [filteredFieldsByLabelId, setFilteredFieldsByLabelId] = useState<
    Field[]
  >([]);
  const [filteredFieldsByValueId, setFilteredFieldsByValueId] = useState<
    Field[]
  >([]);
  const [valData, setValData] = useState<number[]>([]);
  const [labelData, setLabelData] = useState<(string | undefined)[]>([]);
  const [barLabel, setBarLabel] = useState<string>('');
  const authtoken = localStorage.getItem('token');

  const getFormData = async () => {
    try {
      // const response = await axios.post(`${BACKEND.BACKEND_API}}/getformsvaluesbyid`, {
      //   formId: field.formid,
      // });
      const response = await axios.post(
        `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
        { formId: field.formid },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );
      const data: Form[] = response.data;
      setDynamicData(data);

      const extractedSections = data.flatMap((form: Form) =>
        form.sections.map((section) => ({
          ...section,
          formId: form._id,
        }))
      );
      setAllSections(extractedSections);

      const extractsectionidwise = extractedSections.filter(
        (section) => section.id === field.sectionid
      );
      const fieldsFromSection = extractsectionidwise.flatMap((section) =>
        section.fields.map((fieldItem) => ({
          ...fieldItem,
          formId: section.formId,
        }))
      );
      setFilteredFields(fieldsFromSection);

      const filteredDataByLabelId = fieldsFromSection.filter(
        (fieldItem) => fieldItem.id === field.barLabelId
      );
      setFilteredFieldsByLabelId(filteredDataByLabelId);

      const barXdata = filteredDataByLabelId.map((data) => data.value);

      const filteredDataByValueId = fieldsFromSection.filter(
        (fieldItem) => fieldItem.id === field.barValId?.toString()
      );
      setFilteredFieldsByValueId(filteredDataByValueId);
      setBarLabel(filteredDataByValueId?.[0].label);

      const barYdata = filteredDataByValueId.map((data) => data.value);
      const arr = barYdata.map((val) => Number(val));
      setValData(arr);
      setLabelData(barXdata);
    } catch (err) {
      console.error('Error fetching form data:', err);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  const data = {
    labels: labelData === undefined ? [''] : labelData.map((data) => data),
    datasets: [
      {
        label: barLabel || 'Data',
        data: valData === undefined ? [0] : valData.map((data) => data),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return tooltipItem.label + ': ' + tooltipItem.raw;
          },
        },
      },
    },
  };

  return (
    // <div className="w-full max-w-xl mx-auto bg-white p-2 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
    <div className="w-full ml-4 bg-white p-6 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
      <h2 className="text-2xl font-semibold text-center mb-4">{field.label}</h2>
      <div className="relative" style={{ height: '400px' }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChartInput;
