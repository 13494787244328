import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { LineChartProps, PieChartProps } from '../types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ field, value, error, onChange }: LineChartProps) => {
 
  const label = field.lineChart ? field.lineChart.map((data : any)=>data.label) : [""];
  const values= field.lineChart ? field.lineChart.map((data)=> Number(data.value)) : [0]

  const data = {
    labels:  label, 
    datasets: [
      {
        label: `${field.label}`,
        data: values, 
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.4, 
      },
    ],
  };

  // Chart options
  const options: any = {
    responsive: true, 
    maintainAspectRatio: false, 
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.raw}`; 
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: '',
        },
      },
      y: {
        title: {
          display: true,
          text: '',
        },
        beginAtZero: true,
      },
    },
  };

  return (
        <div className="w-full max-w-xl mx-auto bg-white p-6 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
        <h2 className="text-2xl font-semibold text-center mb-4">{field.label}</h2>
        <div className="relative" style={{ height: '400px' }}>
        <Line data={data} options={options} />
        </div>
        </div>
  );
};

export default LineChart;
