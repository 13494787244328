import React from 'react';
import { NumberInputProps } from '../types';

export const NumberInput = ({
  field,
  value,
  error,
  onChange,
}: NumberInputProps) => {
  return (
    <div className="p-2   rounded">
      <input
        type="phone"
        id={field.id}
        placeholder={field.placeholder}
        required={field.required}
        value={value}
        onChange={onChange}
        className={`ti-form-input ${error ? 'border-red-500' : ''}`}
        pattern="[0-9]*"
      />
    </div>
  );
};
