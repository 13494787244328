// import React from 'react';
// import { SwitchInputProps } from '../types';

// export const Toggleswitch = ({ field, onChange }: SwitchInputProps) => {
//   const isChecked = value === field.checkedValue;

//   return (
//     <label className="inline-flex items-center mt-4 cursor-pointer">
//       <span className='mr-4' >{field.uncheckedValue}</span>
//       <input
//         type="checkbox"
//         className="sr-only peer"
//         id={field.id}
//         checked={isChecked}
//         onChange={(e) => {
//           const checkedValue = e.target.checked ? field.checkedValue : field.uncheckedValue;
//           handleInputChange(sectionId, field.id, checkedValue, 'switch');
//         }}
//       />
//       <div className="relative h-6 transition-colors duration-200 ease-in-out bg-gray-300 rounded-full w-11 peer-checked:bg-blue-600">
//         <span
//           className={`absolute left-0.5 top-0.5 h-5 w-5 rounded-full bg-white shadow transition-transform duration-300 ease-in-out ${isChecked ? 'translate-x-5' : 'translate-x-0'}`}
//         />
//       </div>
//       <span className='ml-4'>{field.checkedValue}</span>
//       {/* <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">{field.label}</span> */}
//     </label>
//   );

// };

import React from 'react';
import { ToggleSwitchProps } from '../types';


const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ field, sectionId, value, onChange }) => {
  const isChecked = value === (field.checkedValue || 'Checked');

  return (
    <div className="p-2 border rounded" >
      <label className="inline-flex items-center mt-4 cursor-pointer">
        <span className="mr-4">{field.uncheckedValue || 'Unchecked'}</span>
        <input
          type="checkbox"
          className="sr-only peer"
          id={field.id}
          checked={isChecked}
          onChange={(e) => {
            const checkedValue = e.target.checked
              ? field.checkedValue || 'Checked'
              : field.uncheckedValue || 'Unchecked';
            onChange(sectionId, field.id, checkedValue);
          }}
        />
        <div className="relative h-6 transition-colors duration-200 ease-in-out bg-gray-300 rounded-full w-11 peer-checked:bg-blue-600">
          <span
            className={`absolute left-0.5 top-0.5 h-5 w-5 rounded-full bg-white shadow transition-transform duration-300 ease-in-out ${isChecked ? 'translate-x-5' : 'translate-x-0'
              }`}
          />
        </div>
        <span className="ml-4">{field.checkedValue || 'Checked'}</span>
      </label>
    </div>
  );
};

export default ToggleSwitch;
