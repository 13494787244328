// Card.tsx
import React, { useEffect } from 'react';
import { FaAndroid, FaApple, FaBeer, FaCoffee } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

type IconName = 'FaBeer' | 'FaCoffee' | 'FaApple' | 'FaAndroid';

type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface CustomCardProps {
  title: string;
  subtitle: string;
  icon: IconName;
  iconSize: IconSize;
  bgColor: string;
  textColor: string;
  width: string;
  formId: string;
}

const iconSizeMap: Record<IconSize, number> = {
  xs: 16,
  sm: 24,
  md: 32,
  lg: 48,
  xl: 64,
};

const iconMap: Record<IconName, React.ReactNode> = {
  FaBeer: <FaBeer />,
  FaCoffee: <FaCoffee />,
  FaApple: <FaApple />,
  FaAndroid: <FaAndroid />,
};

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  subtitle,
  icon,
  iconSize,
  bgColor,
  textColor,
  width,
  formId  
}) => {
  const icons = iconMap[icon];


  // console.log(bgColor,textColor,width,'321')
  useEffect(() => {
    
  },[bgColor])

  const navigate = useNavigate();

  return (
    <> 
    {/* <div
      className={`p-4 rounded-lg shadow-2xl w-1/2 bg-gradient-to-r from-blue-500 to-curious-blue-600 hover:shadow-2xl hover:from-blue-600 hover:to-curious-blue-700 transition duration-300 ease-in-out`}
      style={{width}}
    >
      <div className="flex items-center mb-4 ">
        <div style={{ fontSize: iconSizeMap[iconSize] }} className="bg-white rounded-full p-2">
          {icons}
        </div>
        <div className="ml-4">
          <h3 className="text-2xl font-roboto font-semibold cursor-pointer " onClick={() => console.log('Title clicked')}>
            {title}
          </h3>
          <hr className='border-black' style={{borderWidth: '2px'}} />
          <p className="text-lg ml-2 cursor-pointer font-roboto" onClick={() => console.log('Subtitle clicked')}>
            {subtitle}
          </p>
        </div>
      </div>
    </div> */}
   <div className="group relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10 flex flex-col">
                 <span className="absolute top-10 z-0 h-1 w-1 rounded-full bg-gray-50 transition-all duration-300 group-hover:bg-sky-600 group-hover:scale-[200]"></span>
                 <div className="relative z-10 mx-auto max-w-md">
                     <span className="grid h-20 w-20 place-items-center rounded-full bg-sky-500 transition-all duration-300 group-hover:from-purple-500 group-hover:to-blue-400">
                         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-10 w-10 text-white transition-all">
                             <path stroke-linecap="round" stroke-linejoin="round" d="M12 3.75L16.5 8.25L12 12.75M12 8.25L7.5 12.75M12 8.25V21" />
                         </svg>
                     </span>
                     <div
                         className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                         <h4 
                           onClick={() => navigate(`/test/form/${formId}`)}
                          >{title}   &rarr;</h4>
                         {/* {selectedOrg === org._id ? 'Selected' : 'Click to Open'} */}
                     </div>
                     <hr/>
                     <div className=" text-base font-semibold leading-7">
                         <p
                          onClick={() => navigate(`/test/list/${formId}`)}
                          >
                             <a   className="text-sky-500 transition-all duration-300 group-hover:text-white">{  subtitle}
                                 &rarr;
                             </a>
                         </p>
                     </div>
                 </div>
             </div> </>
  );
};

export default CustomCard;


