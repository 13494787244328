import React, { useEffect, useState } from 'react';
import { Form, TextAreaInputProps } from '../types';
import axios from 'axios';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};
export const TextAreaInput = ({
  field,
  value,
  error,
  onChange,
  dependentFormData,
  selectedFirst,
  setSelectedFirst,
}: TextAreaInputProps) => {
  const authtoken = localStorage.getItem('token');
  const prepopulatedValue = selectedFirst[`${field.parentField}`];
  const [selectedValue, setSelectedValue] = useState<string | undefined>('');
  const [isreadOnly, setIsreadOnly] = useState(false);
  const getInputData = async () => {
    const dependentFormDataobj: { [key: string]: any } = dependentFormData;
    if (dependentFormDataobj['isDependent'] && field.markAsAutoPopulate) {
      const response = await axios.post(
        `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
        {
          parentFieldId: field.parentField,
          parentFieldValue: prepopulatedValue,
        },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      const data: Form[] = response.data;
      const extractedSections = data.flatMap((form: Form) =>
        form.sections.map((section) => ({
          ...section,
          formId: form._id,
        }))
      );
      if (extractedSections.length > 0) {
        extractedSections.forEach((section) => {
          section.fields.forEach((fieldData) => {
            if (field.label === fieldData.label) {
              setSelectedValue(fieldData.value);
              const FieldsData = {
                [field.id]: fieldData.value,
              };

              setSelectedFirst((prev) => ({
                ...prev,
                inputData: {
                  ...prev.inputData,
                  [field.sectionId]: {
                    ...(prev.inputData?.[field.sectionId] ?? {}),
                    ...FieldsData,
                  },
                },
              }));
            }
          });
        });
        setIsreadOnly(true);
      } else {
        setSelectedValue('');
        setIsreadOnly(true);
      }
    } else {
      setSelectedValue('');
      setIsreadOnly(false);
    }
  };
  useEffect(() => {
    if (prepopulatedValue !== '' && prepopulatedValue != undefined) {
      getInputData();
    }
  }, [prepopulatedValue]);
  return (
    <div className="p-2 rounded">
      <textarea
        id={field.id}
        placeholder={field.placeholder}
        required={field.required}
        value={field.markAsAutoPopulate ? selectedValue : value}
        onChange={onChange}
        readOnly={isreadOnly}
        className={`ti-form-input ${error ? 'border-red-500' : ''} ${
          field.markAsAutoPopulate &&
          'cursor-pointer outline-none select-none focus:outline-none focus:ring-0 focus:border-gray-300 active:border-gray-300 bg-gray-100'
        }`}
        rows={1}
      />
    </div>
  );
};
