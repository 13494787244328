// const jwtDecode = require('jwt-decode');
// import CryptoJS from 'crypto-js';


// export const Decode = () => {
//   const token = localStorage.getItem('token');

//   if (!token) {
//     console.error('No token found in localStorage');
//     return;
//   }

//   try {
//     // Decode the JWT token to extract the encrypted payload
//     const decoded: any = jwtDecode(token);

//     // Get the encrypted data from the token's payload
//     const encryptedData = decoded.data;

//     // Decrypt the encrypted data using the same secret key used on the backend
//     const secretKey = process.env.REACT_APP_ENCRYPTION_KEY || 'mySecretEncryptionKey';
//     const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
//     const decryptedPayload = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

//     console.log('Decrypted Payload:', decryptedPayload);

//     return decryptedPayload;
//   } catch (error: any) {
//     console.error('Error decoding or decrypting token:', error.message);
//   }
// };

const jwtDecode = require('jwt-decode');
import CryptoJS from 'crypto-js';

export const Decode = () => {
  const token = localStorage.getItem('token'); 

  if (!token) {
    console.error('No token found in localStorage');
    return;
  }

  try {
    
    const decoded: any = jwtDecode(token); 

 
    const encryptedData = decoded.data;

    if (!encryptedData) {
      console.error('No encrypted data found in the token');
      return;
    }


    const secretKey = process.env.ENCRYPTION_KEY || 'mySecretEncryptionKey';
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedPayload = JSON.parse(bytes.toString(CryptoJS.enc.Utf8)); 

    // console.log('Decrypted Payload:', decryptedPayload); 
    return decryptedPayload;
  } catch (error: any) {
    console.error('Error decoding or decrypting token:', error.message); 
  }
};
