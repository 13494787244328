import React, { useEffect } from 'react';

export const GoogleTranslate = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
        includedLanguages: 'en,hi,gu,te',
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google_translate_element'
    );
  };

  useEffect(() => {
    // Google Translate script dynamically add karna
    var addScript = document.createElement('script');
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    // ✅ **Fix: Google Translate jo `<body>` ke andar extra style add karta hai, usko remove karega**
    const styleFixer = setInterval(() => {
      if (
        document.body.style.position === 'relative' &&
        document.body.style.top === '40px'
      ) {
        console.log('✅ Removing unwanted styles from <body>...');
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.minHeight = '';
      }
    }, 100); // Har 100ms check karega

    return () => {
      // ✅ Cleanup: setInterval ko band karna
      clearInterval(styleFixer);

      // ✅ Cleanup: Google Translate script ko remove karna
      const scriptTag = document.querySelector(
        'script[src*="translate_a/element.js"]'
      );
      if (scriptTag) {
        scriptTag.remove();
        window.googleTranslateElementInit = () => {};
      }
    };
  }, []);

  return (
    <div>
      {/* Google Translate dropdown yahan render hoga */}
      <div id="google_translate_element" className="border rounded"></div>
    </div>
  );
};

export default GoogleTranslate;
