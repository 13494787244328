import {AiOutlineArrowRight } from 'react-icons/ai';
import React from 'react';
import { BarChartProps, IconCardProps } from '../types';
import * as FaIcons from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
import * as AiIcons from 'react-icons/ai';
import * as FiIcons from 'react-icons/fi';
import { IconType } from 'react-icons/lib';
const allIcons = {
    ...AiIcons,
    ...FaIcons,
    ...MdIcons,
    ...IoIcons,
    ...GiIcons,
    ...FiIcons,
  };
export const IconCard = ({field} :  IconCardProps) => {
   
    
    const card =  field.iconCard ? field.iconCard.map((card:any, index)=>({
        ...card,
        icon : card.iconName,
        SelectedIcon : allIcons[card.iconName as keyof IconType],   
        textColor:  'text-' + card.bgColor.split('-')[1] + '-600'
    }
)) : [];

  console.log(card,".........card");
   return (
    <div className="grid grid-cols-2 gap-4 max-w-lg mx-auto p-4">

     {card.map((card)=>(
        <div
        className={`p-6 rounded-lg shadow-md flex justify-between items-center  ${card.bgColor}`}
      >
        <div>
          <h3 className={`text-lg font-medium ${card.textColor}`}>{card.title}</h3>
          <div className={`flex items-center ${card.textColor} text-3xl font-bold`}>
            {card.value}
            <AiOutlineArrowRight className={`ml-2 ${card.textColor} text-xl`} />
          </div>
        </div>
        <div className={`text-4xl ${card.textColor}`}>{<card.SelectedIcon/>}</div>
      </div>
     ))}

     { card.length===0 && 
      <div className="text-lg font-bold font-roboto text-gray-900">No records found !</div>
     }
    </div>
  );
}

