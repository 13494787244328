import React from "react";
import { useState, useEffect } from "react";
import { WebsiteProps } from "../types";




export const Website: React.FC<WebsiteProps> = ({ field, error, onChange, value}) => {
  
  const [url, setUrl] = useState<string>(value || "");
  const [urlError, setUrlError] = useState<string>(error ? String(error) : "");

  useEffect(() => {
    setUrl(value || "");
  }, [value]);
  const validateUrl = (value: string) => {
   
    const urlPattern = /^(https?:\/\/|www\.)[\w-]+(\.[\w-]+)+([\w-.,@?^=%&:/~+#]*[\w-@?^=%&/~+#])?$/;

    if (!value.match(urlPattern)) {
      setUrlError("Invalid URL! It must start with 'http://', 'https://', or 'www.'");
    } else {
      setUrlError("");
      
    };
    
    setUrl(value);
    onChange({ target: { value } } as React.ChangeEvent<HTMLInputElement>);

  };

  return (
    <div className="p-6 max-w-md mx-auto bg-white shadow-lg rounded-2xl">
      <label className="block text-lg font-semibold text-gray-700 mb-2">
        Enter Website URL:
      </label>
      <input
        type="text"
        value={url}
        onChange={(e) => validateUrl(e.target.value)}
        id={field.id}
        required={field.required}
        // onChange={onChange}
        className={`w-full ${error ? 'p-3 border border-red-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500' : ''}`}
  
        placeholder="https://example.com"
      />
      {urlError && <p className="text-red-500 mt-2 text-sm">{urlError}</p>}
    </div>
  );
}

