import React from 'react';
import { FileInputProps } from '../types';

export const FileInput = ({ field, error, onChange, value, mode }: FileInputProps) => {
   const accept = field?.fileType?.join(", ");

  return (
    <>
    <div className="p-2 border rounded" >
      {field.multiple ?
      <input
        type="file"
        id={field.id}
        required={field.required}
        onChange={onChange}
        multiple
        className={`w-full ${error ? 'border-red-500' : ''}`}
      />
      :
      <input
        type="file"
        id={field.id}
        required={field.required}
        onChange={onChange}
        accept= { accept}
        className={`w-full ${error ? 'border-red-500' : ''}`}
      />
      }
    </div>
    { mode &&
    <div className="grid grid-cols-3 gap-4 p-4">
    {value && (
      <img src={`${value}`} alt="Uploaded File" width="200" className="w-full" />
    )}
    {
      field.fileUrls && field.fileUrls.length > 0 && (
        field.fileUrls.map((url, index) => (
          <img src={url} alt="Uploaded File" width="200" className="w-full"/>
        ))
      )
    }
    </div>
    }
    </>
  );
};
