import axios from "axios";
import { toast } from "react-toastify";
import { FormJSON, Section } from "../types";

export interface UpdatedForm extends FormJSON {
  userId ?: string;
}


const BACKEND={
    BACKEND_API:process.env.BACKEND_API,
    PLUGIN_BACKEND_API: process.env.PLUGIN_BACKEND_API
  }

const renderToaster =   (updatedFormData: UpdatedForm, status: string[], formIdd: string | undefined, message: string, responseId: string, username:any) => {

  const authtoken = localStorage.getItem('token');
    const linkedField = updatedFormData.sections.map((section: any) => {
      return section.fields
          .filter((field: any) => field.type === 'select') 
          .map((field: any) => field.value); 
    });
    linkedField[0].push(responseId);
    linkedField.forEach(async(forms : any) =>  {
      forms.forEach((formIDD : any)=>{
      status.forEach(async(desc:any)=>{
        
        let formData = {
          _id: "675d187a114d0118ef74265f",
          timelineFormId: formIDD,
          formTitle: "Demo Data ",
          formIdd: responseId,
          sections: [
            {
              id: "979bde0f-17e7-4ec7-8969-ee8f69e8804b",
              fields: [
                {
                  type: "text",
                  sectionId: "979bde0f-17e7-4ec7-8969-ee8f69e8804b",
                  id: "3668b13c-051e-4ccf-8428-c6b85b58ab2d",
                  label: "Description",
                  name: "",
                  value: desc,
                  placeholder: "",
                  required: false,
                  deviedation: "1/1",
                  options: [
                    {
                      value: "",
                      label: ""
                    }
                  ],
                  width: 720,
                  height: 80,
                  x: 2,
                  y: 270,
                },
                {
                  type: "text",
                  sectionId: "979bde0f-17e7-4ec7-8969-ee8f69e8804b",
                  id: "c08b0934-faf2-467d-ace5-9a6fbd8cc15a",
                  label: "Title",
                  name: "",
                  value: updatedFormData.formTitle,
                  placeholder: "",
                  required: true,
                  deviedation: "1/1",
                  options: [
                    {
                      value: "",
                      label: ""
                    }
                  ],
                  width: 720,
                  height: 80,
                  x: 2,
                  y: 180,
                  isReadOnly: true
                },
                {
                  type: "text",
                  sectionId: "979bde0f-17e7-4ec7-8969-ee8f69e8804b",
                  id: "363e1c24-9d62-4d53-93a6-0680e9555ff3",
                  label: "User",
                  name: "",
                  value: username,
                  placeholder: "Enter User",
                  required: false,
                  deviedation: "1/1",
                  options: [
                    {
                      value: "",
                      label: ""
                    }
                  ],
                  x: 2.000030517578125,
                  y: 359.56944274902344,
                  width: 720,
                  height: 80
                },
                {
                  type: "dateTime",
                  sectionId: "979bde0f-17e7-4ec7-8969-ee8f69e8804b",
                  id: "d07a18cb-20b9-461a-9f78-0e7ba337e1d6",
                  label: "date",
                  name: "",
                  value: new Date().toLocaleString("en-IN", { 
                          timeZone: "Asia/Kolkata",
                          hour12: true, 
                          hour: '2-digit', 
                          minute: '2-digit', 
                          day: '2-digit', 
                          month: '2-digit', 
                          year: 'numeric'
                  }),
                  placeholder: "",
                  required: false,
                  deviedation: "1/1",
                  options: [
                    {
                      value: "",
                      label: ""
                    }
                  ],
                  x: 2,
                  y: 89.99998474121094,
                  width: 710,
                  height: 80
                }
              ],
              x: 10,
              y: 54.00001525878906,
              width: 1010,
              height: 600,
              name: "1"
            }
          ],
          orgId: "6718dc80389c94cadeec9e55",
          moduleId: "671f6b42359fa902c380f542",
          formtype: [
            "67614c3f6fb46c5eee08f218",
            "6731eb9b1d47fae8ef5b4294"
          ],
          plugins: [
            {
              _id: "67690e7cad196ab624d0ac47",
              formTitle: "Flat Data Plugin",
              sections: [
                {
                  id: "a8986274-0c09-42ce-80fd-2576289316dc",
                  fields: [
                    {
                      type: "text",
                      sectionId: "a8986274-0c09-42ce-80fd-2576289316dc",
                      id: "9c7340da-56c7-4888-b8b7-0ac62618e7d1",
                      label: "Config Name",
                      name: "",
                      value: "timeline",
                      placeholder: "Enter Config Name",
                      required: true,
                      deviedation: "1/1",
                      options: [
                        {
                          value: "",
                          label: ""
                        }
                      ],
                      x: 0.000030517578125,
                      y: 90.00009155273438,
                      width: 1250,
                      height: 100
                    },
                    {
                      type: "text",
                      sectionId: "a8986274-0c09-42ce-80fd-2576289316dc",
                      id: "b547e327-357a-42b5-9f2b-ba0f0939126e",
                      label: "PluginID",
                      name: "",
                      value: "6763be2de3d5ca3d74981b2a",
                      placeholder: "PluginID",
                      required: true,
                      deviedation: "1/1",
                      options: [
                        {
                          value: "",
                          label: ""
                        }
                      ],
                      x: 0.000030517578125,
                      y: 200.00009155273438,
                      width: 1250,
                      height: 100
                    }
                  ],
                  name: "PluginInfo",
                  x: 0,
                  y: 0,
                  width: 1370,
                  height: 360
                }
              ],
              formId: "6763be8de3d5ca3d74981b2b",
              userId: "672c58d2b4a5e6a223d1f2ac",
              selectedPluginId: "6763be2de3d5ca3d74981b2a"
            }
          ],
          userId:updatedFormData.userId,
        };

        const result = await axios.post(
                `${BACKEND.PLUGIN_BACKEND_API}/plugin/flatpluginvalueinsert`,
                {...formData,flatCopyId: formIDD},
                {
                  headers: {
                    Authorization: `Bearer ${authtoken}`,
                  },
                }
              );
        })
      })
    })

    

}

export default renderToaster;