import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import LoginPage from '../LoginPage';
import Main from './Main';
import ViewMode from './ViewMode';
import ListMain from './ListMain';
import Plugin from './PluginMenu';
import Module from './Modules';
import Layout from './Layout';
import Test from '../Test';

import { ProtectedRoute, AuthProvider } from '@empire/components/atoms';

import AutoLogin from './AutoLogin';
import Header from './Header';
import ImportData from './ImportData';
import ImportStatus from './ImportStatus';

function App() {
  const location = useLocation();
  const isLoginRoute = location.pathname === '/';

  return (
    <div>
      <AuthProvider>
        {!isLoginRoute && <Header />}

        <Routes>
          <Route path="/" element={<LoginPage />} />

          <Route
            path="/plugin/*"
            element={
              <ProtectedRoute>
                <Plugin />
              </ProtectedRoute>
            }
          />

          <Route path="/auto-login/" element={<AutoLogin />} />

          <Route path="/" element={<Layout />}>
            <Route path="/test/publiclist/:formId" element={<ViewMode />} />
            <Route path="/test/list/:formId" element={<ListMain />} />

            <Route
              path="/main/:id"
              element={
                <ProtectedRoute>
                  <Test />
                </ProtectedRoute>
              }
            />

            <Route
              path="/modules"
              element={
                <ProtectedRoute>
                  <Module />
                </ProtectedRoute>
              }
            />

            <Route
              path="/test/form/:formId"
              element={
                <ProtectedRoute>
                  <Main />
                </ProtectedRoute>
              }
            />

            <Route
              path="/test/view/:formId"
              element={
                <ProtectedRoute>
                  <ViewMode />
                </ProtectedRoute>
              }
            />

            <Route
              path="/test/list/:formId"
              element={
                <ProtectedRoute>
                  <ListMain />
                </ProtectedRoute>
              }
            />

            <Route
              path="/import-data"
              element={
                <ProtectedRoute>
                  <ImportData />
                </ProtectedRoute>
              }
            />

            <Route
              path="/import-status"
              element={
                <ProtectedRoute>
                  <ImportStatus />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
