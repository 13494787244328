import React, { useState, useEffect } from 'react';
import { Form, TextInputProps } from '../types';
import axios from 'axios';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

export const TextInput = React.memo(({
  field,
  value,
  error,
  onChange,
  isReadOnly,
  dependentFormData,
  selectedFirst,
  setSelectedFirst,
  formValuesUpdated,
  setFormValuesUpdated,
}: TextInputProps) => {
  const authtoken = localStorage.getItem('token');
  const prepopulatedValue = selectedFirst?.[`${field.parentFormSectionId}`]?.[`${field.parentFormFieldId}`]?.['value'];
  const [selectedValue, setSelectedValue] = useState<string | undefined>('');
  const [isreadOnly, setIsreadOnly] = useState(isReadOnly);
  const getInputData = async () => {
    const dependentFormDataobj: { [key: string]: any } = dependentFormData;
    if (field.markAsAutoPopulate) {
      const response = await axios.post(
        `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
        {
//           parentFieldValue: value !== "" ? value : prepopulatedValue,
          parentFieldValue: prepopulatedValue,
          markAsDependent: field.markAsDependent
        },
        {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        }
      );

      const data: Form[] = response.data;
      const extractedSections = data.flatMap((form: Form) =>
        form.sections.map((section) => ({
          ...section,
          formId: form._id,
        }))
      );
      if (extractedSections.length > 0) {
        extractedSections.forEach((section) => {
          section.fields.forEach((fieldData) => {
            if (fieldData.label.trim() === field.label.trim()) {
              setSelectedValue(fieldData.dummyvalue ?? fieldData.value);
//               const FieldsData = {
//                 [field.id]: fieldData.value,
//               };
//               setSelectedFirst((prev) => ({
//                 ...prev,
//                 inputData: {
//                   ...prev.inputData,
//                   [field.sectionId]: {
//                     ...(prev.inputData?.[field.sectionId] ?? {}),
//                     ...FieldsData,
//                   },
//                 },
//               }));
              setSelectedFirst((prev) => ({
                ...prev,
                [field.sectionId]: {
                    ...(prev[field.sectionId] ?? {}),
                    [field.id] : {
                     id: fieldData.dummyvalue ?? fieldData.value,
                     value: fieldData.dummyvalue ?? fieldData.value
                   }
                },
              }));
              setFormValuesUpdated((prevValues: any) => {
                const newState = {
                  ...prevValues,
                  [field.sectionId]: {
                    ...(prevValues[field.sectionId] || {}),
                    [field.id]: fieldData.value,
                  },
                };
                return newState;
              });
            }
          });
        });
        setIsreadOnly(true);
      } else {
        setSelectedValue('');
        setIsreadOnly(true);
      }
    } else {
      setSelectedValue('');
      setIsreadOnly(false);
    }
  };
  useEffect(()=>{
    if (value !== '' && value != undefined) {
      getInputData()
      setSelectedFirst((prev) => ({
        ...prev,
        [field.sectionId]: {
            ...(prev[field.sectionId] ?? {}),
            [field.id] : {
             id: value,
             value: value
           }
        },
      }));
    }
  },[value])
  useEffect(() => {
    if (prepopulatedValue !== '' && prepopulatedValue != undefined) {
      getInputData();
    } else {
      setSelectedValue('');
      setFormValuesUpdated((prevValues: any) => {
        const newState = {
          ...prevValues,
          [field.sectionId]: {
            ...(prevValues[field.sectionId] || {}),
            [field.id]: '',
          },
        };
        return newState;
      });
    }
  }, [prepopulatedValue]);

  return (
    <div className="p-2  rounded py-2">
      <input
        type="text"
        id={field.id}
        placeholder={field.placeholder}
        required={field.required}
        value={field.markAsAutoPopulate ? selectedValue : value}
        readOnly={isreadOnly}
        onChange={onChange}
        className={`my-auto ti-form-input ${error ? 'border-red-500' : ''} ${
          field.markAsAutoPopulate &&
          'cursor-pointer outline-none select-none focus:outline-none focus:ring-0 focus:border-gray-300 active:border-gray-300 bg-gray-100'
        }`}
      />
    </div>
  );
});
