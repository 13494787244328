import React, { useEffect, useState } from 'react';
import { FormRenderer } from 'libs/components/organisms/FormRenderer';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useFormStore } from '@empire/components/organisms';
import Loader from '../../../libs/components/molecules/Loader';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

const RenderForm: React.FC = () => {
  const [formConfig, setFormConfig] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { formId } = useParams();
  const location = useLocation();
  const authtoken = localStorage.getItem('token');
  const editmode =
    location.state && location.state.mode ? location.state.mode : 'view';
  const { userConfig, setUserConfig } = useFormStore();
  const moduleId = useParams().id;

  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        });
        const data = await response.data;
        setUserConfig(data);
      } catch (error) {
        console.error('Error fetching form configuration:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFormConfig();
  }, []);

  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        if (editmode === 'editfields') {
          // const response = await axios.get(`${BACKEND.BACKEND_API}/value/${formId}`);
          const response = await axios.get(
            `${BACKEND.BACKEND_API}/value/${formId}`,
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );
          setFormConfig(response.data);
        } else {
          const response = await axios.post(
            `${BACKEND.BACKEND_API}/other/getdashboard`,
            {
              id: '6731eb931d47fae8ef5b4210',
              moduleId: moduleId,
            }
          );
          setFormConfig({ ...response.data, isDashboard: true });
        }
      } catch (error) {
        console.error('Error fetching form configuration:', error);
        // setError('Failed to load form configuration.');
      } finally {
        setLoading(false);
      }
    };

    fetchFormConfig();
  }, [formId]);

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (error) return <div>{error}</div>;

  return (
    <div className="py-1">
      <div className="">
        <main className="font-bold">
          {formConfig ? (
            <FormRenderer formData={formConfig} />
          ) : (
            <div className="text-xl font-bold">Welcome</div>
          )}
        </main>
      </div>
    </div>
  );
};

export default RenderForm;
