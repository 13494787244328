import React, { useState } from 'react';
import { GroupField, InputFieldProps } from '../types';

import {
  AddressInput,
  BarGraph,
  DateInput,
  FileInput,
  Heading,
  LineGraph,
  NumberInput,
  ParaGraph,
  PieGraph,
  Radio,
  SelectInput,
  TextAreaInput,
  TextInput,
  TimeInput,
  DonutGraph,
  TabBar,
  TextCard,
  IconCard,
  Header,
  SearchBar,
  BottomSidebar,
  Timeline,
  DateTimeInput,
  TodoCard,
  Email,
  SignaturePad,
  MultiSelectInput,
  FullNameInput,
  DrawingPad,
  Website,
  AmBarChart,
  AmPieChart,
  AmDonutChart,
  Calendar,
  DataGrid,
} from '../molecules';

import { MonthYearPicker } from '../molecules/MonthYearPicker';

import { TermsCondition } from '../molecules/TermandCondition';
// import { Calendar } from '@fullcalendar/core/index.js';

export const InputField = ({
  field,
  sectionId,
  value,
  error,
  onChange,
  isReadOnly,
  dummyvalue,
  dependentFormData,
  selectedFirst,
  setSelectedFirst,
  formValuesUpdated,
  setFormValuesUpdated,
  mode,
}: InputFieldProps) => {
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({});
  const [signaturesave, setSignaturesave] = useState<boolean>(false);
  const [drawingsave, setDrawingsave] = useState<boolean>(false);
  const addressField = field as GroupField;

  const fullnameField = field as GroupField;

  const handleAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    subFieldId: string
  ) => {
    setSignaturesave(true);
    setDrawingsave(true);
    const { value } = e.target;

    setFormValues((prevValues: any) => ({
      ...prevValues,
      [subFieldId]: value,
    }));
  };

  // console.log(onChange,'fieldfonChangeonChangeield');

  switch (field.type) {
    case 'text': {
      return (
        <TextInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
          isReadOnly={isReadOnly}
          dependentFormData={dependentFormData}
          selectedFirst={selectedFirst}
          setSelectedFirst={setSelectedFirst}
          formValuesUpdated={formValuesUpdated}
          setFormValuesUpdated={setFormValuesUpdated}
        />
      );
    }
    case 'email': {
      return (
        <Email
          field={field}
          value={value}
          error={error}
          onChange={onChange}
          isReadOnly={isReadOnly}
          dependentFormData={dependentFormData}
          selectedFirst={selectedFirst}
          setSelectedFirst={setSelectedFirst}
          formValuesUpdated={formValuesUpdated}
          setFormValuesUpdated={setFormValuesUpdated}
        />
      );
    }
    case 'phone': {
      return (
        <NumberInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'textarea': {
      return (
        <TextAreaInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
          dependentFormData={dependentFormData}
          selectedFirst={selectedFirst}
          setSelectedFirst={setSelectedFirst}
        />
      );
    }
    case 'select': {
      return (
        <SelectInput
          dummyvalue={dummyvalue}
          dependentFormData={dependentFormData}
          selectedFirst={selectedFirst}
          setSelectedFirst={setSelectedFirst}
          mode={mode}
          formValuesUpdated={formValuesUpdated}
          setFormValuesUpdated={setFormValuesUpdated}
          field={field}
          value={value}
          error={error}
          onChange={onChange}
          _id={
            typeof field._id === 'string' ? field._id : field._id?.$oid ?? ''
          }
          formTitle={field.formTitle ?? ''}
        />
      );
    }
    case 'multiselect': {
      return (
        <MultiSelectInput
          dummyvalue={dummyvalue}
          field={field}
          value={value}
          error={error}
          onChange={onChange}
          _id={
            typeof field._id === 'string' ? field._id : field._id?.$oid ?? ''
          }
          formTitle={field.formTitle ?? ''}
        />
      );
    }
    case 'file': {
      return (
        <FileInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
          mode={mode}
        />
      );
    }
    case 'time': {
      return (
        <TimeInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'Address': {
      return (
        <AddressInput
          field={addressField}
          values={formValues}
          errors={formErrors}
          onChange={handleAddressChange}
        />
      );
    }
    case 'fullname': {
      return (
        <FullNameInput
          field={fullnameField}
          values={formValues}
          errors={formErrors}
          onChange={handleAddressChange}
        />
      );
    }
    case 'date': {
      return (
        <DateInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'monthYearPicker': {
      return (
        <MonthYearPicker
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'bottomSidebar': {
      return <BottomSidebar field={field} />;
    }
    case 'paragraph': {
      return (
        <ParaGraph
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'textCard': {
      return (
        <TextCard
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }

    case 'datagrid': {
      return (
        <DataGrid
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }

    case 'iconCard': {
      return (
        <IconCard
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'tab': {
      return <TabBar field={field} />;
    }

    case 'dragCard': {
      return <TodoCard field={field} />;
    }
    case 'searchBar': {
      return <SearchBar />;
    }
    case 'heading': {
      return (
        <Heading
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'radio': {
      return (
        <Radio field={field} value={value} error={error} onChange={onChange} />
      );
    }
    case 'barChart': {
      return (
        // <BarGraph
        //   field={field}
        //   value={value}
        //   error={error}
        //   onChange={onChange}
        // />
        <AmBarChart
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'calendar': {
      return <Calendar field={field} />;
    }
    case 'header': {
      return <Header field={field} />;
    }
    case 'dateTime': {
      return (
        <DateTimeInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'pieChart': {
      // return <PieGraph field={field} value={value} error={error} onChange={onChange} />;
      return (
        <AmPieChart
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'donutChart': {
      return (
        // <DonutGraph
        //   field={field}
        //   value={value}
        //   error={error}
        //   onChange={onChange}
        // />
        <AmDonutChart
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'lineChart': {
      return (
        <LineGraph
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'termsCondition': {
      return (
        <TermsCondition
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'Customgroup': {
      return (
        <AddressInput
          field={addressField}
          values={formValues}
          errors={formErrors}
          onChange={handleAddressChange}
        />
      );
    }
    case 'signaturePad': {
      return <SignaturePad field={field} value={value} />;
    }
    case 'website': {
      return (
        <Website
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      );
    }
    case 'drawingPad': {
      return <DrawingPad field={field} value={value} />;
    }
    default:
      return null;
  }
};
