import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Field } from "../types";

interface DrawingPadProps { 
  field: Field; 
  value: string; 
}

export const DrawingPad: React.FC<DrawingPadProps> = ({ field, value }) => {

    const [width, height] = field?.drawingPadSize?.split(" ").map(Number) || "";
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(value || null); // Initialize with value prop if available

  // Whenever a new signature is drawn, update the imageURL state
  const saveSignature = () => {
    if (sigCanvas.current) {
      const signatureData = sigCanvas.current.toDataURL("image/png");
      setImageURL(signatureData); // Update the state with the new signature
    }
  };

  // Clear the signature
  const clearSignature = () => {
    sigCanvas.current?.clear();
    setImageURL(null); // Clear the current signature
  };

  // Sync field value with the imageURL (if needed, depends on your form setup)
  if (field) {
    field.value = imageURL ? imageURL.toString() : ""; // Ensure the field value is always updated
  }

  return (
    <div className="p-20 text-align item-center">
      <h6>Drawing Pad</h6>

      <div
        className="border bg-white flex justify-center items-center"
        style={{ width: `${width}px`, height: `${height}px` }}
        >
        <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            onEnd={saveSignature}
            canvasProps={{
            width: width,
            height: height,
            className: "signatureCanvas",
            }}
        />
      </div>


      {/* Buttons */}
      <div style={{ marginTop: "1px" }}>
        <button 
          type="button" 
          onClick={clearSignature} 
          style={{ marginLeft: "10px" }}
        >
          Clear {imageURL ? 'Drawing' : 'Empty'}
        </button>
      </div>

      {/* Conditional rendering of the image */}
      {(imageURL) && (
        <img 
          src={imageURL} 
          alt="User Signature" 
          style={{ border: "1px solid black" }} 
        />
      )}
    </div>
  );
};
