import React, { useState, useEffect } from "react";

import { Field } from "../types";

interface DynamicCalculationProps {
  fields: any;
  initialFormula: string;
  formValues: any;
  field: Field

}

const DynamicCalculationField: React.FC<DynamicCalculationProps> = ({
  fields = [],
  initialFormula,
  formValues,
  field,
  // formSelectValue
}) => {
  if (!initialFormula) initialFormula = "";

  const formula1 = initialFormula.replace(/\s+/g, "") || "";

  const processedFields = fields.map((field: any) => {
    const newLabel = field.label.replace(/\s+/g, "");
    return { ...field, label: newLabel };
  });

  const sectionFields = processedFields.reduce((acc: any, field: any) => {
    const section = formValues[field.sectionId];
    if (section && field.type !== "select") {
      acc[field.id] = section[field.id] ?? "";
    }
    return acc;
  }, {} as { [key: string]: number | "" });

  processedFields.forEach((field: any) => {
    field.value = field.type !== "select" ? sectionFields[field.id] : field.dummyvalue;
  });

  const fieldValuesOnly = processedFields.reduce((acc: any, field: any) => {
    acc[field.id] = field.value;
    return acc;
  }, {} as Record<string, number | "">);



  const [fieldValues, setFieldValues] = useState<{ [key: string]: number | "" }>(
    processedFields.reduce((acc: any, field: any) => {
      acc[field.id] = field.value;
      return acc;
    }, {} as { [key: string]: number | "" })
  );

  const [formula, setFormula] = useState<string>(formula1);
  const [calculatedValue, setCalculatedValue] = useState<number | null>(null);

  useEffect(() => {
    calculateResult(fieldValues, formula);
  }, [processedFields]);

  function calculateFormula(array: any[], formula: string): number | null {
    const values: Record<string, number> = {};


    array.forEach((item) => {
      const label = item.label;
      const value = parseFloat(item.value) || 0;
      values[label] = value;
    });

    let computation = formula || "";

    for (const [key, value] of Object.entries(values)) {
      computation = computation.replace(new RegExp(`(?<!\\w)${key}(?!\\w)`, "g"), value.toString());
    }

    try {
      return eval(computation);
    } catch (error) {
      console.error("Error evaluating formula:", error);
      return null;
    }
  }
  const calculatedValues = calculateFormula(processedFields, formula);

  if (field) field.value = calculatedValues ? calculatedValues.toString() : "";

  const calculateResult = (
    updatedValues: { [key: string]: number | "" },
    formula: string
  ) => {

    try {
      const evaluatedFormula = formula.replace(/\b\w+(\.\d+)?\b/g, (match) => {
        const field = processedFields.find((field: any) => field.label === match);
        // console.log(field,'field')
        return field ? updatedValues[field.id]?.toString() || "0" : "0";
      });
      const result = new Function(`return (${evaluatedFormula})`)();
      setCalculatedValue(result);
    } catch (error) {
      console.error("Error evaluating formula:", error);
      setCalculatedValue(null);
    }
  };

  return (
    <div className="">
      <div className="">
        {calculatedValue !== null ? (
          <>
            <p>{calculatedValues}</p>
          </>
        ) : (
          <p className="">0</p>
        )}
      </div>
    </div>
  );
};


export default DynamicCalculationField;
