
import React, { useState } from "react";
import {TabProps } from "../types";

export const TabBar = ({field} : TabProps) => {

  const [activeTab, setActiveTab] = useState(0);

 const tab = field.tab ? field.tab : [];

  const tabs = tab.map((tab:any, index:any) => ({
    ...tab, 
    id : index
  }));

  
  const getTabStyle = (tabId: any) => {
    if (field.tabStyle === "rectangle") {
      return activeTab === tabId
        ? "px-4 py-2 rounded-md bg-curious-blue-700 text-white"
        : "px-4 py-2 rounded-md border-2 text-white-600";
    } else {
      return activeTab === tabId
      ? "cursor-pointer pb-2 px-4 text-lg font-semibold text-curious-blue-700 border-b-2 border-curious-blue-700"
      : "cursor-pointer pb-2 px-4 text-lg font-semibold text-gray-700"
    }
  };



  return (
    
    tabs.length===0 ? ( 
      <div className="text-lg flex justify-center items-center font-bold font-roboto text-gray-900"
           style={{ height: '100%' }}
      >No records found !</div>

    )
    :
    (
      <>
      <div className="relative border rounded">
      <ul className="flex space-x-4 m-2 py-2 border-b-2 border-gray-300 mb-4 ">
        {tabs.map((tab:any) => (
          <li
            key={tab.id}
            onClick={() => setActiveTab(tab.id)} 
            className={getTabStyle(tab.id)}
          >
            {tab.title}
          </li>
        ))}
      </ul>

      <div className="p-4">
        
        {tabs
          .filter((tab :any) => tab.id === activeTab) 
          .map((tab :any) => (
            <div key={tab.id}>
              <h2 className="text-2xl font-bold mb-2">{tab.content}</h2>
            </div>

          ))}
      </div>
      </div>
      </>
    )
  
    
  );
};

