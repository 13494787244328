import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ColDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useFormStore } from '@empire/components/organisms';
import { FaLink } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify'; 

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
};

export default function ImportStatus() {
  const { userConfig, setUserConfig } = useFormStore();
  const [queueData, setQueueData] = useState<any[]>([]);
  const authtoken = localStorage.getItem('token');
  const OrgId = userConfig?.orgStatus[0].orgId || undefined;
  const userid = userConfig?.userId != undefined ? userConfig?.userId : localStorage.getItem('userid');

  // Column Definitions for AG Grid
  const columnDefs: ColDef[] = [
    { headerName: 'Date', field: 'date' },
    { headerName: 'Time', field: 'time' },
    { headerName: 'Form Name', field: 'formname' },
    { headerName: 'User Name', field: 'username' },
    { headerName: 'Status', field: 'status' },
    {
      headerName: 'Excel',
      field: 'fileurl',
      width: 250,
      cellRenderer: (params: any) => (
        <a
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline flex items-center justify-center"
          style={{ textAlign: 'center' }} 
        >
          <FaLink className="mr-2" /> 
          Link 
        </a>
      ),
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: (params: any) => (
        <button
          onClick={() => handleRun(params.data)}
          className="bg-blue-500 text-white  px-4 rounded-md hover:bg-blue-700 transition-all duration-300"
        >
          Run
        </button>
      ),
    },
  ];

  // Fetch User Config on initial load
  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
        const data = await response.data;
        setUserConfig(data);
      } catch (error) {
        console.error('Error fetching form configuration:', error);
      }
    };

    fetchFormConfig();
  }, []);

  // Fetch Queue Data when OrgId is available
  useEffect(() => {
    if (OrgId) {
      const fetchQueueData = async () => {
        try {
          const response = await axios.post(
            `${BACKEND.BACKEND_API}/other/getqueuedata`,
            { OrgId },
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );
          setQueueData(response.data);
        } catch (error) {
          console.error('Error fetching queue data:', error);
        }
      };

      fetchQueueData();
    }
  }, [OrgId]);

  // Handle the "Run" button click
  const handleRun = (rowData: any) => {
    // console.log('Row Data:', rowData);
    if(rowData.status==='Inserted')
    {
      toast.error('This Excel is allready inserted',{
        position: 'bottom-right',
        autoClose: 2000,
      }); 

    }
    else
    {
       toast.success('Queue management working in progress.',{
                        position: 'bottom-right',
                        autoClose: 2000,
                      }); 
    }  

  };

  return (
    <div className='p-6'>
         <ToastContainer />
      <h4 className='mb-6'>Excel Import Queue's List</h4>
      <hr/>
      <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={queueData} // Set the row data from queueData state
          pagination={true}
        />
      </div>
    </div>
  );
}
