import Sidebar from './Sidebar';
import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Installed from './Installed';
import Public from './Public';
import Private from './Private';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

function Plugin() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    // <div className="flex">
    //   <div className="relative flex">
    //     <div className={`relative ${isSidebarOpen ? 'w-56' : 'w-3'} bg-gray-200 min-h-screen`}>
    //       {isSidebarOpen && <Sidebar />}

    //       <div className="absolute top-0 right-0 w-2 h-full group ">
    //         <button
    //           className="absolute flex items-center justify-center w-6 h-6 text-black transform -translate-y-1/2 bg-gray-100 rounded-full shadow-md top-32 -right-3"
    //           onClick={toggleSidebar}
    //         >
    //           {isSidebarOpen ? <FaAngleLeft /> : <FaAngleRight />}
    //         </button>
    //         <div className="absolute px-2 py-1 text-xs text-black transition-opacity duration-300 transform -translate-y-1/2 bg-white rounded-md opacity-0 top-36 -right-16 group-hover:opacity-100">
    //           {isSidebarOpen ? 'Collapse' : 'Expand'}
    //         </div>
    //       </div>
    //     </div>

    //     <div className={`flex flex-col w-full flex-grow `}>
    //       <main className="p-8">
    //         <Routes>
    //           <Route path="Installed" element={<Installed />} />
    //           <Route path="Public" element={<Public />} />
    //           <Route path="Private" element={<Private />} />
    //           <Route path="*" element={<div>Select a plugin section</div>} />
    //         </Routes>
    //       </main>
    //     </div>
    //   </div>
    // </div>
    <div className="page">
      <Sidebar />

      <div className="content">
        {/* Start::main-content */}
        <div className="main-content">
          {/* Page Header */}
          <div className="block justify-between page-header md:flex">
            <div>
              <h3 className="text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium"></h3>
            </div>
          </div>
          {/* Page Header Close */} {/* Start::row-1 */}
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <Routes>
                <Route path="Installed" element={<Installed />} />
                <Route path="Public" element={<Public />} />
                <Route path="Private" element={<Private />} />
                <Route
                  path="*"
                  element={
                    <div className="box">
                      <div className="box-header">
                        <h5 className="box-title"> Alerts Plugin </h5>
                      </div>
                      <div className="box-body">
                        <div
                          className="bg-yellow-50 border border-yellow-200 alert mb-0"
                          role="alert"
                        >
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <svg
                                className="h-4 w-4 text-yellow-400 mt-0.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="currentColor"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                              </svg>
                            </div>
                            <div className="ms-2">
                              <h3 className="text-sm text-yellow-800 font-semibold">
                                Select a plugin section
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </Routes>
            </div>
          </div>
          {/* End::row-1 */}
        </div>
        {/* Start::main-content */}
      </div>
    </div>
  );
}

export default Plugin;
