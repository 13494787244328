import React from 'react';
import LeadList from './LeadList';

type Props = {
  isShare?: boolean;
};

export function ListMain({ isShare }: Props) {
  return (
    <div>
      <LeadList />
    </div>
  );
}

export default ListMain;
