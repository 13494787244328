import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import { Field, Form, PieChartProps, Section } from '../types';
import { Pie } from 'react-chartjs-2';
import PieChart from './PieChart';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

export const PieGraph = ({ field, value, error, onChange }: PieChartProps) => {
  return (
    <>
      {field.selectType === 'Dynamic' && (
        <PieChartInput
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      )}
      {field.selectType === 'Static' && field.pieChart && (
        <PieChart
          field={field}
          value={value}
          error={error}
          onChange={onChange}
        />
      )}
    </>
  );
};

// const PieChartInput = ({ field, value, error, onChange }: PieChartProps) => {

//     const [loading, setLoading] = useState(true);
//     const [dynamicData, setDynamicData] = useState<Form[]>([]);
//     const [allSections, setAllSections] = useState<Section[]>([]);
//     const [filteredFields, setFilteredFields] = useState<Field[]>([]);
//     const [filteredFieldsByLabelId, setFilteredFieldsByLabelId] = useState<Field[]>([]);
//     const [filteredFieldsByValueId, setFilteredFieldsByValueId] = useState<Field[]>([]);
//     const [valData, setValData] = useState<number[]>([]);
//     const [labelData, setLabelData] = useState<(string )[]>([]);
//     const authtoken = localStorage.getItem('token')

//   const getFormData = async () => {
//     try {
//       // const response = await axios.post(`${BACKEND.BACKEND_API}/getformsvaluesbyid`, {
//       //   formId: field.formid,
//       // });

//       const response = await axios.post(`${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,{formId: field.formid} ,{
//         headers: {
//           'Authorization': `Bearer ${authtoken}`,
//         },
//       })

//       const data = response.data;
//       setDynamicData(data);

//       const extractedSections = data.flatMap((form: Form) =>
//         form.sections.map((section) => ({
//           ...section,
//           formId: form._id,
//         }))
//       );

//       const extractsectionidwise = extractedSections.filter((section : Field) => section.id === field.sectionid);
//       const fieldsFromSection = extractsectionidwise.flatMap((section : Field) =>
//         section.fields?.map((fieldItem) => ({
//           ...fieldItem,
//           formId: section.formId,
//         }))
//       );

//       const filteredDataByLabelId = fieldsFromSection.filter((fieldItem : Field) => fieldItem.id === field.pieLabelId);
//       const barXdata = filteredDataByLabelId.map((data : Field) => data.value?.toString() || "");

//       const filteredDataByValueId = fieldsFromSection.filter((fieldItem : Field) => fieldItem.id === field.pieValId?.toString());
//       const barYdata = filteredDataByValueId.map((data : Field) => Number(data.value));

//       setValData(barYdata);
//       setLabelData(barXdata);

//       setLoading(false);
//     } catch (err) {
//       console.error("Error fetching data:", err);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getFormData();
//   }, []);
//   if (loading) {
//     return <div>Loading...</div>;  // Show loading state
//   }

//   if (valData.length === 0 || labelData.length === 0) {
//     return <div>No data available for the chart.</div>;
//   }

//   const options : any = {
//     chart: {
//       width: '100%',
//       type: 'pie',
//     },
//     labels: labelData,
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: '100%',
//           },
//           legend: {
//             position: 'bottom',
//           },
//         },
//       },
//     ],
//   };

//   return (
//     <div className="max-w-3xl mx-auto p-4">
//       <div className="bg-white p-8 rounded-lg shadow-lg">
//         <h2 className="text-2xl font-semibold text-center mb-6">{field.label}</h2>
//         <div className="flex justify-center">
//           <ReactApexChart options={options} series={valData} type="pie" width="380" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PieChartInput;

const PieChartInput = ({ field, value, error, onChange }: PieChartProps) => {
  const [loading, setLoading] = useState(true);
  const [valData, setValData] = useState<number[]>([]);
  const [labelData, setLabelData] = useState<string[]>([]);
  const authtoken = localStorage.getItem('token');

  const getFormData = async () => {
    try {
      const response = await axios.post(
        `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
        { formId: field.formid },
        { headers: { Authorization: `Bearer ${authtoken}` } }
      );

      const data = response.data;

      const extractedSections = data.flatMap((form: Form) =>
        form.sections.map((section) => ({ ...section, formId: form._id }))
      );

      const extractsectionidwise = extractedSections.filter(
        (section: Field) => section.id === field.sectionid
      );

      const fieldsFromSection = extractsectionidwise.flatMap((section: Field) =>
        section.fields?.map((fieldItem) => ({
          ...fieldItem,
          formId: section.formId,
        }))
      );

      const filteredDataByLabelId = fieldsFromSection.filter(
        (fieldItem: Field) => fieldItem.id === field.pieLabelId
      );
      const barXdata = filteredDataByLabelId.map(
        (data: Field) => data.value?.toString() || ''
      );

      const filteredDataByValueId = fieldsFromSection.filter(
        (fieldItem: Field) => fieldItem.id === field.pieValId?.toString()
      );
      const barYdata = filteredDataByValueId.map(
        (data: Field) => Number(data.value) || 0
      );

      // console.log("barXdata:", barXdata);
      // console.log("barYdata:", barYdata);

      setValData(barYdata);
      setLabelData(barXdata);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFormData();
  }, []);

  if (loading) return <div>Loading...</div>;

  if (valData.length === 0 || labelData.length === 0)
    return <div>No data available for the chart.</div>;

  const options: any = {
    chart: { width: '100%', type: 'pie' },
    labels: labelData,
    responsive: [
      {
        breakpoint: 480,
        options: { chart: { width: '100%' }, legend: { position: 'bottom' } },
      },
    ],
  };

  return (
    // <div className="max-w-3xl mx-auto p-4">
    <div className="w-full ml-4 bg-white p-6 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
      <h2 className="text-2xl font-semibold text-center mb-6">{field.label}</h2>
      <div className="flex justify-center">
        <ReactApexChart
          options={options}
          series={valData.length > 0 ? valData : [0]}
          type="pie"
          width="380"
        />
      </div>
    </div>
  );
};
