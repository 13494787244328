import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FaLink, FaTimes } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormStore  }  from "../organisms/UserStore";

import { Field, Section, Form as FormBase } from "../types";

interface Form extends FormBase {
  selectedFields: Field[];
}

interface FormRelationship {
  parentFormId: string;
  childFormId: string;
  parentFieldId: string;
  childFieldId: string;
}

const BACKEND = {
  BACKEND_API: process.env["BACKEND_API"],
  BACKEND_API_NORMAL: process.env["BACKEND_API_NORMAL"]
}


const crmRelated: React.FC = () => {
  const [formList, setFormList] = useState<Form[]>([]);
  const [formRelationships, setFormRelationships] = useState<FormRelationship[]>([]);
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState<Form | null>(null);
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [formsData, setFormsData] = useState<Form[]>([]);
  const [loading, setLoading] = useState(false);
  const [parentLinkedFieldId, setParentLinkedFieldId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [parentLinkedField, setParentLinkedField] = useState<Field[] | null>(null);
  const [parentFormId,setParentFormId]=useState('')
  const [ChildsectionId,setSectionId]=useState('')
  const { userConfig} = useFormStore();
    const userid = userConfig?.userId
    const authtoken=localStorage.getItem('token')
  // const [oldFormRelationships,setOldFormRelationships]=useState<FormRelationship[]>([]);
 
//  console.log(parentFormId,'parentFormId');
 

  const { formId } = useParams();


  useEffect(() => {
    const fetchForms = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/other/relation/${parentFormId}`,{
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
        // setFormsData(response.data);
        // console.log(response.data,'response.data1');
        setFormRelationships(response.data.data);
      } catch (error) {
        // console.error(error);
      }
    };

    fetchForms();
  }, [parentFormId]);
  const moduleId=
localStorage.getItem('lastModuleId')
// console.log(moduleId,'moduleId')

  useEffect(() => {
    const fetchForms = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${BACKEND.BACKEND_API}/other/getFormbymoduleId`, { moduleId: moduleId },{
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });


        const data = response.data || [];


        const formsWithSections = data.filter((form: any) => form.sections && Array.isArray(form.sections) && form.sections.length > 0);
        if (formsWithSections.length === 0) {
          console.error("No forms with valid sections found in the response.");
          return;
        }
        const transformedForms: Form[] = formsWithSections.filter((form: any) => {

          return form.sections.some((section: any) =>
            section.fields.some((field: any) => field.isLinkedField)
          );
        }).map((form: any) => {

          return {
            formId: form._id,
            _id: form._id,
            formTitle: form.formTitle,
            sections: form.sections.map((section: any) => ({
              id: section.id,
              fields: section.fields.filter((field: any) => field.isLinkedField),
            })),
            selectedFields: [],
          };
        });

        setFormsData(transformedForms);


      } catch (error) {
        console.error("Error fetching forms:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchForms();
  }, []);

  useEffect(() => {
    const getSpecificForm = () => {
      const specificFormFields = formsData.find((form: any) => form._id === parentFormId)?.sections.flatMap((section) => {
        setSectionId(section.id);
        return section.fields.filter((field) => field.isLinkedField);
      });

      if (specificFormFields && specificFormFields.length > 0) {
        setParentLinkedField(specificFormFields);
      }
    };
    getSpecificForm();
  }, [formsData,parentFormId]);

  const handleAddForm = (form: Form) => {
    const formExists = formList.some((f) => f._id === form._id);
    if (!formExists) {
      setSelectedForm(form);
      setShowFormModal(false);
      setShowFieldModal(true);
    } else {
      setSelectedForm(null);
    }
  };

  const handleFieldSelection = (field: Field) => {
    setSelectedForm((prevForm) => {
      if (prevForm) {
        const isFieldSelected = prevForm.selectedFields.some(
          (selectedField) => selectedField.id === field.id
        );
        const updatedFields = isFieldSelected
          ? prevForm.selectedFields.filter((selectedField) => selectedField.id !== field.id)
          : [...prevForm.selectedFields, field];

        return { ...prevForm, selectedFields: updatedFields };
      }
      return prevForm!;
    });
  };




  const handleSaveForm = async () => {
    if (selectedForm) {
      const updatedFormList = formList.some((form) => form._id === selectedForm._id)
        ? formList.map((form) =>
          form._id === selectedForm._id
            ? { ...form, selectedFields: selectedForm.selectedFields }
            : form
        )
        : [...formList, selectedForm];

      setFormList(updatedFormList);

      const newRelationships = selectedForm.selectedFields.map((field) => ({
        formId: parentFormId as string,
        parentFormId: formId as string,
        parentSectionId: ChildsectionId,
        parentFieldId: parentLinkedFieldId as string,
        childFormId: selectedForm._id,
        childSectionId: selectedForm.sections[0].id,
        childFieldId: field.id,
      }));
  //  console.log(newRelationships,'newRelationships')
      setFormRelationships((prevRelationships) => [...prevRelationships, ...newRelationships]);
      // console.log(newRelationships,'newRelationships');
      // Send newRelationships to API
      try {
        const res=await axios.post(`${BACKEND.BACKEND_API}/other/relation/`, newRelationships[0],{
          headers: {
            'Authorization': `Bearer ${authtoken}`,
          },
        });
        // console.log(res,'reresresresress');
      } catch (error) {
        console.error('Error saving relationships:', error);
      }
    }


    setShowFieldModal(false);
  };


  const viewForm = (matchingRelation: any) => {


    // localStorage.setItem('matchingRelation', JSON.stringify(matchingRelation));
    // localStorage.setItem('matchingRelation1', JSON.stringify(matchingRelation));

    // const { formId } = useParams();

    // console.log(formId,'11');
    localStorage.setItem('formId', formId as string);
    localStorage.setItem('formId1', formId as string);

    const getData = async () => {
      const res = await axios.get(`${BACKEND.BACKEND_API}/value/${formId}`,{
        headers: {
          'Authorization': `Bearer ${authtoken}`,
        },
      });
  
      setParentFormId(res.data.formId);
          
      
    };


    // localStorage.setItem('formId', formId);
    navigate(`/test/list/${formId}`);
  }


  const getData = async () => {
  const res = await axios.get(`${BACKEND.BACKEND_API}/value/${formId}`,{
        headers: {
          'Authorization': `Bearer ${authtoken}`,
        },
      });

    setParentFormId(res.data.formId);


  };

  useEffect(() => {
    getData();
  },);

  //  console.log(formRelationships,'formRelationships');
  return (
    <div className="">
      <div className="">
        {/* <div className="flex items-center mb-4">
          <FaLink className="mr-2" />
          <h2 className="text-xl text-black">Related List</h2>
        </div> */}
        <ul className="text-white">
          {/* {formRelationships
  .map((rel) => 
    formsData.find((form) => form._id === rel.childFormId)
  )
  .filter((form) => form)
  .map((form) => (
    <li
      key={form?._id ?? ''}
      className="p-1 font-serif font-semibold text-gray-700 rounded cursor-pointer hover:bg-gray-200"
      onClick={() => {
        const matchingRelation = formRelationships.find(rel => rel.childFormId === form?._id);
        if (matchingRelation) {
          console.log('Matching Relation:', matchingRelation);
          viewForm(matchingRelation);
        }
      }}
    >
      {form?.formTitle ?? ''}
    </li>
  ))} */}
        </ul>
        <div className="p-2 ">
          <a
            className="mb-4 text-sm text-blue-500 rounded cursor-pointer "
            onClick={() => setShowFormModal(true)}
          >
            Add Related +
          </a>
        </div>
      </div>

      {showFormModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50  ">
          <div className="w-1/3 p-6 bg-white rounded-md overflow-y-auto h-1/2">
            <div className="flex justify-between">
              <h3 className="text-xl">Add Form</h3>
              <button onClick={() => setShowFormModal(false)}>
                <FaTimes />
              </button>
            </div>
            <ul className="mt-4">

              {formsData.map((form) => {
                const formExists = formList.some((f) => f._id === form._id);
                return (
                  <li
                    key={form._id}
                    className={`p-2 cursor-pointer hover:bg-gray-200 ${formExists ? "text-gray-400" : ""}`}
                    onClick={() => !formExists && handleAddForm(form)}
                    style={{ pointerEvents: formExists ? "none" : "auto" }}
                  >
                    <span className={formExists ? "line-through" : ""}>{form.formTitle}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      {showFieldModal && selectedForm && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-1/3 p-6 bg-white rounded-md">
            <div className="flex justify-between">
              <h3 className="text-xl">Select Child Linked Field</h3>
              <button onClick={() => setShowFieldModal(false)}>
                <FaTimes />
              </button>
            </div>
            <ul className="mt-4">
              {selectedForm.sections.flatMap((section) =>
                section.fields.map((field) => (
                  <li key={field.id} className="p-2 cursor-pointer hover:bg-gray-200">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={selectedForm.selectedFields.some(
                          (selectedField) => selectedField.id === field.id
                        )}
                        onChange={() => handleFieldSelection(field)}
                        className="mr-2"
                      />
                      {field.label}
                    </label>
                  </li>
                ))
              )}
            </ul>
            <div className="mt-4">
              <h4 className="text-lg">Parent Linked Field</h4>
              <select
                className="w-full p-2 mt-2 border rounded"
                // onChange={(e) => console.log(`Parent field selected: ${e.target.value}`)}
                onChange={(e) => { setParentLinkedFieldId(e.target.value) }}
              >
                <option value="">-- Select Parent Field --</option>
                {parentLinkedField?.map((field) => (
                  <option key={field.id} value={field.id}>
                    {field.label}
                  </option>
                ))}
              </select>
            </div>
            <button
              className={`mt-4 p-2 rounded ${selectedForm.selectedFields.length === 0 ? "bg-gray-500 text-gray-200" : "bg-curious-blue-600 text-white"
                }`}
              disabled={selectedForm.selectedFields.length === 0}
              onClick={handleSaveForm}
            >
              {selectedForm.selectedFields.length === 0 ? "Select Linked Field" : "Save Form"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default crmRelated;


