import React, { useState } from 'react';
import { Field } from '../types';
interface HeadingInputProps { 
    field: Field;
    value?: string;
    error?: boolean;
    onChange?: (e: React.ChangeEvent<any>) => void;}

export const Heading = ({ field, value, error, onChange }: HeadingInputProps) => {
    
  return (
    <div className="p-2   rounded flex space-x-6" >
      {field.type==='heading' && field.headingType && 
      <field.headingType>{field.heading}</field.headingType>
      } 
      </div>
   
  );
};