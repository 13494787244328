import React, { useState, useCallback, useRef } from 'react';
import { IoMdCloudUpload } from 'react-icons/io';
import ReactCrop,  { Crop } from 'react-image-crop'; //CompletedCrop
import CompletedCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import Webcam from 'react-webcam';
import { Field } from '../types';

type ImageUploaderProps = {
  field: Field;
  error: boolean;
  aspect?: number;
  onChange: any;
};

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  field,
  aspect = 1,
  onChange
}) => {
  const [previewUrl, setPreviewUrl] = useState<string >(field.value || "");
  const [error, setError] = useState<string | null>(null);
  const [src, setSrc] = useState<string | null>(null); // The image source used for cropping
  const [isWebcam, setIsWebcam] = useState<boolean>(false); // To track if webcam is active
  const [crop, setCrop] = useState<Crop>({ unit: '%', width: 100, height: 100, x: 0, y: 0 });
  const [completedCrop, setCompletedCrop] = useState<CompletedCrop | any>();
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null); // To store cropped image URL
  const [croppedImageFile, setCroppedImageFile] = useState<File | null>(null); // To store cropped image file
  const [fileName, setFileName] = useState('')
  const webcamRef = useRef<Webcam | any>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const capture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setSrc(imageSrc); 
      setPreviewUrl(imageSrc); 

      const videoTracks = webcamRef?.current?.stream.getVideoTracks();
      videoTracks.forEach((track : any) => track.stop());

      setIsWebcam(false); 
    }
  };

  const cancelCapture = () => {
    const videoTracks = webcamRef.current?.stream.getVideoTracks();
    videoTracks?.forEach((track : any) => track.stop()); 
    setIsWebcam(false);
  };

  const getCroppedImg = (): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      if (!completedCrop || !imageRef.current || !canvasRef.current) {
        reject(new Error('Crop context missing'));
        return;
      }

      const image = imageRef.current;
      const canvas = canvasRef.current;
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Canvas context missing'));
        return;
      }

      const cropX = completedCrop.x * scaleX;
      const cropY = completedCrop.y * scaleY;
      const cropWidth = completedCrop.width * scaleX;
      const cropHeight = completedCrop.height * scaleY;

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );

      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleChange = (e : any) =>{
    const filename =  e.target.files ? e.target.files[0].name : "document";
    setFileName(filename);
    onChange(e.target.files[0])    
  }

  const handleCropComplete = async (e : any) => {
    try {
      const croppedImage = await getCroppedImg();
      const fileName = `cropped-image-${Date.now()}.jpg`;
      const file = new File([croppedImage], fileName, { type: 'image/jpeg' });
      onChange(file);
      const previewUrl = URL.createObjectURL(croppedImage);
      setFileName("document.png")
      setPreviewUrl(previewUrl);
      setSrc(null); 

      setCroppedImageUrl(previewUrl); 
      setCroppedImageFile(file);

    } catch (error) {
      setError('Error cropping image');
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      {src && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg max-w-full max-h-full overflow-auto">
            <div className="flex justify-end gap-4 mt-4">
              <div
                onClick={() => setSrc(null)}
                className="px-4 py-2 bg-gray-300 rounded-lg hover:opacity-80 cursor-pointer"
              >
                Cancel
              </div>
              <div
                onClick={handleCropComplete}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:opacity-80 cursor-pointer"
              >
                Crop
              </div>
            </div>
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img ref={imageRef} src={src} alt="Crop preview" className="max-w-full max-h-96 object-contain" />
            </ReactCrop>
          </div>
        </div>
      )}

      <canvas ref={canvasRef} style={{ display: 'none' }} />

      {isWebcam ? (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            videoConstraints={{
              facingMode: 'environment',
            }}
          />
          <div
            onClick={capture}
            className="mt-4 px-6 py-2 w-full bg-blue-500 text-white rounded-lg hover:bg-blue-600 cursor-pointer"
          >
            Capture
          </div>
          <div
            onClick={cancelCapture} 
            className="mt-4 px-6 py-2 w-full bg-red-500 text-white rounded-lg hover:bg-red-600 cursor-pointer"
          >
            Cancel
          </div>
        </div>
      ) : (
        <div>          
          {previewUrl || fileName ? (
            // <img src={previewUrl} alt="Preview" className="max-w-full max-h-96 object-contain rounded-lg" />
            <div className="flex items-center justify-between bg-gray-100 p-2 rounded-lg">
                <div className="flex items-center space-x-2">
                <span className="bg-gray-300 w-8 h-8 flex items-center justify-center rounded">
                    📄
                </span>
                <div>
                    <p className="text-sm font-medium">{fileName}</p>
                    {previewUrl && <a href={previewUrl || "##"} target='_blank'>{ "document"}</a>}
                </div>
                </div>
            <div className="text-red-500 hover:text-red-700 cursor-pointer" onClick={()=>{
              setPreviewUrl('')
              setFileName('')
              }}>
            🗑️
            </div>
  </div>
            ) : (
                <div className="max-w-md mx-auto border-2 border-dashed border-gray-300 rounded-lg p-4 hover:border-blue-500">
                    <div className="flex flex-col items-center justify-center  hover:border-blue-500 transition">
                        <label className="flex flex-col items-center justify-center cursor-pointer"> 
                            <IoMdCloudUpload className="text-gray-400 w-12 h-12" />
                            <p className="text-gray-600 font-medium mt-2">Upload a File</p>
                            <input type="file" style={{ display: 'none' }} onChange={handleChange} />
                        </label>
                        <p className="text-gray-500 text-sm pt-4">or</p>
                        <div
                        onClick={() => setIsWebcam(true)}
                        className="p-1 text-3xl bg-transparent hover:scale-105 transition-transform cursor-pointer"
                        >
                        📸
                        <p className="text-gray-500 text-sm">Take Photo</p>
                        </div>
                    </div>
                </div> 
                )}
        </div>  
      )}
    </div>
  );
};

