import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { Field, Form, PieChartProps, Section } from '../types';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4themes_animated from '@amcharts/amcharts4/themes/animated';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

// Apply amCharts theme
am4core.useTheme(am4themes_animated.default);

export const AmDonutChart: React.FC<PieChartProps> = ({
  field,
  value,
  error,
  onChange,
}) => {
  const [loading, setLoading] = useState(true);
  const [valData, setValData] = useState<number[]>([]);
  const [labelData, setLabelData] = useState<string[]>([]);
  const chartRef = useRef<am4charts.PieChart | null>(null);
  const chartDivRef = useRef<HTMLDivElement>(null);
  const authtoken = localStorage.getItem('token');

  useEffect(() => {
    const getFormData = async () => {
        try {
          const  response = await axios.post(
            `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
            {
              formId: field.formid,
              isFilterd: field.isFilterd || false,
              filters: field.filters || {},
            },
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );
          const data = response.data;
          const extractedSections = data.flatMap((form: Form) =>
            form.sections.map((section: Section) => ({ ...section, formId: form._id }))
          );
  
          const fieldsFromSection = extractedSections.flatMap((section: any) =>
            section.fields?.map((fieldItem: Field) => ({
              ...fieldItem,
              formId: section.formId,
            }))
          );
  
          const filteredDataByLabelId = fieldsFromSection.filter(
            (fieldItem: Field) => fieldItem.id === field.donutLabelId
          );
          const barXdata = filteredDataByLabelId.map(
            (data: Field) => data.value?.toString() || ''
          );
  
          const filteredDataByValueId = fieldsFromSection.filter(
            (fieldItem: Field) => fieldItem.id === field.donutValId?.toString()
          );
          const barYdata = filteredDataByValueId.map(
            (data: Field) => Number(data.value) || 0
          );
  
          setValData(barYdata);
          setLabelData(barXdata);
          setLoading(false);
        } catch (err) {
          console.error('Error fetching data:', err);
          setLoading(false);
        }
      };

    getFormData();
  }, []);

  useLayoutEffect(() => {
    if (labelData.length === 0 || valData.length === 0) return; // Avoid rendering empty chart

    const chart = am4core.create(
      chartDivRef.current as HTMLDivElement,
      am4charts.PieChart
    );
    chartRef.current = chart;

    // ✅ Set Data
    chart.data = labelData.map((label, index) => ({
      category: label,
      value: valData[index] || 0,
    }));

    // ✅ Donut Chart Setup (Pie Chart with Inner Radius)
    chart.innerRadius = am4core.percent(40); // Adjust for larger/smaller hole

    // Create pie series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'category';
    pieSeries.slices.template.tooltipText = '{category}: [bold]{value}[/]';

    // ✅ Remove legend
    if (chart.legend) {
      chart.legend.dispose();
    }

    // ✅ Remove watermark
    chart.logo.disabled = true;

    return () => {
      chart.dispose();
    };
  }, [labelData, valData]); // 🔥 Re-run effect when data updates

  return (
    <div className="w-full ml-4 bg-white p-6 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
      <div className="relative">
        {loading ? (
          <p>Loading chart...</p>
        ) : (
          <div ref={chartDivRef} style={{ width: '100%', height: '500px' }} />
        )}
      </div>
    </div>
  );
};

