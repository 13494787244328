import React, { useEffect, useState } from 'react';

export const LiveClock: React.FC<{ className?: string }> = ({ className }) => {
  const [time, setTime] = useState<string>('');
  const [date, setDate] = useState<string>('');

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      setTime(
        now.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          second: '2-digit',
          hour12: true,
        })
      );
      setDate(
        now.toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      );
    };

    const interval = setInterval(updateClock, 1000);
    updateClock();

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="col-span-12 sm:col-span-6 xxl:col-span-3">
        <div className="box">
          <div className="box-body">
            <div className="flex items-start space-x-3 rtl:space-x-reverse">
              <span className="">
                <i className="ti ti-clock text-xl avatar w-10 h-10 rounded-full p-2.5 bg-primary/10 text-primary leading-none" />
              </span>
              <div className="w-full">
                <div className="flex mb-2 items-start justify-between">
                  <h5 className="text-xl font-semibold mb-0 leading-none">
                    <span className="font-bold">{time.split(' ')[0]}</span>
                    <span className="ml-2">{time.split(' ')[1]}</span>
                  </h5>
                </div>
                <p className="mb-0 text-xs leading-none opacity-70 text-gray-500 dark:text-white/70">
                  {date}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
