import { BarChartProps, Field ,Form, Section} from '../types'; 
import React from 'react';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const BarChartInput = ({field} : BarChartProps) => {

  const data = {
    labels: field.barChart===undefined ? [""] : field.barChart.map(data => data.label), 
    datasets: [
      {
        label: field.label,
        data: field.barChart===undefined ? [0] : field.barChart.map(data => data.value),  
        backgroundColor: 'rgba(75, 192, 192, 0.2)', 
        borderColor: 'rgba(75, 192, 192, 1)', 
        borderWidth: 1,
      },
    ],
  };

  const options : any = {
    responsive: true, 
    maintainAspectRatio: false, 
    scales: {
      x: {
        grid: {
          display: false, 
        },
      },
      y: {
        beginAtZero: true, 
        grid: {
          color: 'rgba(0, 0, 0, 0.1)', 
        },
      },
    },
    plugins: {
      legend: {
        position: 'top', 
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem : any) {
            return tooltipItem.label + ': ' + tooltipItem.raw ; 
          },
        },
      },
    },
  };

  return (
    <div className="w-full max-w-xl mx-auto bg-white p-6 rounded-lg shadow-lg dark:bg-gray-800 dark:text-white">
      <h2 className="text-2xl font-semibold text-center mb-4">{field.label}</h2>
      <div className="relative" style={{ height: '400px' }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChartInput;