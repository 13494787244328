import axios from 'axios';
import { Form } from 'libs/components/types';
import React, { useState, FormEvent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { json } from 'stream/consumers';
import FormData from 'form-data';
//import '@empire/theme/assets/theme.css';
import adaptixlogo from '@empire/theme/assets/img/brand-logos/desktop-dark-login-logo.png';
import adaptixthemetwo from '@empire/theme/assets/img/authentication/2.png';
import googleicon from '@empire/theme/assets/img/authentication/social/1.png';
import desktoplogo from '@empire/theme/assets/img/brand-logos/desktop-logo.png';
import desktopdark from '@empire/theme/assets/img/brand-logos/desktop-dark.png';
import { Loader } from '@empire/components/molecules';

interface ResponseData {
  res_code: number;
  user_name: string;
  user_id: number;
  finyear: number;
  user_email: string;
}
interface Node {
  name: string;
  children?: Node[];
}

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
  BACKEND_APII: process.env.BACKEND_APII,
  COMURL: process.env.COMPLINCE_URL,
};
const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [URL, setURL] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [responseS, setResponse] = useState<ResponseData | null>(null);
  const [userpermission, setUserPermission] = useState<Form[]>([]);
  const [rolepermission, setRolePermission] = useState<Form[]>([]);
  const [orgId, setOrgId] = useState<string>('');
  const [referrer, setReferrer] = useState<string>('');
  const authtoken = localStorage.getItem('token');
  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    const referralSource: string = document.referrer;
    setReferrer(referralSource);
    if (referralSource) {
      fetch('/api/track-referrer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ referrer: referralSource }),
      }).catch((err) => console.error('Error tracking referrer:', err));
    }
  }, []);

  const handleUserAddFallback = async () => {
    if (responseS) {
      await axios.post(`${BACKEND.BACKEND_API}/user/add`, {
        user: {
          name: username,
          email: responseS.user_email,
          roles: [],
          isActive: true,
          user_id: responseS.user_id,
          finyear: responseS.finyear,
          orgStatus: [
            {
              orgId: orgId,
              isActive: true,
            },
          ],
          password: password,
        },
      });
    }
  };

  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(`${BACKEND.BACKEND_API}/user/getallOrg`);
      setOrgId(response.data[0]._id);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const permissiondata = await axios.post(
          `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
          {
            formId: '672cbdb72957dfdf7f76bf7f',
          }
        );
        const ropermission = await axios.post(
          `${BACKEND.BACKEND_API}/other/getformsvaluesbyid`,
          {
            formId: '672cbc152957dfdf7f76bf7c',
            credentials: true,
          }
        );
        const authurl = await axios.get(`${BACKEND.BACKEND_API}/other/urlGet`);

        let URL = authurl.data[0].data.clientURL;
        setURL(URL);
        setUserPermission(permissiondata.data);
        setRolePermission(ropermission.data);
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };
    fetchPermissions();
  }, []);
  function collectNamesFromChildren(
    node: Node,
    result: string[] = []
  ): string[] {
    result.push(node.name);
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => collectNamesFromChildren(child, result));
    }
    return result;
  }
  const handleSubmit = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      let secondaryResponse = await axios.post(
        `${BACKEND.BACKEND_API}/user/concordauth`,
        {
          url: URL,
          username: username,
          password: password,
        }
      );

      setResponse(secondaryResponse.data);

      if (secondaryResponse.data.res_code == 1) {
        const response = await axios.post(`${BACKEND.BACKEND_API}/user/login`, {
          name: username,
          password,
        });
        if (response.status === 200) {
          if (response.data.language != 'en') {
            document.cookie = `googtrans=${response.data.language};path=/;domain=${window.location.hostname}`;
          }
          localStorage.setItem('userid', response.data.userId);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('home', 'CRMHOME');
          localStorage.setItem('pluginsection', 'Installed'),
            navigate('/modules');
        } else {
          await handleUserAddFallback();
          alert('Primary login failed');
        }
      } else {
        alert('Please Enter Valid Credentials ');
        console.log('Secondary login response was unsuccessful');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      if (responseS) {
        await handleUserAddFallback();
      } else {
        console.error('responseS is undefined');
      }
      alert('Please Try Again');
    } finally {
      setIsLoading(false);
    }
  };

  {
    /* kp code */
  }
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1024);
  const loginButtonRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      loginButtonRef.current?.focus(); // Focus button when Enter key is pressed
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Helper function to add user if needed

  // useEffect(() => {
  //   setTimeout(() => {
  //     const link = document.createElement('link');
  //     link.rel = 'stylesheet';
  //     link.href = 'libs/theme/src/assets/theme.css'; // Ensure correct path
  //     link.id = 'dynamic-theme';
  //     document.head.appendChild(link);

  //     console.log('✅ CSS file added:', link.href);
  //   }, 100); // Delay to load last

  //   return () => {
  //     document.getElementById('dynamic-theme')?.remove();
  //     console.log('❌ CSS file removed on unmount');
  //   };
  // }, []);

  return (
    <>
      {/* <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-curious-blue-600  to-gray-300">
        <motion.div
          className="bg-gray-50 p-10 rounded-lg shadow-lg w-96"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-xl font-bold text-center mb-6 font-serif">
            Welcome to Adaptix – Your Success Partner!
          </h2>
          <form>
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="User Name"
              >
                User Name
              </label>
              <div className="relative">
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  name="username"
                  type="text"
                  id="username"
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-curious-blue-600"
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                className="block text-sm font-medium text-gray-700"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-curious-blue-600"
                />
              </div>
            </div>
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-full bg-curious-blue-600 hover:bg-curious-blue-800 text-white font-semibold py-4 rounded-md hover:bg-sky-800 transition duration-200"
            >
              Login
            </button>
          </form>
        </motion.div>
      </div> */}
{isloading?<div className='py-40'><Loader/></div>:<div className="error-page flex h-full !py-0 bg-white dark:bg-bgdark">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-6 w-full h-full">
          {!isMobile && (
            <div
              className="lg:col-span-6 md:col-span-6  lg:block md:block relative"
              style={{ height: '100vh' }}
            >
              <div className="cover relative w-full h-full z-[1] p-10">
                <a href="index.html" className="header-logo">
                  <img src={adaptixlogo} alt="logo" className="ms-auto block" />
                </a>
                <div className="authentication-page justify-center w-full max-w-7xl mx-auto p-0">
                  <img
                    src={adaptixthemetwo}
                    alt="logo"
                    className="mx-auto h-[500px]"
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className="lg:col-span-6 md:col-span-6 "
            style={{ height: '100vh' }}
          >
            <div className="authentication-page w-full">
              <main id="content" className="w-full max-w-md mx-auto p-6">
                <a href="index.html" className="header-logo lg:hidden">
                  <img
                    src={desktoplogo}
                    alt="logo"
                    className="mx-auto block dark:hidden"
                  />
                  <img
                    src={desktopdark}
                    alt="logo"
                    className="mx-auto hidden dark:block"
                  />
                </a>

                <div className="mt-7">
                  <div className="p-4 sm:p-7">
                    <div className="text-center">
                      <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">
                        Welcome to Adaptix – Your Success Partner!
                      </h1>
                    </div>
                    <div className="mt-5">
                      <div className="grid gap-y-4">
                        {/* Username */}
                        <div>
                          <label
                            htmlFor="text"
                            id="username"
                            className="block text-sm mb-2 dark:text-white"
                          >
                            User Name
                          </label>
                          <div className="relative">
                            <input
                              type="text"
                              onChange={(e) => setUsername(e.target.value)}
                              id="username"
                              name="username"
                              required
                              className="py-2 px-3 block w-full border-gray-200 rounded-sm text-sm focus:border-primary focus:ring-primary dark:bg-bgdark dark:border-white/10 dark:text-white/70"
                            />
                          </div>
                        </div>

                        {/* Password */}
                        <div>
                          <div className="flex justify-between items-center">
                            <label
                              htmlFor="password"
                              className="block text-sm mb-2 dark:text-white"
                            >
                              Password
                            </label>
                          </div>
                          <div className="relative">
                            <input
                              type="password"
                              onChange={(e) => setPassword(e.target.value)}
                              id="password"
                              name="password"
                              required
                              className="py-2 px-3 block w-full border-gray-200 rounded-sm text-sm focus:border-primary focus:ring-primary dark:bg-bgdark dark:border-white/10 dark:text-white/70"
                            />
                          </div>
                        </div>

                        {/* Sign In Button */}
                        <button
                          type="submit"
                          onClick={handleSubmit}
                          ref={loginButtonRef}
                          disabled={!!!URL}
                          className="py-2 px-3 inline-flex justify-center items-center gap-2 rounded-sm border border-transparent font-semibold bg-primary text-white hover:bg-primary focus:outline-none focus:ring-0 focus:ring-primary focus:ring-offset-0 transition-all text-sm dark:focus:ring-offset-white/10"
                        >
                          Sign in
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>}
      
    </>
  );
};

export default LoginPage;
