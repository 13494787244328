import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useFormStore } from '@empire/components/organisms';
import { Loader } from '@empire/components/molecules';

interface CardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  className: string;
  onClick: () => void;
}
interface Module {
  icon: any;
  name: string;
  description: string;
  _id: string;
}

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

const Card = ({ title, description, className = '', onClick }: CardProps) => {
  const isSmallScreen = window.innerWidth < 1024;
  return (
    <div
      className={`${className}`}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="box">
        <div className="box-body ">
          <div className="flex items-start">
            <div className="me-4">
              <span className="avatar bg-primary/20 text-primary text-2xl p-3 rounded-sm">
                <i className="ri-flag-2-line leading-none" />
              </span>
            </div>
            <div className="flex-1">
              <div className="font-medium text-sm mb-1">Module Name</div>
              <div className="flex items-center justify-between mb-1">
                <h5 className="text-xl font-bold text-gray-800 dark:text-white">
                  {title.slice(0, 10)} {title.length > 10 ? '...' : ''}
                </h5>
                {/* <span className="badge bg-success/20 text-success rounded-sm py-1">
                  +12.2%
                </span> */}
              </div>
              {/* <p className="text-gray-500 dark:text-white/70 mb-0 text-xs">
                since last 2 months
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Modules = () => {
  const navigate = useNavigate();
  const [modules, setModules] = useState<Module[]>([]);
  const { builderUserConfig, setBuilderUserConfig } = useFormStore();
  const userdetail = builderUserConfig;
  const orgId = userdetail?.orgStatus[0].orgId;

  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        const response = await axios.get(`${BACKEND.BACKEND_API}/user/userd`, {
          headers: {
            Authorization: `Bearer ${authtoken}`,
          },
        });
        const data = await response.data;
        setBuilderUserConfig(data);
      } catch (error) {
        console.error('Error fetching form configuration:', error);
      } finally {
        console.log(false);
      }
    };

    fetchFormConfig();
  }, []);

  const [loading, setLoading] = useState(false);
  const authtoken = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${BACKEND.BACKEND_API}/module/modules`,
          {
            type: 'getmodulesbyorgstatus',
            orgId: orgId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authtoken}`,
            },
          }
        );

        const data = response.data;
        setModules(data);
      } catch (error) {
        console.error('Error fetching modules:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [orgId]);

  return (
    <>
      <div className="block justify-between page-header md:flex">
        <div>
          <h3 className="text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-white text-2xl font-medium">
            Dashboard
          </h3>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {loading ? (
          <div className="col-span-4 text-center">
            <p>
              <Loader />
            </p>
          </div>
        ) : (
          modules.map((module) => (
            <Card
              key={module._id}
              icon={module.icon}
              title={module.name}
              description={module.description}
              className=""
              onClick={() => navigate(`/main/${module._id}`)}
            />
          ))
        )}
      </div>
    </>
  );
};

const Module = () => {
  return <Modules />;
};

export default Module;
