import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaStickyNote, FaFilter } from "react-icons/fa"; 
import { TimelineDay, Field,  Section, Event } from '../types';
import { useNavigate } from 'react-router-dom';


interface field{
    field:string
 };


export interface Form {
    _id: string;
    formTitle: string;
    sections: Section[];
    CreatedBy: string;
    formId: string;
    responseId : string;
  }
  
const transformToTimeline = (jsonArray: Form[]): TimelineDay[] => {
    const events: TimelineDay[] = [];

        jsonArray.forEach((json: Form) => {
        json.sections.forEach((section: Section) => {
            const date = section.fields.find((field: Field) => field.type === "dateTime")?.value?.split(",")[0];
            const time = section.fields.find((field: Field) => field.type === "dateTime")?.value?.split(",")[1];
            const description = section.fields.find((field: Field) => field.label === "Description")?.value;
            const title = section.fields.find((field: Field) => field.label === "Title")?.value;
            const user = section.fields.find((field: Field) => field.label === "User")?.value;
            const formIdd = json.responseId;
            // : json._id;
            
            if (date && time) {
                let dateEntry = events.find((event: TimelineDay) => event.date === date);
                if (!dateEntry) {
                    dateEntry = { date, events: [] };
                    events.push(dateEntry);
                }

                dateEntry.events.push({
                    time,
                    icon: <FaStickyNote className="text-gray-600" />,
                    title,
                    description,
                    by: user || "Unknown User",
                    formIdd: formIdd
                });
            }
        });
    });

    return events;
};

 export const Timeline = ({ field} : field) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [timelineData, setTimelineData] = useState<TimelineDay[]>([]); 
    const [filteredData, setFilteredData] = useState<TimelineDay[]>([]); 
    const [showDateFilter, setShowDateFilter] = useState<boolean>(false); 
    const [selectedDate, setSelectedDate] = useState<string>(''); 
    const navigate = useNavigate();
    
const BACKEND={
    BACKEND_API:process.env.BACKEND_API,
    BACKEND_API_NORMAL:process.env.BACKEND_API_NORMAL
  }

    const authtoken=localStorage.getItem('token')

    const getFormData = async () => {
        try {
            const response = await axios.post(`${BACKEND.BACKEND_API}/other/getTimelineFlatdata`, {
                formId: field,
            }, {
                headers: {
                  'Authorization': `Bearer ${authtoken}`,
                },
              });
            const data = response.data;
            setTimelineData(transformToTimeline(data));
            setFilteredData(transformToTimeline(data)); 
            setLoading(false);
        } catch (err) {
            console.error("Error fetching data:", err);
            setLoading(false);
        }
    };

    useEffect(() => {
        getFormData();
    }, []);  

    const toggleDateFilter = () => {
        setShowDateFilter(!showDateFilter);
    };

    const convertDateFormat = (date : string) => {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear(); 
        return `${day}/${month}/${year}`;
    };

    const applyDateFilter = () => {
        const date = convertDateFormat(selectedDate)
        if (date) {
            const filtered = timelineData.filter((day: TimelineDay) => day.date === date);
            setFilteredData(filtered);
        } else {
            setFilteredData(timelineData);
        }
        setShowDateFilter(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }


    return (
        <div className="max-w-2xl max-h-full mx-auto overflow-y-scroll bg-white shadow-md rounded-md p-6">
           
            <div className="mb-4 flex items-center">
            <h2 className="text-xl font-semibold mb-4 p-4">Timeline History</h2>
                <button
                    onClick={toggleDateFilter}
                    className="flex justify-center items-center bg-white h-10 w-10 border-2 rounded-lg text-gray-600 hover:text-gray-900"
                >
                    <FaFilter className="" /> 
                </button>
                {showDateFilter && (
                    <div className="ml-4 flex items-center">
                        <input
                            type="date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            className="border border-gray-300 rounded p-2"
                        />
                        <button
                            onClick={applyDateFilter}
                            className="ml-2 bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Apply
                        </button>
                    </div>
                )}
            </div>

            <div className="space-y-8">
                {filteredData.map((day: TimelineDay, index: number) => (
                    <div key={index} className="relative">
                        <div className="bg-gray-100 text-gray-700 font-semibold px-6 py-1 rounded-md border-2 border-gray-300 shadow-sm mb-4 inline-block">
                            {day.date}
                        </div>
                        <div className="absolute left-24 top-9 w-0.5 bg-gray-300 h-full z-0"></div>
                        {day.events.map((event :Event , idx:number) => (
                            <div key={idx} className="flex items-start relative mb-8 mt-8">
                                <div className="w-20 text-sm text-gray-500 text-right pr-4">
                                    {event.time}
                                </div>
                                <div className="relative z-10">
                                    <div className="w-10 h-10 bg-gray-100 border border-gray-300  rounded-full flex items-center justify-center">
                                        {event.icon}
                                    </div>
                                </div>
                                <div className="ml-6">
                                    <div className="text-gray-800 font-semibold ml-2">{event.title}</div>
                                    <div className="text-gray-800 font-semibold ml-2">{event.description}</div>
                                    <div className="text-sm text-gray-500 ml-2">by {event.by} {day.date}</div>
                                </div>
                            </div>
                        ))}
                        {day.events.length === 0 && (
                            <div className="text-sm text-gray-500">No events</div>
                        )}
                    </div>
                ))}
                {filteredData.length === 0 && (
                     <div className="text-lg font-semibold font-roboto text-gray-500">No records found in the timeline.</div>
                )}
            </div>
        </div>
    );
};


 