import { useState } from 'react';
import RenderForm from './RenderForm';

export function Main() {
  return (
    // <div className="relative flex">
    //   <div className={`flex flex-col w-full flex-grow `}>
    //     <main className="p-2">
    //       <RenderForm />
    //     </main>
    //   </div>
    // </div>

    <RenderForm />
  );
}

export default Main;
