import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useFormStore, FormRenderer } from '@empire/components/organisms';
import axios from 'axios';

import { FormJSON } from '@empire/components';
import Loader from 'libs/components/molecules/Loader';

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

const RenderForm: React.FC = () => {
  const [formConfig, setFormConfig] = useState<FormJSON | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { formId } = useParams();
  const location = useLocation();
  const authtoken = localStorage.getItem('token');
  const editmode =
    location.state && location.state.mode ? location.state.mode : 'view';
  const { userConfig, setUserConfig } = useFormStore();
  const userid =
    userConfig?.userId != undefined
      ? userConfig?.userId
      : localStorage.getItem('userid');

  useEffect(() => {
    const fetchFormConfig = async () => {
      try {
        if (editmode === 'editfields') {
          const response = await axios.get(
            `${BACKEND.BACKEND_API}/value/valuebyresid/${formId}`,
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );
          setFormConfig(response.data);
        } else {
          const response = await axios.get(
            `${BACKEND.BACKEND_API}/forms/${formId}`,
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );
          const valueresponse = await axios.get(
            `${BACKEND.BACKEND_API}/value/valueCheckByStatus/${formId}/draft/formresponse/${userid}`,
            {
              headers: {
                Authorization: `Bearer ${authtoken}`,
              },
            }
          );
          if (
            valueresponse.data._id !== undefined &&
            valueresponse.data.userId === userid
          ) {
            const updatedObject = {
              ...valueresponse.data,
              plugins: [...response.data.plugins],
            };
            setFormConfig(updatedObject);
          } else {
            setFormConfig(response.data);
          }
        }
      } catch (error) {
        console.error('Error fetching form configuration:', error);
        setError('Failed to load form configuration.');
      } finally {
        setLoading(false);
      }
    };
    fetchFormConfig();
  }, [formId, userid]);

  if (error) return <div>{error}</div>;
  if (loading)
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader />
      </div>
    );

  return (
    <div className="p-2 mt-10 border rounded">
      {formConfig ? <FormRenderer formData={formConfig}/> : <div>No form configuration available</div>}
    </div>
  );
};

export default RenderForm;
