import React, { useState } from 'react';
import { ProfileImageProps } from '../types';

const ProfileImage: React.FC<ProfileImageProps> = ({ field }) => {
  const [imageUrl, setImageUrl] = useState(field.imageUrl || './assets/add.png');

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setImageUrl(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    const fileInput = document.getElementById('file-input') as HTMLInputElement;
    if (fileInput) {
      fileInput.click(); // Trigger click on hidden file input
    }
  };
  return (
    <div className="flex items-center justify-center bg-gray-50">
    <div className="p-5 bg-gray-100 rounded-lg shadow-md flex flex-col text-center space-y-4">
     {/* Profile Image - Click to Upload */}
     <div className="flex items-center justify-center">
          <img
            src={imageUrl}
            alt={field.label || ''}
            className="w-16 h-16 rounded-full object-cover cursor-pointer"
            onClick={handleImageClick}
          />
        </div>
        <input
          id="file-input"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="hidden" // Hide the input element
        />

      {/* Name */}
      <div className="flex">
        <label className="block text-gray-600 font-semibold mr-5">Name:</label>
        <span className="text-gray-800">{field.name || 'N/A'}</span>
      </div>

      {/* Mobile */}
      <div className="flex">
        <label className="block text-gray-600 font-semibold mr-2">Mobile:</label>
        <span className="text-gray-800">{field.mobile || 'N/A'}</span>
      </div>

      {/* Email */}
      <div className="flex">
        <label className="block text-gray-600 font-semibold mr-5">Email:</label>
        <span className="text-gray-800">{field.email || 'N/A'}</span>
      </div>
    </div>
    </div>
  );
};

export default ProfileImage;
