import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { BiPhone } from 'react-icons/bi';
import CrmRelated from './CrmRelatedList';
import { FaFileAlt, FaList } from 'react-icons/fa';
import { IoIosArrowForward, IoIosMail, IoMdAttach } from 'react-icons/io';
import { CiCalendarDate } from 'react-icons/ci';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { Timeline } from '../molecules';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import BackButton from '../molecules/BackButton';
import PrintButton from '../molecules/PrintButton';

import { Form } from '../types';
import PrintComponent from '../molecules/PrintButton';

type FormViewerProps = {
  forms: Form[];
};

const BACKEND = {
  BACKEND_API: process.env.BACKEND_API,
  BACKEND_API_NORMAL: process.env.BACKEND_API_NORMAL,
};

const slideInVariants = {
  hidden: { x: '100%', opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.7, ease: 'easeInOut' },
  },
};

export const ViewLinkedDetails: React.FC<FormViewerProps> = ({ forms }) => {
  const [expandedForms, setExpandedForms] = useState<{
    [key: string]: boolean;
  }>({});
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const formRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [activeView, setActiveView] = useState('overview');
  const location = useLocation(); // Hook that gives you the current URL
  const [fieldValues, setFieldValues] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    setActiveView('overview');
  }, [location]);

  const responseId = forms[0]?._id;
  useEffect(() => {
    if (forms.length > 0) {
      setExpandedForms({ [forms[0].formTitle]: true });
    }
  }, [forms]);

  const toggleForm = (formTitle: string) => {
    setExpandedForms((prev) => ({
      ...prev,
      [formTitle]: !prev[formTitle],
    }));
  };

  const toggleSection = (sectionId: string, sectionName: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  const scrollToForm = (formTitle: string) => {
    const formElement = formRefs.current[formTitle];
    if (formElement) {
      const yOffset = -100;
      const yPosition =
        formElement.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({
        top: yPosition,
        behavior: 'smooth',
      });
    }
  };

  const fieldStaggerVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: (index: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.1,
        duration: 0.4,
        ease: [0.25, 0.1, 0.25, 1],
      },
    }),
  };

  const groupedForms: { [key: string]: Form[] } = forms.reduce((acc, form) => {
    if (!acc[form.formTitle]) {
      acc[form.formTitle] = [];
    }
    acc[form.formTitle].push(form);
    return acc;
  }, {} as { [key: string]: Form[] });

  const fieldValueFromId = async (field: string) => {
    const response = await axios.get(`${BACKEND.BACKEND_API}/value/${field}`);
    const value = response.data.sections[0].fields[0].value;
    return value;
  };

  const handleFieldValue = async (fieldId: string) => {
    if (!fieldValues[fieldId]) {
      const value: any = await fieldValueFromId(fieldId);
      setFieldValues((prevValues) => ({
        ...prevValues,
        [fieldId]: value,
      }));
    }
    return fieldValues[fieldId] || 'No data';
  };

  return (
    <div className="flex flex-row min-h-screen">
      <div className="p-4 bg-white border-r border-gray-200 w-1/10 dark:bg-gray-900 dark:text-gray-300">
        <div className="grid items-center grid-cols-2 gap-2">
          <div className="flex items-center dark:bg-gray-900 dark:text-gray-300">
            <FaList className="mr-2 text-gray-700 dark:bg-gray-900 dark:text-gray-300" />
            <h6 className="text-sm font-medium text-gray-800 dark:bg-gray-900 dark:text-gray-300">
              Related List
            </h6>
          </div>
          <hr className="col-span-2 border-b border-gray-300" />
        </div>
        <CrmRelated />
        <ul className="fixed">
          {Object.keys(groupedForms).map((formTitle) => (
            <li
              key={formTitle}
              className="ml-2 rounded cursor-pointer text-gray-50 hover:bg-gray-50"
              onClick={() => scrollToForm(formTitle)}
            >
              <motion.span
                className="font-medium text-gray-600"
                variants={slideInVariants}
              >
                <span className="text-sm font-medium text-gray-800 dark:bg-gray-900 dark:text-gray-300">
                  {formTitle.charAt(0).toUpperCase() + formTitle.slice(1)}
                </span>
              </motion.span>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex-1 p-6 bg-gray-50 dark:bg-gray-900 dark:text-white">
        <div className="flex items-center justify-between p-4">
          <div className="flex items-center p-1 m-4 bg-gray-200 rounded-full w-fit">
            <button
              className={`px-2 rounded-full ${
                activeView === 'overview'
                  ? 'bg-white text-blue-600 shadow'
                  : 'text-gray-600'
              }`}
              onClick={() => setActiveView('overview')}
            >
              Overview
            </button>
            <button
              className={`px-2 rounded-full ${
                activeView === 'timeline'
                  ? 'bg-white text-blue-600 shadow'
                  : 'text-gray-600'
              }`}
              onClick={() => setActiveView('timeline')}
            >
              Timeline
            </button>
          </div>
          <div>
            {/* <PrintButton printContentId="printableArea1" /> */}

            <PrintButton
              printContentId="printableArea1"
              setExpandedForms={setExpandedForms}
              setExpandedSections={setExpandedSections}
              groupedForms={groupedForms}
            />

            <BackButton />
          
           
          </div>
        </div>

        {activeView === 'overview' &&
          Object.entries(groupedForms).map(([formTitle, groupedForms]) => (
            <motion.div
              key={formTitle}
              className="mb-12 bg-white border rounded-lg dark:bg-gray-900 dark:text-white"
              ref={(el) => (formRefs.current[formTitle] = el)}
              variants={fieldStaggerVariants}
              initial="hidden"
              animate="visible"
              id="printableArea1"
              style={{
                border: '1px solid black',
                padding: '20px',
                marginTop: '20px',
              }}
            >
              <div
                onClick={() => toggleForm(formTitle)}
                className="flex items-center justify-between p-2 mb-2 cursor-pointer dark:bg-gray-900 dark:text-gray-300"
              >
                <h5 className="text-sm font-bold text-center text-gray-800 uppercase dark:bg-gray-900 dark:text-gray-300">
                  {formTitle}
                </h5>
                <div className="flex items-center">
                  {expandedForms[formTitle] ? (
                    <MdKeyboardArrowDown className="w-5 h-5 text-gray-600" />
                  ) : (
                    <MdKeyboardArrowRight className="w-5 h-5 text-gray-600" />
                  )}
                </div>
              </div>
              {expandedForms[formTitle] && (
                <>
                  {groupedForms.map((form, formIndex) => (
                    <div key={formIndex}>
                      {form.sections.map((section) => (
                        <motion.div
                          key={section.id}
                          className="p-6 mb-4 "
                          variants={fieldStaggerVariants}
                          initial="hidden"
                          animate="visible"
                        >
                          <div
                            onClick={() =>
                              toggleSection(
                                section.id,
                                section.name?.toLowerCase().includes('open')
                                  ? section.name
                                  : '(Open Section)'
                              )
                            }
                            className="flex items-center justify-between p-2 cursor-pointer bg-gray-50 dark:bg-gray-900 dark:text-gray-300"
                          >
                          
                            <h5 className="text-sm font-bold text-center text-gray-800 uppercase dark:bg-gray-900 dark:text-gray-300">
                            {section.name || 'Open Section'}
                </h5>
                            <div className="flex items-center">
                              {expandedSections[section.id] ? (
                                <MdKeyboardArrowDown className="w-5 h-5 text-gray-600" />
                              ) : (
                                <MdKeyboardArrowRight className="w-5 h-5 text-gray-600" />
                              )}
                            </div>
                          </div>
                          {expandedSections[section.id] && (
                            <motion.div
                              className="grid grid-cols-2 gap-4"
                              variants={fieldStaggerVariants}
                              initial="hidden"
                              animate="visible"
                            >
                              {section.fields.map((field) => (
                                <div
                                  key={field.id}
                                  className="flex items-center justify-between py-2 border-b border-gray-200"
                                >
                                  <motion.span
                                    className="font-bold text-gray-600 dark:bg-gray-900 dark:text-gray-300"
                                    variants={fieldStaggerVariants}
                                  >
                                    {field.label}
                                  </motion.span>
                                  <div className="flex items-center space-x-2">
                                    {field.type === 'email' && (
                                      <IoIosMail className="w-5 h-5 text-curious-blue-700" />
                                    )}
                                    {field.type === 'date' && (
                                      <CiCalendarDate className="w-5 h-5 text-curious-blue-700" />
                                    )}
                                    {field.type === 'file' && (
                                      <FaFileAlt className="w-5 h-5 text-curious-blue-700" />
                                    )}
                                    {field.type === 'phone' && (
                                      <BiPhone className="w-5 h-5 text-curious-blue-700" />
                                    )}
                                    <span className="text-gray-700 break-words  text-wrap">
                                      {typeof field.value === 'string' &&
                                      field.value?.startsWith('http://') ? (
                                        <a
                                          href={field.value}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ textDecoration: 'none' }}
                                        >
                                          <a
                                            href={field.value}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ textDecoration: 'none' }}
                                          >
                                            <div className="flex items-center justify-center pr-6">
                                              <div className="overflow-hidden rounded-lg shadow-md">
                                                <img
                                                  src={field.value}
                                                  alt="image"
                                                  className="w-full h-20 object-cover"
                                                />
                                              </div>
                                            </div>
                                          </a>
                                        </a>
                                      ) : (
                                        field.value || (
                                          <span className="italic text-gray-400">
                                            No data
                                          </span>
                                        )
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </motion.div>
                          )}
                        </motion.div>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </motion.div>
          ))}

        {activeView === 'timeline' && (
          <div>
            <Timeline field={responseId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewLinkedDetails;
