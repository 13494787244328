import React from 'react';
import { AddressInputProps } from '../types';

export const AddressInput = ({ field, values, errors, onChange }: AddressInputProps) => {
  const handleSubFieldChange = (subFieldId: string, e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e, subFieldId);
  };

  return (
    <div className="p-2 bg-gray-200 border rounded" >
    <div className="address-field">
      {field.fields.map((subField) => (
        <div key={subField.id} className="mb-4">
          <label htmlFor={subField.id} className="block mb-2">
            {subField.label}
          </label>
          <input
            type={subField.type}
            id={subField.id}
            name={subField.name}
            placeholder={subField.placeholder}
            required={subField.required}
            value={values[subField.id] || ""}
            onChange={(e) => handleSubFieldChange(subField.id, e)}
            className={`w-full p-2 border rounded ${errors[subField.id] ? 'border-red-500' : ''}`}
          />
        </div>
      ))}
    </div>
    </div>
  );
};