import { create } from "zustand";

interface FormState {
  // formConfig: any | null;
  userConfig: any | null; 
  builderUserConfig: any | null; 
  builderConfig: any | null;
  cardModalOpen: boolean,
  setUserConfig: (config: any) => void; 
  setBuilderUserConfig: (config: any) => void; 
  setBuilderConfig:(config:any)=>void
  setCardModalOpenConfig: (config:any)=>void;
  // setFormConfig: (config: any) => void;
}

export const useFormStore = create<FormState>((set) => ({
  // formConfig: null,
  userConfig: null, 
  builderUserConfig:null,
  builderConfig:null,
  cardModalOpen:false,
  setBuilderUserConfig: (config) => set({ builderUserConfig: config }),
  setUserConfig: (config) => set({ userConfig: config }), 
  setBuilderConfig: (config) => set({ builderConfig: config }) ,
  setCardModalOpenConfig: (config:any)=> set({cardModalOpen : config})

  // setFormConfig: (config) => set({ formConfig: config }),
}));